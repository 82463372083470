import React, { ChangeEvent, FormEvent } from "react";
import {
	FormControl,
	FormHelperText,
	IconButton,
	OutlinedInput,
	InputAdornment,
	InputLabel,
	Tooltip
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { SnackbarActionPayload } from "components/snackbar/types";
import { showSnackbar } from "components/snackbar/actionCreators";
import { connect } from "react-redux";
import { ContentCopy } from "mdi-material-ui";
import { INPUT_VARIANT } from "components/form/const";

interface LocalState {
	isPasswordVisible: boolean;
	formValidation: {
		isValid: boolean;
		message: string;
	};
}

interface LocalProps {
	value?: string;
	label: string;
	required?: boolean;
	disabled?: boolean;
	onChange?: (e: ChangeEvent) => void;
}

interface DispatchProps {
	showSnackbar: (snackbar: SnackbarActionPayload) => void;
}

type Props = LocalProps & DispatchProps;

class PasswordField extends React.PureComponent<Props, LocalState> {
	constructor(props: Props) {
		super(props);

		this.state = {
			isPasswordVisible: false,
			formValidation: { isValid: true, message: "" }
		};
	}

	render() {
		const { isPasswordVisible, formValidation } = this.state;
		const { value, label, onChange, required, disabled } = this.props;

		const isReadOnly = !onChange;

		return (
			<FormControl
				required={required}
				fullWidth={true}
				variant={INPUT_VARIANT}
				onInvalid={(e: FormEvent): void => {
					e.preventDefault();
					const form = e.target as HTMLFormElement;

					this.setState((state: LocalState) => ({
						formValidation: {
							...state.formValidation,
							isValid: false,
							message: form.validationMessage
						}
					}));
				}}
				error={!formValidation.isValid}
			>
				<InputLabel htmlFor="password-field">{label}</InputLabel>
				<OutlinedInput
					fullWidth={true}
					disabled={disabled}
					label="DB Root Password"
					margin="dense"
					id="password-field"
					type={isPasswordVisible ? "text" : "password"}
					value={value}
					readOnly={isReadOnly}
					error={!formValidation.isValid}
					onChange={(e: ChangeEvent) => {
						this.setState(() => ({
							formValidation: {
								isValid: true,
								message: ""
							}
						}));

						onChange && onChange(e);
					}}
					endAdornment={
						<InputAdornment position="end">
							{isReadOnly && (
								<Tooltip
									enterDelay={500}
									placement={"top"}
									title={"Copy to clipboard"}
								>
									<IconButton
										style={{ marginRight: "5px" }}
										aria-label="copy to clipboard"
										onClick={() => {
											if (value) {
												if (navigator.clipboard) {
													navigator.clipboard.writeText(value).then(
														() => {
															this.props.showSnackbar({
																msg: "Password copied to clipboard"
															});
														},
														(err: Error) => {
															console.error("Password copy error:", err);
															this.props.showSnackbar({
																msg: "Couldn't copy password to clipboard"
															});
														}
													);
												} else {
													this.props.showSnackbar({
														msg: "Couldn't copy password because the application is not served over HTTPS"
													});
												}
											} else {
												this.props.showSnackbar({
													msg: "Didn't copy because password is empty"
												});
											}
										}}
										onMouseDown={(
											event: React.MouseEvent<HTMLButtonElement>
										) => {
											event.preventDefault();
										}}
										size="large"
									>
										<ContentCopy />
									</IconButton>
								</Tooltip>
							)}
							<Tooltip
								enterDelay={500}
								placement={"top"}
								title={"Toggle visibility"}
							>
								<IconButton
									aria-label="toggle password visibility"
									onClick={() => {
										this.setState((state: LocalState) => ({
											isPasswordVisible: !state.isPasswordVisible
										}));
									}}
									onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => {
										event.preventDefault();
									}}
									size="large"
								>
									{isPasswordVisible ? <Visibility /> : <VisibilityOff />}
								</IconButton>
							</Tooltip>
						</InputAdornment>
					}
				/>
				<FormHelperText>{formValidation.message}</FormHelperText>
			</FormControl>
		);
	}
}

const mapGlobalDispatchToProps = (dispatch: any) => {
	return {
		showSnackbar: (snackbar: SnackbarActionPayload) => {
			dispatch(showSnackbar(snackbar));
		}
	};
};

export default connect(undefined, mapGlobalDispatchToProps)(PasswordField);
