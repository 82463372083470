import {
	USER_CREATE_WIZARD_ACTION,
	UserCreateWizardHide,
	UserCreateWizardShow
} from "pages/users/userCreateWizard/types";

export const userCreateWizardShow = (): UserCreateWizardShow => ({
	type: USER_CREATE_WIZARD_ACTION.SHOW
});

export const userCreateWizardHide = (): UserCreateWizardHide => ({
	type: USER_CREATE_WIZARD_ACTION.HIDE
});
