import { takeEvery } from "redux-saga/effects";
import { SNACKBAR_ACTION, SnackbarActionShow } from "components/snackbar/types";

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
// function* fetchUser(action) {
//   try {
//     const user = yield call(Api.fetchUser, action.payload.userId);
//     yield put({type: "USER_FETCH_SUCCEEDED", user: user});
//   } catch (e) {
//     yield put({type: "USER_FETCH_FAILED", message: e.message});
//   }
// }

/*
  Starts fetchUser on each dispatched `USER_FETCH_REQUESTED` action.
  Allows concurrent fetches of user.
*/
function* SnackbarSideEffects() {
	yield takeEvery(SNACKBAR_ACTION.SHOW, (args: SnackbarActionShow) => {
		// console.log("caught snackbar show action!!!", args);
	});
}

/*
  Alternatively you may use takeLatest.

  Does not allow concurrent fetches of user. If "USER_FETCH_REQUESTED" gets
  dispatched while a fetch is already pending, that pending fetch is cancelled
  and only the latest one will be run.
*/
// function* SnackbarSaga() {
//   yield takeLatest("USER_FETCH_REQUESTED", fetchUser);
// }

export default SnackbarSideEffects;
