import { AGGREGATION } from "components/monitoring/dashboard/types";

export const DEFAULT_REFRESH_INTERVAL = 1000;
export const DEFAULT_PERIOD = "15m";
export const DEFAULT_AGGREGATION: AGGREGATION = AGGREGATION.RAW;
export const MAX_NUM_OF_POINTS_IN_CHART = 1000;

export interface ChartMetric {
	name: string;
	table: string;
}
