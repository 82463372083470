import Axios from "axios";
import { clusterListFetchSucceeded } from "store/cluster/actions";
import { hostListFetchSucceeded } from "store/host/actions";
import { HOST_TYPE } from "pages/management/host/types";
import { nodeListFetchSucceeded } from "store/node/actions";
import ClustersAPI from "services/api/ClustersAPI";
import EnvAPI from "services/api/EnvAPI";
import HostsAPI from "services/api/HostsAPI";
import NodesAPI from "services/api/NodesAPI";
import SupportMatrixApi from "services/api/SupportMatrixApi";
import APIUtils from "services/api/utils";
import { bootAuthCheck } from "store/auth/actions";
import { Config } from "services/config/Config";
import { startMetricsMonitor } from "store/metricsStore/actions";
import {
	publicPreloadFailed,
	publicPreloadSuccess,
	securePreloadFailed,
	securePreloadSuccess
} from "store/preloader/actions";
import {
	PUBLIC_PRELOADER_ACTION,
	SECURE_PRELOADER_ACTION
} from "components/preloader/types";
import { takeLatest } from "redux-saga/effects";
import {
	versionGetFailedAction,
	versionGetSuccessAction
} from "store/version/actions";
import { call, put } from "typed-redux-saga";

function* publicPreloadSideEffect() {
	try {
		const jwtPublicKeyResponse = yield* call(
			EnvAPI.fetchPublicKey,
			`JWT_PUBLIC_KEY`
		);

		// console.log("jwtPublicKeyResponse", jwtPublicKeyResponse);

		if (!jwtPublicKeyResponse.data.value)
			publicPreloadFailed("JWT_PUBLIC_KEY is missing.");

		Config.jtw_public_token = jwtPublicKeyResponse.data.value;

		try {
			// get app version
			const app_version_response = yield* call(Axios.get, `/version`) as any;
			yield put(versionGetSuccessAction(app_version_response.data.version));
		} catch (e) {
			const errorMsg = APIUtils.APIErrorResponseHandler(e);
			console.error("Version get error:", errorMsg);
			yield put(versionGetFailedAction(errorMsg));
		}

		yield put(publicPreloadSuccess());
		yield put(bootAuthCheck());
	} catch (e: any) {
		console.error(e);
		yield put(publicPreloadFailed(e.message));
	}
}

function* securePreloadSideEffect() {
	// try {
	// 	const jwtData = yield* call(AuthAPI.refreshWsAccessToken);
	// 	yield put(fetchJWTSuccess(jwtData));
	// } catch (e: any) {
	// 	// console.log("fetch jwt failed", e.message);
	// 	yield put(fetchJWTFailed(e.message));
	// }

	try {
		const clusterList = yield* call(ClustersAPI.fetchList);
		// console.log("saga cluster list fetched", clusterList);
		yield put(clusterListFetchSucceeded(clusterList));

		let clusterIndex = 0;
		let cluster = clusterList[clusterIndex];
		while (cluster) {
			const clusterID = cluster.id;
			if (!clusterID) break;

			const hostList = yield* call(HostsAPI.fetchList, clusterID);
			yield put(hostListFetchSucceeded(clusterID, hostList));

			const nodeList = yield* call(NodesAPI.fetchList, clusterID);
			yield put(nodeListFetchSucceeded(clusterID, nodeList));
			cluster = clusterList[++clusterIndex];
		}

		// yield put(userListFetchRequested());
		//
		// yield put(jobListFetchRequested());

		// yield call(DashboardsApi.fetchList);

		Config.support_matrix = yield* call(SupportMatrixApi.fetchSupportMatrix);

		// console.log("supportMatrix", supportMatrix);

		Config.support_matrix_ec2 = yield* call(
			SupportMatrixApi.fetchSupportMatrix,
			HOST_TYPE.EC2
		);

		Config.support_matrix_digitalocean = yield* call(
			SupportMatrixApi.fetchSupportMatrix,
			HOST_TYPE.DIGITAL_OCEAN
		);

		const influxUrlFetchResponse = yield* call(EnvAPI.fetchKey, "INFLUXDB_URL");
		// console.log("influxUrlFetchResponse", influxUrlFetchResponse);

		const fullUrl = influxUrlFetchResponse.data?.value;

		try {
			const influxUrl = new URL(fullUrl);

			// console.warn(r"influxUrl", fullUrl, influxUrl);

			Config.influx_protocol =
				influxUrl.protocol === "https:" ? "https" : "http";
			Config.influx_host = influxUrl.hostname;
			Config.influx_username = influxUrl.username;
			Config.influx_password = influxUrl.password;
			Config.influx_port = parseInt(influxUrl.port);
			Config.influx_db = influxUrl.pathname.replace("/", "");
		} catch (e) {
			console.warn(
				`Failed to parse InfluxDB Url: ${fullUrl}
				Expected url format: http://username:password@url/dbname
				The application will not be able to display logs and chart metrics.`
			);
		}

		console.log("Secure preload success!");
		console.log("config", Config);

		yield put(securePreloadSuccess());
	} catch (e: any) {
		console.error(e);
		yield put(securePreloadFailed(e.message));
	}
}

function* securePreloadSuccessSideEffect() {
	yield put(startMetricsMonitor());
}

export default function* PreloaderSideEffects() {
	yield takeLatest(PUBLIC_PRELOADER_ACTION.PRELOAD, publicPreloadSideEffect);
	yield takeLatest(SECURE_PRELOADER_ACTION.PRELOAD, securePreloadSideEffect);
	yield takeLatest(
		SECURE_PRELOADER_ACTION.PRELOAD_SUCCESS,
		securePreloadSuccessSideEffect
	);
}
