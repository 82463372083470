import { Theme } from "@mui/material/styles";
import { createStyles } from "@mui/styles";

export const styles = (theme: Theme) =>
	createStyles({
		appInfo: {
			position: "fixed",
			bottom: theme.spacing(1),
			left: theme.spacing(1),
			opacity: 0.4,
			zIndex: 10000,
			"&:hover": {
				opacity: 0.8
			},
			cursor: "pointer"
		}
	});
