import { Grid, Typography } from "@mui/material";
import React from "react";
import { LogLine } from "components/logViewer/types";
import { styles } from "pages/systemLog/styles";
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import moment from "moment";

interface LocalProps {
	logLine: LogLine;
}

type Props = LocalProps & WithStyles<typeof styles>;

class SystemLogMessage extends React.Component<Props, any> {
	componentDidUpdate(
		prevProps: Readonly<Props>,
		prevState: Readonly<any>,
		snapshot?: any
	): void {
		console.log("componentDidUpdate");
	}

	shouldComponentUpdate(
		nextProps: Readonly<Props>,
		nextState: Readonly<any>,
		nextContext: any
	): boolean {
		return this.props.logLine._logLineHash !== nextProps.logLine._logLineHash;
	}

	render():
		| React.ReactElement<any, string | React.JSXElementConstructor<any>>
		| string
		| number
		| {}
		| React.ReactNodeArray
		| React.ReactPortal
		| boolean
		| null
		| undefined {
		const { logLine, classes } = this.props;

		console.log(
			"msg",
			`${moment(logLine.time).format(
				"MMM DD, YYYY HH:mm:ss"
			)} | ${logLine.level.padEnd(7, " ")} | ${logLine.message}`
		);

		return (
			<Grid data-testid="log-message" container direction="row">
				<Typography
					component="span"
					noWrap={true}
					variant="body2"
					classes={{ body2: classes.logMessagesTypography }}
					className={
						(logLine.level === "error" && classes.errorLog) ||
						(logLine.level === "warning" && classes.warningLog) ||
						""
					}
				>
					{`${moment(logLine.time).format(
						"MMM DD, YYYY HH:mm:ss"
					)} | ${logLine.level.padEnd(7, " ")} | ${logLine.message}`}
				</Typography>
			</Grid>
		);
	}
}

export default withStyles(styles)(SystemLogMessage);
