import { Help as HelpIcon } from "@mui/icons-material";
import {
	Avatar,
	Badge,
	Grid,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Tooltip,
	Typography
} from "@mui/material";
import { WithStyles, WithTheme } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { AppState } from "AppState";
import { BlinkingBadge } from "components/BlinkingBadge/BlinkingBadge";
import { Server } from "mdi-material-ui";
import { DEPLOYMENT_STATUS } from "pages/management/cluster/types";
import { Host, HOST_STATE } from "pages/management/host/types";
// import HostStateComponent from "components/sharedComponents/hostState/HostStateComponent";
import HostUtils from "pages/management/host/utils";
import React from "react";
import { connect } from "react-redux";
import { StaticContext } from "react-router";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { createSelector } from "reselect";
import { JOB_STATUS, JobTracking } from "services/jobs/types";
import { sharedStyles } from "sharedStyles";
// import { styles } from "styles.ts";

// component local state interface
interface State {
	isSelected: boolean;
}

// PROPS
interface LocalProps {
	host: Host;
}

interface DispatchProps {}

interface ReduxStateProps {
	hasRunningJobs: boolean;
}

type Props = LocalProps &
	ReduxStateProps &
	DispatchProps &
	WithStyles<typeof sharedStyles> &
	// WithStyles<typeof styles> &
	WithTheme &
	RouteComponentProps<any, StaticContext, any>;

// COMPONENT
class StrayHostItemComponent extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			isSelected: false
		};
	}

	componentDidMount(): void {
		this.onRouteChanged();
	}

	componentDidUpdate(prevProps: Props) {
		if (this.props.location !== prevProps.location) {
			this.onRouteChanged();
		}
	}

	onRouteChanged() {
		const { clusterID, id: hostID } = this.props.host;
		const isSelected =
			this.props.location.pathname === `/clusters/${clusterID}/hosts/${hostID}`;

		this.setState({
			isSelected
		});
	}

	onItemClick = () => {
		const { clusterID, id: hostID } = this.props.host;
		this.props.history.push(`/clusters/${clusterID}/hosts/${hostID}`);
	};

	render(): React.ReactNode {
		const { theme, host, hasRunningJobs, classes } = this.props;
		const { isSelected } = this.state;

		const hostState: HOST_STATE = HostUtils.getHostState(host.deploymentStatus);

		const avatar = (
			<Avatar
				style={{
					backgroundColor:
						(hostState === DEPLOYMENT_STATUS.SUCCESS &&
							theme.palette.grey[400]) ||
						undefined,
					color: theme.palette.common.white
					// border: `3px solid ${theme.palette.error.main}`
				}}
				// className={classes.strayHostIcon}
			>
				<Server />
			</Avatar>
		);

		const badgeQuestionMark = (
			<Badge
				overlap="rectangular"
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right"
				}}
				badgeContent={
					<Tooltip title="This is a stray host">
						<HelpIcon
							style={{
								width: 22,
								height: 22,
								borderRadius: "50%",
								color: theme.palette.grey[400],
								background: theme.palette.common.white
							}}
						/>
					</Tooltip>
				}
			>
				{avatar}
			</Badge>
		);

		return (
			<>
				<ListItem
					className={classes.doubleNestedTreeViewItem}
					selected={isSelected}
					button
					onClick={this.onItemClick}
				>
					<ListItemAvatar>
						{hasRunningJobs ? (
							<BlinkingBadge variant="dot" color="primary">
								{badgeQuestionMark}
							</BlinkingBadge>
						) : (
							badgeQuestionMark
						)}
					</ListItemAvatar>
					<ListItemText
						primary={
							<Grid container direction="row" spacing={1}>
								<Grid item>
									<Typography variant="body2">{host.name}</Typography>
								</Grid>
							</Grid>
						}
						secondary={host.system}
					/>
					{/* <HostStateComponent hostState={hostState} /> */}
				</ListItem>
			</>
		);
	}
}

const makeHasRunningJobs = () =>
	createSelector(
		(state: AppState) => state.jobMonitor.runningJobList,
		(state: AppState, props: LocalProps) => props.host,
		(jobList: JobTracking[], host: Host) =>
			jobList.some(
				(job: JobTracking) =>
					job.meta.cluster_id === host.clusterID &&
					job.meta.host_id === host.id &&
					job.status === JOB_STATUS.RUNNING
			)
	);

// REDUX MAPPINGS
const mapGlobalStateToProps = (state: AppState, props: LocalProps) => {
	const hasRunningJobsSelector = makeHasRunningJobs();

	return {
		hasRunningJobs: hasRunningJobsSelector(state, props)
	};
};

export default withStyles(sharedStyles, { withTheme: true })(
	withRouter(connect(mapGlobalStateToProps, {})(StrayHostItemComponent))
);
