import PreloaderComponentHtml from "components/preloader/PreloaderComponentHtml";
import React from "react";
import { AppState } from "AppState";
import { connect } from "react-redux";
import { publicPreload } from "store/preloader/actions";

interface LocalProps {}

interface ReduxDispatchProps {
	preloadRequested: () => void;
}

type Props = LocalProps & ReduxDispatchProps;

class PublicPreloader extends React.Component<Props> {
	constructor(props: Props) {
		super(props);

		props.preloadRequested();
	}

	render() {
		return <PreloaderComponentHtml />;
	}
}

// REDUX MAPPINGS
const mapGlobalStateToProps = (state: AppState) => ({});

const mapGlobalDispatchToProps = (dispatch: any) => {
	return {
		preloadRequested: () => {
			dispatch(publicPreload());
		}
	};
};

export default connect(
	mapGlobalStateToProps,
	mapGlobalDispatchToProps
)(PublicPreloader);
