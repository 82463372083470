import { Theme } from "@mui/material";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
	listItem: {
		paddingLeft: 8,
		paddingRight: 0
	},
	consoleIcon: {
		minWidth: 36, // TODO: remove this, since it isn't displayed BKBK
		[theme.breakpoints.up("sm")]: {
			minWidth: 48
		}
	},
	avatar: {
		backgroundColor: "#aaa",
		width: 22,
		height: 22,
		marginLeft: 4,
		marginRight: 6
	},
	miniAvatar: {
		width: 26,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		marginRight: 8
	},
	first: {
		minWidth: 150,
		width: 220,
		color: "rgba(0, 0, 0, 1)"
	},
	firstType: {
		color: "rgba(0, 0, 0, 0.6)",
		textTransform: "capitalize"
	},
	firstName: {
		color: "rgba(0, 0, 0, 1)"
	},
	second: {
		width: 250,
		color: "rgba(0, 0, 0, 1)"
	},
	collapsibleItem: {
		paddingLeft: 8,
		[theme.breakpoints.up("md")]: {
			paddingLeft: 56 // listItem.paddingLeft + consoleIcon.minWidth
		}
	},
	openCloseIcon: {
		width: 34,
		height: 34,
		minWidth: 34
	},
	buttonContainer: {
		display: "flex",
		justifyContent: "space-between"
	},
	showDetailsBtn: {
		marginTop: 12,
		marginBottom: 12
	},
	copyIconBtn: {
		marginTop: 3,
		marginBottom: 3
	},
	icon: {
		display: "inline",
		width: 18,
		height: 18,
		color: theme.palette.error.dark,
		marginRight: 6
	}
}));

export default useStyles;
