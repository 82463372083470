import React from "react";
// MUI
import {
	Alert,
	AlertTitle,
	Avatar,
	Box,
	Button,
	Collapse,
	Divider,
	IconButton,
	ListItem,
	ListItemAvatar,
	ListItemButton,
	ListItemText,
	Tooltip,
	Typography
} from "@mui/material";
// icons
import {
	Console as ConsoleIcon,
	ContentCopy as ContentCopyIcon,
	Database as DatabaseIcon,
	Server as ServerIcon
} from "mdi-material-ui";
import {
	DeviceHub as DeviceHubIcon,
	KeyboardArrowDown as KeyboardArrowDownIcon,
	KeyboardArrowUp as KeyboardArrowUpIcon,
	SubdirectoryArrowRight as SubdirectoryArrowRightIcon
} from "@mui/icons-material";
// redux
import { connect } from "react-redux";
// components
import JobStatusComponent from "pages/management/node/jobs/jobStatus/jobStatusComponent";
import { Job, Wrapped, Meta, JobType } from "services/jobs/types";
import JobErrorDetails from "components/JobErrorDetails/JobErrorDetailsComp";
import { showSnackbar } from "components/snackbar/actionCreators";
import { SnackbarActionPayload } from "components/snackbar/types";
import useStyles from "pages/management/node/jobs/jobComponent/styles";
// packages
import moment from "moment";

const yaml = require("js-yaml");

interface LocalProps {
	job: Job;
}

interface DispatchProps {
	showSnackbar: (snackbar: SnackbarActionPayload) => void;
}

type Props = LocalProps & DispatchProps;

function getAvatar(jobType: JobType) {
	const avatar =
		jobType === "host" ? (
			<ServerIcon style={{ width: 16, height: 16 }} />
		) : jobType === "node" ? (
			<DatabaseIcon style={{ width: 16, height: 16 }} />
		) : (
			<DeviceHubIcon style={{ width: 16, height: 16 }} />
		);

	return (
		<Avatar
			style={{
				backgroundColor: "#aaa",
				width: 22,
				height: 22,
				marginLeft: 4,
				marginRight: 6
			}}
		>
			{avatar}
		</Avatar>
	);
}

function getJobType(meta: Meta) {
	const jobType =
		meta?.node_name !== undefined
			? "node"
			: meta?.host_name !== undefined
			? "host"
			: undefined;

	return jobType;
}

function getName(jobType: JobType, meta: Meta) {
	const name =
		jobType === "node"
			? meta.node_name
			: jobType === "host"
			? meta.host_name
			: meta.cluster_name;

	return name;
}

function JobComponent(props: Props) {
	const { id, description, createdAt, executionInfo, meta } = props.job;
	const failure = executionInfo.status === "failure";
	const [isOpen, setIsOpen] = React.useState(true);
	const [isYamlOpen, setIsYamlOpen] = React.useState(false);
	const [lookForWrapped, setLookForWrapped] = React.useState(true);
	const classes = useStyles();

	const jobType = getJobType(meta);
	const name = getName(jobType, meta);
	const miniAvatar = getAvatar(jobType);

	const message = (
		<Box
			sx={{
				display: "flex"
			}}
		>
			<Box className={classes.miniAvatar}>{miniAvatar}</Box>

			<Box className={classes.first}>
				<span className={classes.firstType}>
					{jobType ? jobType : "Cluster"}
					{": "}
				</span>

				<span className={classes.firstName}>{name}</span>
			</Box>
			<Box className={classes.second}>{description}</Box>
		</Box>
	);

	let msgList: string[] = [];

	let error: Wrapped | undefined = undefined;
	let formattedYaml: string = "";

	const createIconElement = (childNumber: number) => {
		// width of icon is 18, right margin is 6, and 2 makes up to 26 px margin per indent level
		// (childNumber - 1) is because first icon shouldn't have big indent, only second and beyond
		const iconLeftMargin = 2 + 26 * (childNumber - 1);

		let icon =
			childNumber === 0 ? null : (
				<SubdirectoryArrowRightIcon
					sx={{ marginLeft: `${iconLeftMargin}px` }}
					className={classes.icon}
				/>
			);

		return icon;
	};

	if ("error" in executionInfo) {
		error = executionInfo.error;
		if (error !== undefined) {
			const checkIfHasWrapped = (item: Wrapped) => {
				if (lookForWrapped) {
					if (item !== undefined) {
						msgList.push(item.msg);

						if (item.wrapped !== undefined) checkIfHasWrapped(item.wrapped);
					}
				} else {
					setLookForWrapped(false);
				}
			};

			checkIfHasWrapped(error);

			formattedYaml = yaml.dump(error, {
				flowLevel: 3,
				styles: {
					"!!int": "hexadecimal",
					"!!null": "camelcase"
				}
			});
		}
	}

	const handleCopyError = () => {
		if (error !== undefined) {
			if (navigator.clipboard) {
				navigator.clipboard.writeText(formattedYaml).then(
					() => {
						props.showSnackbar({
							msg: "Copied error details to clipboard"
						});
					},
					(err: Error) => {
						console.error("Password copy error:", err);
						props.showSnackbar({
							msg: "Couldn't copy error details to clipboard"
						});
					}
				);
			} else {
				props.showSnackbar({
					msg: "Couldn't copy error details because the application is not served over HTTPS"
				});
			}
		}
	};

	return (
		<>
			<ListItemButton
				onClick={() => setIsOpen(!isOpen)}
				key={id}
				className={classes.listItem}
			>
				<ListItemAvatar
					sx={{ display: { xs: "none", md: "block" } }}
					className={classes.consoleIcon}
				>
					<ConsoleIcon />
				</ListItemAvatar>
				<ListItemText
					primary={message}
					secondary={`Started on ${moment(createdAt).format("LLL")} (${moment(
						createdAt
					).fromNow()})${
						executionInfo?.details
							? ", response: " + executionInfo?.details
							: ""
					}`}
				/>
				<JobStatusComponent jobStatus={executionInfo?.status} />

				<div className={classes.openCloseIcon}>
					{failure && (
						<IconButton
							aria-label="expand row"
							size="small"
							onClick={() => setIsOpen(!isOpen)}
						>
							{isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
						</IconButton>
					)}
				</div>
			</ListItemButton>
			{failure && (
				<Collapse in={isOpen} timeout="auto" unmountOnExit>
					<div>
						<ListItem className={classes.collapsibleItem}>
							<Box sx={{ width: "100%" }}>
								<Alert variant="outlined" severity="error">
									<AlertTitle>Error</AlertTitle>
									{msgList.map((err, i) => (
										<Typography key={i}>
											{createIconElement(i)}
											{msgList[i]}
										</Typography>
									))}
								</Alert>

								<Collapse in={isYamlOpen} timeout="auto" unmountOnExit>
									{error !== undefined && (
										<JobErrorDetails formattedYaml={formattedYaml} />
									)}
								</Collapse>

								<Box className={classes.buttonContainer}>
									<Button
										onClick={() => setIsYamlOpen(!isYamlOpen)}
										className={classes.showDetailsBtn}
									>
										{isYamlOpen ? "Hide" : "Show"} Details
									</Button>

									<Tooltip title={"Copy Error"}>
										<IconButton
											className={classes.copyIconBtn}
											onClick={handleCopyError}
											aria-label="Copy Error"
											size="large"
										>
											<ContentCopyIcon />
										</IconButton>
									</Tooltip>
								</Box>
							</Box>
						</ListItem>
					</div>
				</Collapse>
			)}
			<Divider />
		</>
	);
}

// REDUX MAPPINGS
const mapDispatchToProps = (dispatch: any) => {
	return {
		showSnackbar: (snackbar: SnackbarActionPayload) => {
			dispatch(showSnackbar(snackbar));
		}
	};
};
// export default JobComponent;
export default connect(undefined, mapDispatchToProps)(JobComponent);
