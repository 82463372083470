import { Dashboard } from "components/monitoring/dashboard/types";
import DashboardUtils from "components/monitoring/dashboard/utils";
import DashboardsApi from "services/api/DashboardsApi";
import {
	dashboardSaveFailed,
	dashboardSaveSuccess
} from "store/dashboard/actions";
import { dashboardsSelector } from "store/dashboard/selectors";
import { DASHBOARD_ACTION, DashboardSaveAction } from "store/dashboard/types";
import { takeLatest } from "redux-saga/effects";
import { call, put, select } from "typed-redux-saga";

function* saveDashboard(action: DashboardSaveAction) {
	try {
		const dashboardMap: Map<string, Dashboard> = yield select(
			dashboardsSelector
		);
		dashboardMap.set(action.payload.dashboard.name, action.payload.dashboard);

		const dashboards: Dashboard[] = DashboardUtils.mapToArray(dashboardMap);

		const response = yield* call(DashboardsApi.save, dashboards);
		console.log("saved dashboards", response.data);
		yield put(dashboardSaveSuccess(dashboards));
	} catch (e: any) {
		console.error("dashboard save error:", e);
		yield put(dashboardSaveFailed(e.message));
	}
}

export default function* DashboardSideEffects() {
	yield takeLatest(DASHBOARD_ACTION.SAVE, saveDashboard);
}
