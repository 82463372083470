import { Expression, InfluxDB, IResults } from "influx";
import moment from "moment";
import {
	INFLUX_DATABASE_NAME,
	LOG_FETCH_PAGE_SIZE
} from "components/logViewer/const";
import { Config } from "services/config/Config";
import { LogLine } from "components/logViewer/types";
import { Node } from "pages/management/node/types";
import objectHash from "object-hash";
import { Cluster } from "pages/management/cluster/types";
import { Host } from "pages/management/host/types";

export default class LogViewerService {
	private static getInfluxInstance() {
		return new InfluxDB({
			protocol: Config.influx_protocol,
			host: Config.influx_host,
			port: Config.influx_port,
			username: Config.influx_username,
			password: Config.influx_password,
			database: INFLUX_DATABASE_NAME
		});
	}

	private static buildWhereStatementPaths(logPaths: string[]) {
		let where = "";

		logPaths.forEach((logPath: string, index: number) => {
			index === 0
				? (where += new Expression()
						.field("fpath")
						.equals.value(logPath)
						.toString())
				: (where +=
						" " +
						new Expression().or
							.field("fpath")
							.equals.value(logPath)
							.toString());
		});

		return where;
	}

	private static buildWhereStatement(
		cluster: Cluster,
		node?: Node,
		host?: Host
	): string {
		let where =
			"" + new Expression().field("cluster_id").equals.value(cluster.id);

		node &&
			(where +=
				"" + new Expression().and.field("node_id").equals.value(node.id));

		host &&
			(where +=
				"" + new Expression().and.field("host_id").equals.value(host.id));

		return where;
	}

	private static transformResponse(result: IResults<unknown>): LogLine[] {
		// console.time("transformResponse");
		const res = result.map(
			(resultLine: any): LogLine => ({
				...resultLine,
				_logLineHash: objectHash(resultLine)
			})
		);

		// console.timeEnd("transformResponse");

		return res;
	}

	static async fetchBefore(
		logPaths: string[],
		dateISOString: string,
		cluster: Cluster,
		node?: Node,
		host?: Host
	): Promise<LogLine[]> {
		let where = this.buildWhereStatementPaths(logPaths);
		where += " AND " + this.buildWhereStatement(cluster, node, host);

		where +=
			" " +
			new Expression().and
				.field("time")
				.lt.value(moment(dateISOString).toISOString());

		// console.log("fetch before", where);

		const response = await this.getInfluxInstance().query(
			`SELECT * FROM logs WHERE ${where} ORDER BY time DESC LIMIT ${LOG_FETCH_PAGE_SIZE}`
		);

		return this.transformResponse(response);
	}

	static async fetchAfter(
		logPaths: string[],
		date: string,
		cluster: Cluster,
		node?: Node,
		host?: Host
	): Promise<LogLine[]> {
		let where = this.buildWhereStatementPaths(logPaths);
		where += " AND " + this.buildWhereStatement(cluster, node, host);

		where += " " + new Expression().and.field("time").gt.value(date);

		// console.log("fetch after", date, where);

		const response = await this.getInfluxInstance().query(
			`SELECT * FROM logs WHERE ${where} ORDER BY time LIMIT ${LOG_FETCH_PAGE_SIZE}`
		);

		return this.transformResponse(response);
	}

	static async fetchPaths(cluster: Cluster, node?: Node, host?: Host) {
		let where = this.buildWhereStatement(cluster, node, host);

		console.log("query", `// SELECT DISTINCT fpath FROM logs WHERE ${where};`);

		return this.getInfluxInstance().query(
			`SELECT DISTINCT fpath FROM logs WHERE ${where};`
		);
	}
}
