import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';

export const styles = (theme: Theme) =>
	createStyles({
		logContent: {
			backgroundColor: "black",
			color: "white",
			overflow: "auto",
			padding: 5
		},
		logMessagesTypography: {
			fontSize: "0.9em",
			fontFamily: "monospace",
			whiteSpace: "break-spaces"
		},
		warningLog: {
			color: theme.palette.warning.main
		},
		errorLog: {
			color: theme.palette.error.main
		}
	});
