import { Theme } from "@mui/material";

import createStyles from "@mui/styles/createStyles";

export const styles = (theme: Theme) =>
	createStyles({
		root: {
			position: "absolute",
			top: 0,
			right: 0,
			zIndex: 2
		},
		formControl: {
			marginTop: 0,
			marginRight: theme.spacing(1)
		},
		selectOption: {
			minHeight: 25
		}
	});
