import { Theme } from "@mui/material";

import createStyles from "@mui/styles/createStyles";

export const styles = (theme: Theme) =>
	createStyles({
		root: {
			display: "flex"
		},
		appBar: {
			backgroundColor: "#424242",
			zIndex: 1300
		},
		menuButton: {
			marginRight: 20
		},
		content: {
			flexGrow: 1,
			padding: theme.spacing(1),
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2)
		}
	});
