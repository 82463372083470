import { Theme } from "@mui/material";

import createStyles from "@mui/styles/createStyles";

export const styles = (theme: Theme) =>
	createStyles({
		card: {
			width: "100%"
			// [theme.breakpoints.up("sm")]: {
			// 	width: 700
			// }
			// height: "100%"
		},
		noPaddingCardContent: {
			padding: 0,
			"&:last-child": {
				padding: 0
			}
		},

		// deployment logs dialog
		logContent: {
			backgroundColor: "black",
			color: "white",
			paddingTop: `${theme.spacing(5)} !important`
		},
		logsTypography: {
			fontSize: "0.9em",
			fontFamily: "monospace"
		},
		galeraManagerLogMessage: {
			color: theme.palette.primary.main
		},
		hostStderrMessage: {
			color: theme.palette.error.main
		},
		closeButton: {
			position: "absolute",
			right: theme.spacing(2),
			top: theme.spacing(2),
			color: theme.palette.grey[500]
		},
		copyDeploymentLogsBtn: {
			position: "absolute",
			bottom: 22,
			right: 26,
			color: "black"
		}
	});
