import { AppState } from "AppState";
import { appStore } from "index";
import { Host, HostLog } from "pages/management/host/types";
import { Subject } from "rxjs";
import AuthAPI from "services/api/AuthAPI";
import AppEnvironment from "services/appEnvironment";
import { JOB_STATUS, JobTracking } from "services/jobs/types";
import {
	addRunningJob,
	removeRunningJob,
	setTrackedJob
} from "store/jobsMonitor/actions";
import { setWsOffline, setWsOnline } from "../../store/systemStatus/actions";

class WsHandlerClass {
	private connection: WebSocket | undefined;
	private hostLogSubscriptions: Map<string, Subject<HostLog>> = new Map();
	private SEND_RETRIES = 5;
	private SEND_RETRY_TIMEOUT = 3000;

	public async connect(): Promise<void> {
		return new Promise((resolve, reject) => {
			if (this.isConnected()) {
				console.info("WebSocket is already connected!");
				resolve();
			} else {
				this.connection = new WebSocket(
					`${AppEnvironment.getWsHostAddress()}/v2/ws`
				);

				this.connection.onopen = (event: Event) => {
					console.log("WebSocket connection open!");
					this.authenticate();
					appStore.dispatch(setWsOnline());
					resolve();
				};

				this.connection.onclose = (event: CloseEvent) => {
					console.warn("WS connection closed:", event);
					appStore.dispatch(setWsOffline());
				};

				this.connection.onerror = (event: Event) => {
					console.error("WebSocket connection error:", event);
					reject();
				};

				this.connection.onmessage = (event: MessageEvent) => {
					console.log("WebSocket message received", JSON.parse(event.data));

					const message = JSON.parse(event.data);

					switch (message.type) {
						case "auth":
							this.authenticate();
							break;
						case "log":
							this.handleLogMessages(message);
							break;
						case "jobs":
							this.handleJobsMessages(message);
					}
				};
			}
		})
	}

	public disconnect() {
		this.isConnected() && this.connection?.close(1000, "Monitoring stopped");
	}

	public isConnected(): boolean {
		return this.connection?.readyState === WebSocket.OPEN;
	}

	private async authenticate() {
		await AuthAPI.refreshWsAccessToken().then((jwt: string) => {
			this.sendMsg({
				type: "auth",
				value: jwt
			});
		});
	}

	private async sendMsg(msg: any, attempt = 0) {
		// logger.info('sendMsg', msg, attempt, this.client.readyState, this.client.readyState !== WebSocket.OPEN);
		// console.log(
		// 	`Sending WS message: ${JSON.stringify(msg)}. Attempts #${
		// 		attempt + 1
		// 	}. WS ${
		// 		this.connection?.readyState !== WebSocket.OPEN ? " not " : ""
		// 	} open`
		// );

		if (attempt >= this.SEND_RETRIES) {
			console.error(
				`WS connection not open after ${attempt + 1} attempts to send msg.`
			);
		} else if (this.connection?.readyState !== WebSocket.OPEN) {
			// if connection is undefined or closed - connect
			if (!this.connection || this.connection.readyState === WebSocket.CLOSED) {
				await this.connect();
			} else if (this.connection.readyState === WebSocket.CONNECTING) {
				console.warn("WS connection is in status CONNECTING.");
			} else if (this.connection.readyState === WebSocket.CLOSING) {
				console.warn("WS connection is in status CLOSING.");
			}

			console.warn(`WS connection not open yet, retrying to send msg...`);

			setTimeout(() => {
				this.sendMsg(msg, ++attempt);
			}, this.SEND_RETRY_TIMEOUT);
		} else {
			this.connection.send(JSON.stringify(msg));
			// console.info(`WS message sent: ${JSON.stringify(msg)}`);
		}
	}

	private handleLogMessages(data: any) {
		// console.log("log message received", data.value);
		const log = data.value as HostLog;
		// console.log("log message");
		const subscriptionStr = `cluster:${log["cluster-id"]}:host:${log["host-id"]}`;
		// console.log(
		// 	"subscription str",
		// 	subscriptionStr,
		// 	this._hostLogSubscriptions
		// );
		if (this.hostLogSubscriptions.has(subscriptionStr) && log.msg.trim()) {
			const observable = this.hostLogSubscriptions.get(subscriptionStr);
			observable && observable.next(log);
		}
	}

	private handleJobsMessages(data: any) {
		console.log("job status change message", data);

		const jobUpdate = data.value as JobTracking;

		// RUNNING JOBS
		// if job status is running - add it to store
		if (jobUpdate.status === JOB_STATUS.RUNNING) {
			appStore.dispatch(addRunningJob(jobUpdate));
		} else {
			const appState = appStore.getState() as AppState;

			// if job status is not running, and it's found in running jobs - remove it from the list of running jobs
			if (
				appState.jobMonitor.runningJobList.find(
					(job: JobTracking) => job.id === jobUpdate.id
				)
			) {
				appStore.dispatch(removeRunningJob(jobUpdate.id));
			}
		}

		// TRACKED JOBS
		const state = appStore.getState() as AppState;
		const { jobsToTrack } = state.jobMonitor;
		console.log(
			"jobsToTrack",
			jobsToTrack,
			jobUpdate.id,
			jobsToTrack.includes(jobUpdate.id)
		);
		if (jobsToTrack.includes(jobUpdate.id)) {
			appStore.dispatch(setTrackedJob(jobUpdate));
		}
	}

	public subscribeToLogs(host: Host): Subject<HostLog> {
		// console.log("subscribe to logs", subscription, this._connection);

		const subscriptionValue = `cluster:${host.clusterID}:host:${host.id}`;

		this.sendMsg({
			type: "subscribe",
			value: subscriptionValue
		});
		const logs: Subject<HostLog> = new Subject<HostLog>();

		this.hostLogSubscriptions.set(subscriptionValue, logs);

		return logs;
	}

	public unsubscribeFromLogs(host: Host) {
		// send unsubscribe message
		this.sendMsg({
			type: "unsubscribe",
			value: `host:${host.name}`
		});

		// delete observable
		if (this.hostLogSubscriptions.has(host.name)) {
			this.hostLogSubscriptions.delete(host.name);
		}
	}
}

export const WSHandler = new WsHandlerClass();
