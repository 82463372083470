export const LOG_FETCH_PAGE_SIZE = 500;
export const INFLUX_DATABASE_NAME = "gmd";
export const DEFAULT_COLORS = [
	"#316B83", // muted blue
	"#FBC6A4", // safety orange
	"#5B6D5B", // cooked asparagus green
	"#FF7878", // brick red
	"#6155A6", // muted purple
	"#AA8976", // chestnut brown
	"#C490E4", // raspberry yogurt pink
	"#555555", // middle gray
	"#BFD8B8", // curry yellow-green
	"#94DAFF" // blue-teal
];