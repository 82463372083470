import { useEffect, useState } from "react";
// redux
import { connect } from "react-redux";
import { AppState } from "AppState";
import { versionInfoSelector } from "store/version/selectors";
// MUI
import { Box, Button, Link, Paper, Tab, Tabs, Typography } from "@mui/material";
import useStyles from "pages/about/styles";
// assets
import logo from "assets/images/gc_logo_orange.png";

interface ReduxStateProps {
	versionInfo: string;
}

type Props = ReduxStateProps;

function AboutPage(props: Props) {
	const [changelogText, setChangelogText] = useState("");
	const [activeTab, setActiveTab] = useState(0);

	const classes = useStyles();

	// length of "</ul>" is 5 characters, we want to copy entire unordered list ( <ul> ... </ul> )
	const lastChanges = changelogText.slice(
		0,
		changelogText.indexOf("</ul>") + 5
	);

	const { versionInfo } = props;

	const handleChange = (event: any, newValue: number) => {
		setActiveTab(newValue);
	};

	const handleOpenChangelog = () => {
		setActiveTab(1);
	};

	// used for MUI tabs
	function a11yProps(index: number) {
		return {
			id: `simple-tab-${index}`,
			"aria-controls": `simple-tabpanel-${index}`
		};
	}

	useEffect(() => {
		fetch("/docs/changelog-mini/")
			.then((response) => {
				return response.text();
			})
			.then((data) => {
				const parsedText = parseText(data);
				setChangelogText(parsedText);
			});
	}, []); // only fetch during initial page load

	const parseText = (text: string) => {
		let newText = "";
		// give special design to words for all 3 types of changes
		newText = text.replaceAll("feature:", '<span class="type">feature:</span>');
		newText = newText.replaceAll("fix:", '<span class="type">fix:</span>');
		newText = newText.replaceAll("misc:", '<span class="type">misc:</span>');
		return newText;
	};

	const aboutPage = (
		<>
			<Box className={classes.logoAndTitleContainer}>
				<div className={classes.logoContainer}>
					<img src={logo} className={classes.logo} alt="Galera Cluster" />
				</div>
				<Box className={classes.titleAndVersion}>
					<Typography
						variant="h3"
						component="h1"
						align="center"
						className={classes.title}
					>
						Galera Manager
					</Typography>

					<Typography variant="subtitle1" className={classes.version}>
						Version: {versionInfo}
					</Typography>
				</Box>
			</Box>
			<Typography className={classes.paragraph}>
				Read our{" "}
				<Link
					href="https://galeracluster.com/category/blog/"
					target="_blank"
					className={classes.link}
				>
					blog post on the new features we have developed
				</Link>
				.
			</Typography>
			<Typography className={classes.paragraph}>
				Need help with Galera Manager? Contact us on{" "}
				<Link
					href="https://github.com/codership/galera-manager-support"
					target="_blank"
					className={classes.link}
				>
					Github
				</Link>
				.
			</Typography>
			<Typography component="h2" variant="h5" className={classes.latestChanges}>
				Latest changes
			</Typography>
			<div
				dangerouslySetInnerHTML={{ __html: lastChanges }}
				className={classes.changelogDiv}
			></div>
			<Button
				variant="outlined"
				onClick={handleOpenChangelog}
				className={classes.seeChangesBtn}
			>
				See previous changes
			</Button>
		</>
	);

	const changelogPage = (
		<div
			dangerouslySetInnerHTML={{ __html: changelogText }}
			className={classes.changelogDiv}
		></div>
	);

	return (
		<Paper className={classes.paper}>
			<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
				<Tabs
					value={activeTab}
					onChange={handleChange}
					aria-label="about page"
					variant="fullWidth"
				>
					<Tab label={<Typography>About</Typography>} {...a11yProps(0)}></Tab>
					<Tab label={<Typography>Changelog</Typography>} {...a11yProps(1)} />
				</Tabs>
			</Box>

			<Box className={classes.container}>
				{(activeTab === 0 && aboutPage) || (activeTab === 1 && changelogPage)}
			</Box>
		</Paper>
	);
}

// REDUX MAPPINGS
const mapStateToProps = (state: AppState) => {
	return {
		versionInfo: versionInfoSelector(state)
	};
};

export default connect(mapStateToProps, undefined)(AboutPage);
