import Axios from "axios";
import { HOST_TYPE } from "pages/management/host/types";
import AppEnvironment from "services/appEnvironment";
import { deattribute } from "kitsu-core";
import {
	SupportMatrixDBEngine,
	SupportMatrixSystem
} from "../supportMatrix/types";

const PATH = "supportMatrixItems";

class SupportMatrixApi {
	static async fetchSupportMatrix(
		cloudProvider?: HOST_TYPE
	): Promise<SupportMatrixSystem[]> {
		try {
			const response = await Axios.get(
				`${AppEnvironment.getApiAddress()}/${AppEnvironment.getApiVersion()}/${PATH}`,
				{
					params: {
						cloud_provider: cloudProvider
					}
				}
			);

			const supportMatrix = response.data.data.map((item: any) =>
				deattribute(item)
			);

			console.log("support matrix", cloudProvider, supportMatrix);

			supportMatrix.sort(
				(
					supportMatrixSystem1: SupportMatrixSystem,
					supportMatrixSystem2: SupportMatrixSystem
				) => supportMatrixSystem1.id.localeCompare(supportMatrixSystem2.id)
			);

			supportMatrix.forEach((supportMatrixSystem: SupportMatrixSystem) => {
				supportMatrixSystem.dbEngines.sort(
					(supportMatrixDb1: SupportMatrixDBEngine, supportMatrixDb2) =>
						supportMatrixDb1.id.localeCompare(supportMatrixDb2.id)
				);
			});

			return supportMatrix;
		} catch (e) {
			throw e;
		}
	}
}

export default SupportMatrixApi;
