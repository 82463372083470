import { history } from "Router";
import { showSnackbar } from "components/snackbar/actionCreators";
import { SNACKBAR_TYPE } from "components/snackbar/types";
import AuthAPI from "services/api/AuthAPI";
import UsersApi from "services/api/UsersApi";
import {
	loginFailed,
	loginSuccess,
	logoutFailed,
	logoutSuccess
} from "store/auth/actions";
import { AUTH_ACTION, AuthLogin } from "store/auth/stateTypes";
import { dashboardGetSuccess } from "store/dashboard/actions";
import { stopMetricsMonitor } from "store/metricsStore/actions";
import { securePreload } from "store/preloader/actions";
import { takeLatest } from "redux-saga/effects";
import { call, put } from "typed-redux-saga";

function* bootAuthCheckSideEffect() {
	try {
		const currentUser = yield* call(UsersApi.getMe);
		yield put(loginSuccess(currentUser));
		yield put(dashboardGetSuccess(currentUser.meta?.dashboards || []));
	} catch (e: any) {
		console.error("Boot auth check failed.", e);
	}
}

function* loginSideEffect({ payload: { credentials } }: AuthLogin) {
	// console.log("login side effect", credentials);

	try {
		const userData = yield* call(AuthAPI.login, credentials);

		yield put(loginSuccess(userData));
		yield put(dashboardGetSuccess(userData.meta.dashboards));
	} catch (e: any) {
		console.log("login error", e.message);
		yield put(loginFailed(e.message));
	}
}

function* authSuccessfulSideEffect() {
	yield put(
		showSnackbar({
			msg: "Welcome to Galera Manager!",
			snackbarType: SNACKBAR_TYPE.SUCCESS
		})
	);
	// if user has logged in from login page, redirect to app root.
	// this side effect is also triggered if user opens application on any other
	// route, and is already logged in, and in that case we're not redirecting him
	if (history.location.pathname === "/login") history.push("/");
	yield put(securePreload());
}

function* logoutSideEffect() {
	console.log("logout side effect");

	try {
		yield* call(AuthAPI.logout);
		yield put(logoutSuccess());
	} catch (e: any) {
		console.log("logout error", e.message);
		yield put(logoutFailed(e.message));
	}
}

function* logoutSuccessSideEffect() {
	history.push("/login");
	yield put(stopMetricsMonitor());
}

function* AuthSideEffects() {
	yield takeLatest(AUTH_ACTION.BOOT_CHECK, bootAuthCheckSideEffect);
	yield takeLatest(AUTH_ACTION.LOGIN, loginSideEffect);
	yield takeLatest(AUTH_ACTION.LOGIN_SUCCESS, authSuccessfulSideEffect);
	yield takeLatest(AUTH_ACTION.LOGOUT, logoutSideEffect);
	yield takeLatest(AUTH_ACTION.LOGOUT_SUCCESS, logoutSuccessSideEffect);
}

export default AuthSideEffects;
