import { hostListFetchSucceeded } from "store/host/actions";
import {
	HOST_ACTION,
	HostListFetchRequestedAction
} from "pages/management/host/types";
import HostsAPI from "services/api/HostsAPI";
import { call, put } from "typed-redux-saga";
import { takeLatest } from "redux-saga/effects";

function* fetchHostList(action: HostListFetchRequestedAction) {
	// console.log("fetchHostList saga");
	const { clusterID } = action.payload;

	try {
		const hostList = yield* call(HostsAPI.fetchList, clusterID);
		// console.log("saga host list fetched", hostList);

		yield put(hostListFetchSucceeded(clusterID, hostList));
	} catch (e) {
		console.error(e);
	}
}

function* HostSideEffects() {
	yield takeLatest(HOST_ACTION.LIST_FETCH_REQUESTED, fetchHostList);
}

export default HostSideEffects;
