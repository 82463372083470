import {
	Avatar,
	CardHeader,
	Divider,
	IconButton,
	ListItemIcon,
	ListItemText,
	MenuItem
} from "@mui/material";
import { WithTheme, WithStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import withStyles from "@mui/styles/withStyles";
import { MoreVert, Person } from "@mui/icons-material";
import { AppState } from "AppState";
import { showSnackbar } from "components/snackbar/actionCreators";
import {
	SNACKBAR_TYPE,
	SnackbarActionPayload
} from "components/snackbar/types";
import React from "react";
import { connect } from "react-redux";
import { StaticContext } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import { styles } from "pages/users/styles";
import Menu from "@mui/material/Menu";
import DeleteIcon from "@mui/icons-material/Delete";
import { User } from "pages/users/types";
import UserFormComponent from "pages/users/UserFormComponent";
import { createSelector } from "reselect";
import UsersApi from "services/api/UsersApi";
import { AxiosError } from "axios";
import { userListFetchRequested } from "pages/users/actions";
import UserUtils from "pages/users/utils";

// component local state interface
interface LocalState {
	user: User;
	anchorEl: any;
	activeTab: number;
}

// PROPS
interface LocalProps {}

interface DispatchProps {
	showSnackbar: (snackbar: SnackbarActionPayload) => void;
	reloadUserList: () => void;
}

interface ReduxStateProps {
	user: User | undefined;
}

type Props = LocalProps &
	DispatchProps &
	ReduxStateProps &
	WithStyles<typeof styles> &
	WithTheme &
	RouteComponentProps<any, StaticContext, any>;

// COMPONENT
class UserManagerComponent extends React.Component<Props, LocalState> {
	constructor(props: Props) {
		super(props);

		if (props.user) {
			this.state = {
				user: props.user,
				anchorEl: null,
				activeTab: 0
			};
		} else {
			this.props.showSnackbar({
				msg: `Error loading user data.`,
				snackbarType: SNACKBAR_TYPE.ERROR
			});
		}
	}

	onDeleteClick = () => {
		UsersApi.delete(this.state.user).then(
			() => {
				this.props.reloadUserList();
				this.props.showSnackbar({
					msg: `User '${this.state.user.name}' deleted.`,
					snackbarType: SNACKBAR_TYPE.SUCCESS
				});
				this.props.history.push("/users");
			},
			(error: AxiosError) => {
				this.props.showSnackbar({
					msg: `Failed to delete user: ${
						error.response ? error.response.data.wrapped.msg : error.message
					}`,
					snackbarType: SNACKBAR_TYPE.ERROR
				});
			}
		);
	};

	static getDerivedStateFromProps(nextProps: Props) {
		return { user: nextProps.user };
	}

	onMoreClick = (event: any) => {
		// isExpanded menu
		this.setState({ ...this.state, anchorEl: event.currentTarget });
	};

	onMenuClose = () => {
		this.setState({ ...this.state, anchorEl: null });
	};

	render(): React.ReactNode {
		const { theme } = this.props;
		const { user } = this.state;

		if (!this.state) return false;

		return (
			<Card>
				<CardHeader
					avatar={
						<Avatar
							style={{ backgroundColor: theme.palette.primary.main }}
							aria-label="User"
						>
							<Person />
						</Avatar>
					}
					action={
						<>
							<IconButton onClick={this.onMoreClick} size="large">
								<MoreVert />
							</IconButton>
							<Menu
								id="long-menu"
								anchorEl={this.state.anchorEl}
								open={Boolean(this.state.anchorEl)}
								onClose={this.onMenuClose}
							>
								<MenuItem onClick={this.onDeleteClick} color={"secondary"}>
									<ListItemIcon>
										<DeleteIcon />
									</ListItemIcon>
									<ListItemText primary="Delete user" />
								</MenuItem>
							</Menu>
						</>
					}
					title={user.name}
					subheader={UserUtils.getRoleName(user.role)}
				/>
				<Divider />
				<CardContent>
					<UserFormComponent user={user} readOnly={true} />
				</CardContent>
			</Card>
		);
	}
}

// selectors
const makeUserSelector = () =>
	createSelector(
		(state: AppState) => state.userList,
		(state: AppState, props: Props) => props.match.params.name,
		(userList: User[], userName: string) =>
			userList.find((user: User) => user.name === userName)
	);

// REDUX MAPPINGS
const mapGlobalStateToProps = (state: AppState, props: Props) => {
	const userSelector = makeUserSelector();

	return {
		user: userSelector(state, props)
	};
};

const mapGlobalDispatchToProps = (dispatch: any) => ({
	showSnackbar: (snackbar: SnackbarActionPayload) => {
		dispatch(showSnackbar(snackbar));
	},
	reloadUserList: () => {
		dispatch(userListFetchRequested());
	}
	// clusterDeleteRequested: (cluster: Cluster) => {
	// 	dispatch(clusterDeleteRequested(cluster));
	// },
	// nodeCreateWizardShow: (cluster: Cluster) => {
	// 	dispatch(nodeCreateWizardShow(cluster));
	// }
});

export default withStyles(styles, { withTheme: true })(
	connect(mapGlobalStateToProps, mapGlobalDispatchToProps)(UserManagerComponent)
);
