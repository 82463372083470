import { createTheme, Theme } from "@mui/material/styles";

// // https://mui.com/guides/migration-v4/#types-property-quot-palette-quot-quot-spacing-quot-does-not-exist-on-type-defaulttheme
declare module "@mui/styles/defaultTheme" {
	interface DefaultTheme extends Theme {}
}

const defaultTheme = createTheme();

export const theme = createTheme({
	components: {
		MuiCardHeader: {
			styleOverrides: {
				root: {
					paddingBottom: defaultTheme.spacing(1),
					paddingTop: defaultTheme.spacing(1)
				},
				action: {
					marginTop: 5
				}
			}
		},
		MuiDivider: {
			styleOverrides: {
				root: {
					margin: "1 0"
				}
			}
		},
		MuiButton: {
			defaultProps: {
				size: "small",
				color: "inherit",
				variant: "text"
			}
		},
		MuiFilledInput: {
			defaultProps: {
				margin: "dense"
			}
		},
		MuiFormControl: {
			defaultProps: {
				// margin: "dense",
				// variant: "standard",
				variant: "outlined"
			}
		},
		MuiFormHelperText: {
			defaultProps: {
				// margin: "dense"
			}
		},
		MuiIconButton: {
			defaultProps: {
				size: "small"
			}
		},
		MuiInputBase: {
			defaultProps: {
				// margin: "dense"
			}
		},
		MuiInputLabel: {
			defaultProps: {
				// margin: "dense"
			}
		},
		MuiListItem: {
			defaultProps: {
				dense: true
			}
		},
		MuiOutlinedInput: {
			defaultProps: {
				// margin: "dense"
			}
		},
		MuiFab: {
			defaultProps: {
				size: "small"
			}
		},
		MuiTable: {
			defaultProps: {
				size: "small"
			}
		},
		MuiTextField: {
			defaultProps: {
				margin: "dense",
				variant: "standard"
			}
		},
		MuiSelect: {
			defaultProps: {
				variant: "outlined" // BKBK
			}
		},
		MuiToolbar: {
			defaultProps: {
				variant: "dense"
			}
		},
		MuiTabs: {
			defaultProps: {
				indicatorColor: "secondary",
				textColor: "secondary"
			}
		}
	},
	spacing: 4,
	palette: {
		primary: {
			main: "#ed7703",
			contrastText: "#fff"
		},
		secondary: {
			main: "#1346bc",
			contrastText: "#fff"
		},
		error: {
			main: "#ed0303"
		},
		warning: {
			main: "#eded03"
		},
		success: {
			main: "#4c9404"
		}
	}
});
