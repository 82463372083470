export enum CONNECTION_STATUS {
	ONLINE = "ONLINE",
	WEAK = "WEAK",
	OFFLINE = "OFFLINE"
}

export interface SystemStatusState {
	backendConnectionStatus: CONNECTION_STATUS;
	influxDBConnectionStatus: CONNECTION_STATUS;
	wsConnectionStatus: CONNECTION_STATUS;
}

export enum SYSTEM_STATUS_ACTION {
	BACKEND_SET_ONLINE = "SYSTEM_STATUS/BACKEND_SET_ONLINE",
	BACKEND_SET_WEAK = "SYSTEM_STATUS/BACKEND_SET_WEAK",
	BACKEND_SET_OFFLINE = "SYSTEM_STATUS/BACKEND_SET_OFFLINE",
	INFLUXDB_SET_ONLINE = "SYSTEM_STATUS/INFLUXDB_SET_ONLINE",
	INFLUXDB_SET_WEAK = "SYSTEM_STATUS/INFLUXDB_SET_WEAK",
	INFLUXDB_SET_OFFLINE = "SYSTEM_STATUS/INFLUXDB_SET_OFFLINE",
	WS_SET_ONLINE = "SYSTEM_STATUS/WS_SET_ONLINE",
	WS_SET_OFFLINE = "SYSTEM_STATUS/WS_SET_OFFLINE"
}

export interface BackendSetOnlineAction {
	type: SYSTEM_STATUS_ACTION.BACKEND_SET_ONLINE;
}

export interface BackendSetWeakAction {
	type: SYSTEM_STATUS_ACTION.BACKEND_SET_WEAK;
}

export interface BackendSetOfflineAction {
	type: SYSTEM_STATUS_ACTION.BACKEND_SET_OFFLINE;
}

export interface InfluxDBSetOnlineAction {
	type: SYSTEM_STATUS_ACTION.INFLUXDB_SET_ONLINE;
}

export interface InfluxDBSetWeakAction {
	type: SYSTEM_STATUS_ACTION.INFLUXDB_SET_WEAK;
}

export interface InfluxDBSetOfflineAction {
	type: SYSTEM_STATUS_ACTION.INFLUXDB_SET_OFFLINE;
}

export interface WsSetOnlineAction {
	type: SYSTEM_STATUS_ACTION.WS_SET_ONLINE;
}

export interface WsSetOfflineAction {
	type: SYSTEM_STATUS_ACTION.WS_SET_OFFLINE;
}

export type SystemStatusAction =
	| BackendSetOnlineAction
	| BackendSetWeakAction
	| BackendSetOfflineAction
	| InfluxDBSetOnlineAction
	| InfluxDBSetWeakAction
	| InfluxDBSetOfflineAction
	| WsSetOnlineAction
	| WsSetOfflineAction;
