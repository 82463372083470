import { FormControl, Grid, TextField } from "@mui/material";
import RegionSelectComponent from "components/form/digitalOceanConfig/regionSelect/RegionSelectComponent";
import SizeSelectComponent from "components/form/digitalOceanConfig/sizeSelect/SizeSelectComponents";
import {
	DigitalOceanConfig,
	ISizeExt
} from "components/form/digitalOceanConfig/types";
import DigitalOceanUtils from "components/form/digitalOceanConfig/utils";
import { LoadingAdornment } from "components/form/inputAdornments/LoadingAdornment";
import { VerifiedAdornment } from "components/form/inputAdornments/VerifiedAdornment";
import { IRegion } from "dots-wrapper/dist/region";
import React, { ChangeEvent, FormEvent } from "react";
import { BehaviorSubject, debounceTime, filter, tap } from "rxjs";
import DigitalOceanAPI from "services/api/DigitalOceanAPI";
import { INPUT_VARIANT } from "components/form/const";

interface LocalState {
	isValid: boolean;
	isTokenVerified: boolean;
	isTokenVerifying: boolean;
	isTokenError: boolean;
	tokenMsg?: string;
	regions?: IRegion[];
	areRegionsLoading: boolean;
	regionMsg?: string;
	availableSizes?: string[];
	allSizes: Map<string, ISizeExt[]>;
	areSizesLoading: boolean;
	sizeMsg?: string;
}

interface LocalProps {
	config: DigitalOceanConfig;
	onChange?: (config: DigitalOceanConfig) => void;
	readOnly?: boolean;
	onValidationChange?: (isValid: boolean) => void;
	isSubmitClicked?: boolean;
}

class DigitalOceanConfigComponent extends React.PureComponent<
	LocalProps,
	LocalState
> {
	_accessTokenObservable?: BehaviorSubject<string>;
	_tokenVerifyAbortController?: AbortController;

	constructor(props: LocalProps) {
		super(props);

		this._accessTokenObservable = new BehaviorSubject<string>(
			props.config?.accessToken || ""
		);

		this.state = {
			isValid: false,
			isTokenVerified: false,
			isTokenVerifying: false,
			isTokenError: false,
			areRegionsLoading: false,
			areSizesLoading: false,
			allSizes: new Map<string, ISizeExt[]>()
		};
	}

	componentDidMount() {
		this._accessTokenObservable
			?.pipe(
				tap((accessToken: string) => {
					if (accessToken) {
						this.setState({
							isTokenVerified: false,
							isTokenVerifying: true,
							isTokenError: false,
							tokenMsg: "Verifying..."
						});
					} else {
						this.setState({
							isTokenVerified: false,
							isTokenVerifying: false,
							tokenMsg: "Please enter access token"
						});
					}
				}),
				filter((accessToken: string) => !!accessToken && !this.props.readOnly),
				debounceTime(2000)
			)
			.subscribe((accessToken: string) => {
				// console.log("accessToken subject changed", accessToken);
				this.verifyToken(accessToken);
			});
	}

	componentWillUnmount() {
		this._accessTokenObservable?.unsubscribe();
		this.cancelAsyncRequests();
	}

	componentDidUpdate(
		prevProps: Readonly<LocalProps>,
		prevState: Readonly<LocalState>,
		snapshot?: any
	) {
		if (
			prevState.isTokenVerified !== this.state.isTokenVerified ||
			prevState.areRegionsLoading !== this.state.areRegionsLoading ||
			prevState.areSizesLoading !== this.state.areSizesLoading
		) {
			this.props.onValidationChange &&
				this.props.onValidationChange(
					this.state.isTokenVerified &&
						!this.state.areRegionsLoading &&
						!this.state.areSizesLoading
				);
		}
	}

	cancelAsyncRequests() {
		if (this._tokenVerifyAbortController) {
			this._tokenVerifyAbortController.abort();
			delete this._tokenVerifyAbortController;
		}
	}

	async verifyToken(accessToken: string) {
		this.cancelAsyncRequests();

		try {
			this._tokenVerifyAbortController = new AbortController();
			const account = await DigitalOceanAPI.verifyToken(
				accessToken,
				this._tokenVerifyAbortController
			);
			delete this._tokenVerifyAbortController;

			console.log("token verified", account);
			this.setState({
				isTokenVerified: true,
				isTokenVerifying: false,
				tokenMsg: `Account: ${account.email}`
			});

			this.getRegions(accessToken);
			this.getAllSizes(accessToken);
		} catch (error: any) {
			console.error("DigitalOcean access token validation failed", error);
			this.setState({
				isTokenVerified: false,
				isTokenVerifying: false,
				isTokenError: true,
				tokenMsg:
					error.response?.data?.message ||
					error.message ||
					"Failed to validate with DigitalOcean"
			});
		}
	}

	async getRegions(accessToken: string) {
		this.setState({
			areRegionsLoading: true,
			regions: []
		});

		try {
			const regions = await DigitalOceanAPI.fetchRegions(accessToken);

			// console.log("regions", regions);

			this.setState({
				regions: regions.sort((region1: IRegion, region2: IRegion) =>
					region1.name.toLowerCase().localeCompare(region2.name.toLowerCase())
				),
				areRegionsLoading: false
			});

			this.checkSelectedRegion(regions);
		} catch (error: any) {
			console.error("DigitalOcean regions fetch failed", error);
			this.setState({
				areRegionsLoading: false,
				regionMsg:
					error.response?.data?.message ||
					error.message ||
					"Failed to load regions from DigitalOcean"
			});
		}
	}

	checkSelectedRegion(regions: IRegion[]) {
		if (
			this.props.config.region &&
			!regions
				.map((region: IRegion) => region.slug)
				.includes(this.props.config.region)
		) {
			this.setState({
				availableSizes: [],
				regionMsg: `${this.props.config.region} is not available`
			});

			const updatedConfig = {
				...this.props.config,
				region: ""
			};

			this.props.onChange && this.props.onChange(updatedConfig);
		}
	}

	async getAllSizes(accessToken: string) {
		this.setState({
			allSizes: new Map<string, ISizeExt[]>(),
			areSizesLoading: true
		});

		try {
			const sizes = await DigitalOceanAPI.fetchAllSizes(accessToken);
			const sizesMap = DigitalOceanUtils.convertSizesToMap(sizes);

			this.setState({
				allSizes: sizesMap,
				areSizesLoading: false
			});
		} catch (error: any) {
			console.error("DigitalOcean sizes fetch failed", error);
			this.setState({
				areSizesLoading: false,
				sizeMsg:
					error.response?.data?.message ||
					error.message ||
					"Failed to load sizes from DigitalOcean"
			});
		}
	}

	render() {
		const {
			isTokenVerified,
			isTokenVerifying,
			isTokenError,
			tokenMsg,
			areRegionsLoading,
			regionMsg,
			areSizesLoading,
			sizeMsg,
			regions,
			availableSizes,
			allSizes
		} = this.state;
		const { config, onChange, readOnly, isSubmitClicked } = this.props;

		return (
			<>
				<Grid container item direction="column" spacing={0}>
					<Grid item xs={12}>
						<FormControl
							fullWidth={true}
							onInvalid={(e: FormEvent): void => {
								e.preventDefault();
								const form = e.target as HTMLFormElement;

								this.setState({
									isTokenError: true,
									tokenMsg: form.validationMessage as string
								});
							}}
							error={isTokenError || (isSubmitClicked && isTokenVerifying)}
						>
							<TextField
								required
								type="password"
								margin="dense"
								variant={INPUT_VARIANT}
								label="Access Token"
								error={isTokenError || (isSubmitClicked && isTokenVerifying)}
								helperText={!readOnly ? tokenMsg : ""}
								autoComplete="off"
								disabled={readOnly}
								inputProps={{
									maxLength: 250,
									"data-testid": "digital-ocean-access-token"
								}}
								// disabled={isDisabled}
								value={config.accessToken}
								onChange={(e: ChangeEvent) => {
									const field = e.target as HTMLFormElement;
									const accessToken = field.value as string;

									const updatedConfig = {
										...config,
										accessToken
									};

									onChange && onChange(updatedConfig);

									this._accessTokenObservable?.next(accessToken);
								}}
								InputProps={{
									endAdornment:
										!readOnly &&
										((isTokenVerified && <VerifiedAdornment />) ||
											(isTokenVerifying && (
												<LoadingAdornment tooltipTitle={"Verifying"} />
											)))
								}}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<RegionSelectComponent
							options={regions || []}
							value={config.region || ""}
							readOnly={readOnly}
							helperMsg={DigitalOceanUtils.getSelectFieldMessage(
								isTokenVerified,
								areRegionsLoading,
								regionMsg
							)}
							onChange={(selectedRegionSlug) => {
								// get available sizes in this region
								const selectedRegion = regions?.find((r: IRegion) => {
									return r.slug === selectedRegionSlug;
								});
								const availableSizes = selectedRegion?.sizes || [];

								// check if currently selected size is available in this region
								const isSelectedSizeSupported =
									!config.size || availableSizes.includes(config.size);

								this.setState((state: LocalState) => ({
									availableSizes,
									regionMsg: "",
									sizeMsg: isSelectedSizeSupported
										? state.sizeMsg
										: `${config.size} not available in ${
												selectedRegion?.name || selectedRegionSlug
										  } region`
								}));

								const updatedConfig = {
									...config,
									region: selectedRegionSlug,
									size: isSelectedSizeSupported ? config.size : ""
								};

								onChange && onChange(updatedConfig);
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<SizeSelectComponent
							options={allSizes}
							value={config.size || ""}
							availableOptions={availableSizes}
							readOnly={readOnly}
							helperMsg={DigitalOceanUtils.getSelectFieldMessage(
								isTokenVerified,
								areSizesLoading,
								sizeMsg
							)}
							onChange={(selectedSizeSlug) => {
								const updatedConfig = {
									...config,
									size: selectedSizeSlug
								};

								onChange && onChange(updatedConfig);

								this.setState({
									sizeMsg: ""
								});
							}}
						/>
					</Grid>
				</Grid>
			</>
		);
	}
}

export default DigitalOceanConfigComponent;
