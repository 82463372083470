export enum VERSION_ACTION {
	GET_SUCCESS = "VERSION/GET_SUCCESS",
	GET_FAILED = "VERSION/GET_FAILED"
}

export interface VersionGetSuccessAction {
	type: VERSION_ACTION.GET_SUCCESS;
	payload: {
		version: string;
	};
}

export interface VersionGetFailedAction {
	type: VERSION_ACTION.GET_FAILED;
	payload: {
		errorMsg: string;
	};
}

export type VersionAction = VersionGetSuccessAction | VersionGetFailedAction;
