// export interface ResultItem {
// 	metric: Metric;
// 	value: [number, string];
// }
//
// export interface Metric {
// 	__name__: string;
// 	gmd_cluster: string;
// 	gmd_db_engine: string;
// 	gmd_db_segment: string;
// 	gmd_host: string;
// 	gmd_host_system: string;
// 	gmd_node: string;
// 	instance: string;
// 	job: string;
// }

export interface NodeMetric {
	time: Date;
	cluster: string;
	node: string;
	value: number;
}

export interface ClusterMetric {
	time: Date;
	cluster: string;
	value: number;
}

export interface WsrepLocalStateMetric extends NodeMetric {
	type: METRIC.WSREP_LOCAL_STATE;
}

export interface WsrepLastCommittedMetric extends NodeMetric {
	type: METRIC.WSREP_LAST_COMMITTED;
}

export enum METRIC {
	WSREP_LOCAL_STATE = "wsrep_local_state",
	WSREP_LAST_COMMITTED = "wsrep_last_committed",
	MYSQL_UP = "mysql_up"
}


