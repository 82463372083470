import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import Axios, { AxiosError, AxiosResponse } from "axios";
import { Cluster } from "pages/management/cluster/types";
import { Node } from "pages/management/node/types";
import ChartMessage from "components/monitoring/charts/chartMessage/chartMessage";
import {
	ChartMetric,
	DEFAULT_AGGREGATION,
	DEFAULT_PERIOD,
	DEFAULT_REFRESH_INTERVAL
} from "components/monitoring/charts/const";
import LiveValueComponent from "components/monitoring/charts/timeSeries/liveValue/LiveValueComponent";
import {
	AxisRange,
	DateRange
} from "components/monitoring/charts/timeSeries/types";
import ChartUtils from "components/monitoring/charts/utils";
import { AGGREGATION } from "components/monitoring/dashboard/types";
import { IResponse } from "influx";
import { DateTime } from "luxon";
// @ts-ignore
import { layout } from "plotly-js-material-design-theme";
import { Component, ReactElement } from "react";
import { debounceTime, fromEvent, Subscription } from "rxjs";
import PlotComponent from "services/reactPlotlyPlot";
import { styles } from "./styles";
import { isEqual, isString } from "lodash";

interface LocalState {
	traces: any[];
	isLoading: boolean;
	dateRange: DateRange;
}

interface LocalProps {
	containerHeight?: number;
	containerWidth?: number;
	disableRender: boolean;
	dragToMove?: boolean;
	period?: string;
	title: string;
	metric: ChartMetric;
	cluster: Cluster;
	node?: Node;
	aggregation?: AGGREGATION;
	unit?: string;
	dataScaling?: number;
	yAxisMax?: number;
	tileSize?: string;
}

type Props = LocalProps & WithStyles<typeof styles>;

interface Snapshot {
	shouldFetchAsyncData: boolean;
	shouldClearExistingData: boolean;
}

class TimeSeriesChartComponent extends Component<Props, LocalState> {
	_isMounted = false;
	_scheduledFetch: any;
	_requestAbortController?: AbortController;
	_windowSizeObservable: Subscription;
	_lastRequestTimestamp?: number;
	_zoomRange?: AxisRange;
	_isAutoFetching: boolean = false;

	constructor(props: Props) {
		super(props);

		this.state = {
			traces: [],
			isLoading: true,
			dateRange: ChartUtils.getDateRange(props.period || "5m")
		};

		// rerender after window resize
		this._windowSizeObservable = fromEvent(window, "resize")
			.pipe(debounceTime(500))
			.subscribe(() => {
				this.forceUpdate();
			});
	}

	fetchData = (
		type: "startAutoFetch" | "scheduled" | "manualFetch",
		dateRange?: DateRange
	) => {
		// console.log("fetchData", type, this._isAutoFetching, this._scheduledFetch);
		if (!this.props.metric) return;

		if (type === "startAutoFetch") {
			if (!this._isAutoFetching) {
				this._isAutoFetching = true;
			} else {
				console.warn("Auto-fetch is already in progress.");
				return;
			}
		} else if (type === "scheduled") {
			if (!this._isAutoFetching) {
				console.warn(
					"Auto-fetching is not in progress but scheduled fetch tried executing. Aborted."
				);
				return;
			}
		} else if (type === "manualFetch") {
			this._isAutoFetching = false;
		}

		if (this._requestAbortController) {
			this._requestAbortController.abort();
			clearTimeout(this._scheduledFetch);
			delete this._scheduledFetch;
		}

		this.setState({ isLoading: true });

		let isCancelled = false;

		const {
			metric,
			cluster,
			node,
			period = DEFAULT_PERIOD,
			// resolution = DEFAULT_RESOLUTION,
			aggregation = DEFAULT_AGGREGATION,
			dataScaling = 1
		} = this.props;

		this._lastRequestTimestamp = DateTime.now().toMillis();

		const { promise, abortController } = ChartUtils.fetchData(
			cluster,
			aggregation,
			metric,
			dateRange || ChartUtils.getDateRange(period),
			node
		);

		this._requestAbortController = abortController;

		promise
			.then((response: AxiosResponse<IResponse>) => {
				// console.log("response", response);
				const shouldCancel =
					!this._isMounted || (this._zoomRange && !dateRange);

				if (shouldCancel) return;

				const traces = ChartUtils.parseData(
					response.data,
					dataScaling,
					aggregation
				);

				this.setState({
					traces,
					isLoading: false
				});
			})
			.catch((err: AxiosError) => {
				if (!Axios.isCancel(err)) {
					console.error(
						"Metrics error response:",
						err.message,
						err.response?.data
					);
					this.setState({
						isLoading: false
					});
				} else {
					// console.log("request cancelled");
					isCancelled = true;
				}
			})
			.finally(() => {
				if (!isCancelled) {
					this._requestAbortController = undefined;
					if (this._isMounted && !this._zoomRange) {
						// console.log("starting scheduled fetch", this._isAutoFetching);
						this._scheduledFetch = setTimeout(() => {
							this.fetchData("scheduled");
						}, ChartUtils.getRemainingIntervalTime(this._lastRequestTimestamp));
					}
				}
			});
	};

	getSnapshotBeforeUpdate(
		prevProps: Readonly<Props>,
		prevState: Readonly<LocalState>
	): any | null {
		return {
			shouldFetchAsyncData:
				!isEqual(prevProps.metric, this.props.metric) ||
				!isEqual(prevProps.period, this.props.period) ||
				!isEqual(prevProps.cluster, this.props.cluster) ||
				!isEqual(prevProps.node, this.props.node) ||
				!isEqual(prevProps.aggregation, this.props.aggregation) ||
				!isEqual(prevProps.dataScaling, this.props.dataScaling),
			shouldClearExistingData:
				!isEqual(prevProps.metric, this.props.metric) ||
				!isEqual(prevProps.cluster, this.props.cluster) ||
				!isEqual(prevProps.node, this.props.node) ||
				!isEqual(prevProps.aggregation, this.props.aggregation) ||
				!isEqual(prevProps.dataScaling, this.props.dataScaling)
		};
	}

	componentDidUpdate(
		prevProps: Readonly<Props>,
		prevState: Readonly<LocalState>,
		snapshot?: Snapshot
	): void {
		// if metric, cluster or node changed - reload async data
		if (this._isMounted && snapshot) {
			// console.log("snapshot", snapshot);
			if (snapshot.shouldFetchAsyncData) {
				this.clearAsyncRequests();
				// console.log("snapshot induced fetch");
				this._zoomRange = undefined;
				this.fetchData("startAutoFetch");
			}

			if (snapshot.shouldClearExistingData) {
				this.setState({ traces: [] });
			}
		}
	}

	shouldComponentUpdate(
		nextProps: Readonly<Props>,
		nextState: Readonly<LocalState>,
		nextContext: any
	): boolean {
		const didPropsChange = isEqual(nextProps, this.props);
		const didDataChange = isEqual(this.state.traces, nextState.traces);
		const didStateChange = isEqual(this.state.isLoading, nextState.isLoading);
		// console.log(
		// 	"shouldComponentUpdate",
		// 	didPropsChange,
		// 	didDataChange,
		// 	didStateChange
		// );
		// if (this._isRefreshPaused) {
		// 	console.log("refresh paused");
		// 	return true;
		// } else {
		return didDataChange || didPropsChange || didStateChange;
		// }
	}

	componentDidMount(): void {
		this._isMounted = true;
		this.fetchData("startAutoFetch");
	}

	componentWillUnmount(): void {
		this._isMounted = false;
		this._windowSizeObservable?.unsubscribe();
		this.clearAsyncRequests();
	}

	clearAsyncRequests(): void {
		if (this._requestAbortController) this._requestAbortController.abort();
		if (this._scheduledFetch) {
			clearTimeout(this._scheduledFetch);
			delete this._scheduledFetch;
		}
		this._isAutoFetching = false;
		// console.log("cleared", this._scheduledFetch);
	}

	render(): ReactElement {
		const { traces } = this.state;
		const {
			title,
			period,
			metric,
			aggregation,
			cluster,
			node,
			unit,
			yAxisMax,
			dragToMove
		} = this.props;
		const isThereAnyData = traces.length !== 0;

		const untypedConfigValues = {
			spikedistance: 200,
			hoverdistance: 200
		};

		const measurementUnit = ChartUtils.getMeasurementUnit(aggregation, unit);
		const message = ChartUtils.getMessage(isThereAnyData, dragToMove || false);
		const xAxisRange = this._zoomRange?.x || ChartUtils.getDateRange(period);
		const yAxisRange = this._zoomRange?.y || { from: 0, to: yAxisMax };

		// console.log("render chart", {
		// 	x: xAxisRange,
		// 	y: yAxisRange,
		// 	zoom: this._zoomRange
		// });

		return (
			<>
				{message && (
					<ChartMessage key={message} message={message} overlay={dragToMove} />
				)}

				{/*<CircularProgress*/}
				{/*	thickness={2}*/}
				{/*	size={20}*/}
				{/*	className={`${classes.progress} ${*/}
				{/*		isLoading ? classes.progressFadeIn : classes.progressFadeOut*/}
				{/*	}`}*/}
				{/*/>*/}

				<LiveValueComponent
					traces={traces.sort(
						(trace1: any, trace2: any) =>
							trace2.y[trace2.y.length - 1] - trace1.y[trace1.y.length - 1]
					)}
					// hostNames={sortedTraces.map((trace: any) => trace.name)}
					metric={metric}
					cluster={cluster}
					node={node}
					// lastValues={sortedTraces.map(
					// 	(trace: any) => trace.y[trace.y.length - 1]
					// )}
					// colors={sortedTraces.map((trace: any) => trace.line.color)}
					differential={aggregation === AGGREGATION.DIFFERENTIAL}
					refreshInterval={DEFAULT_REFRESH_INTERVAL}
				/>
				<PlotComponent
					key={"plot"}
					style={{ height: "100%", width: "100%" }}
					data={traces}
					layout={layout({
						...untypedConfigValues,
						font: {
							size: 10
						},
						autosize: true,
						title: {
							text: `${title}${
								measurementUnit ? " (" + measurementUnit + ")" : ""
							}`,
							font: {
								size: 14
							}
						},
						margin: { t: 50, l: 30, r: 0, b: 20 },
						showlegend: false,
						yaxis: {
							type: "linear",
							rangemode: "tozero",
							range: [yAxisRange.from, yAxisRange.to]
						},
						xaxis: {
							showticklabels: true,
							linecolor: "rgba(0, 0, 0, 0.3)",
							range: [xAxisRange.from.toISO(), xAxisRange.to.toISO()]
						}
					})}
					onRelayout={(event: Readonly<any>) => {
						// console.log(
						// 	"relayout event",
						// 	event,
						// 	event["xaxis.autorange"] || event["yaxis.autorange"]
						// );

						// if zoom is reset, fetch new data
						if (event["xaxis.autorange"] || event["yaxis.autorange"]) {
							this._zoomRange = undefined;
							// console.log("zoom reset");
							// fetch data and resume scheduled fetch
							// note: schedule is implicit - calling fetchData without params
							// 			 will fetch data for period selected in dashboard and start
							// 			 scheduled fetching
							this.fetchData("startAutoFetch");

							// if zoom is changed (other than reset) fetch new data for that period
						} else {
							let newZoomRange: AxisRange = {};

							// if y axis zoom changed
							if (event["yaxis.range[0]"] || event["yaxis.range[1]"]) {
								// get new y axis zoom range
								newZoomRange.y = {
									from: event["yaxis.range[0]"] || 0,
									to: event["yaxis.range[1]"] || yAxisRange
								};
							}

							// if x axis zoom changed
							if (event["xaxis.range[0]"] || event["xaxis.range[1]"]) {
								// console.log("x zoom changed");
								// if range is not a string (expecting date in string format)
								// do not update zoom range and force component update to keep existing zoom
								if (!isString(event["xaxis.range[0]"])) {
									// console.log("no data zoom");
									this.forceUpdate();
									return;
								}

								// get new x axis zoom range
								newZoomRange.x = {
									from: ChartUtils.parsePlotlyDateTime(event["xaxis.range[0]"]),
									to: ChartUtils.parsePlotlyDateTime(event["xaxis.range[1]"])
								};

								// if zoom period is too small, do not update zoom range
								// and force component update to keep existing zoom
								// console.log("zoom range", newZoomRange);
								if (
									newZoomRange.x?.to.toSeconds() -
										newZoomRange.x?.from.toSeconds() <
									10
								) {
									// console.log("zoom too small", xAxisRange);
									this.forceUpdate();
									return;
								}
							}

							// if x or y axis zoom change, update zoom range and fetch new data
							if (newZoomRange.x || newZoomRange.y) {
								this._zoomRange = { ...this._zoomRange, ...newZoomRange };
								// console.log("zoomed in", this._zoomRange);
								// fetch data for zoomed in period and pause scheduled fetch
								this.fetchData("manualFetch", this._zoomRange.x);
							}
						}
					}}
					config={{ displayModeBar: false, scrollZoom: false }}
				/>
			</>
		);
	}
}

export default withStyles(styles)(TimeSeriesChartComponent);
