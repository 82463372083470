// MUI
import {
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Tooltip
} from "@mui/material";
// icons
import {
	DeviceHub as DeviceHubIcon,
	ExitToApp as ExitToAppIcon,
	Info as InfoIcon,
	SupervisorAccount as SupervisorAccountIcon
} from "@mui/icons-material";
import { TextBoxOutline as TextBoxOutlineIcon } from "mdi-material-ui";

// redux
import { connect } from "react-redux";
import { logout } from "store/auth/actions";
// css
import { useStyles } from "components/sidebar/styles";
// react  router
import { useHistory } from "react-router-dom";

import { useLocation } from "react-router-dom";

interface PropsWindow {
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	window?: () => Window;
}

interface LocalProps {
	isDrawerOpen: boolean;
	title: string;
	logoutBtn?: boolean;
	handleCloseMobileSidebar?: () => void;
}

interface ReduxDispatchProps {
	logout: () => void;
}

type Props = LocalProps & PropsWindow & ReduxDispatchProps;

function getIcon(title: string) {
	switch (title) {
		case "Management":
			return <DeviceHubIcon />;
		case "Users":
			return <SupervisorAccountIcon />;
		case "System Log":
			return <TextBoxOutlineIcon />;
		case "About":
			return <InfoIcon />;
		case "Logout":
			return <ExitToAppIcon />;
		default:
			return <DeviceHubIcon />;
	}
}

function getUrl(title: string) {
	switch (title) {
		case "Management":
			return "clusters";
		case "Users":
			return "users";
		case "System Log":
			return "system-log";
		case "About":
			return "about";
		case "Logout":
			return;
		default:
			return;
	}
}

function SidebarItem(props: Props) {
	const classes = useStyles();
	let history = useHistory();
	const { isDrawerOpen, title, logoutBtn, handleCloseMobileSidebar } = props;
	const icon = getIcon(title);
	const url = getUrl(title);
	const location = useLocation();

	// check if pathname is same as url, to highlight sidebar item
	let pathname = location.pathname.slice(1);
	const indexChar = pathname.indexOf("/");
	if (indexChar > -1) {
		pathname = pathname.slice(0, indexChar);
	}
	const isCurrentlyVisited = pathname === url;

	const logOut = async () => {
		// todo: handle logging out loader and logout errors
		props.logout();
	};

	const handleClick = () => {
		if (logoutBtn) {
			logOut();
		} else {
			// change url ( react redux )
			history.push(`/${url}`);
			// and close sidebar on mobile devices
			if (handleCloseMobileSidebar) {
				handleCloseMobileSidebar();
			}
		}
	};

	return (
		<ListItem
			// key={text}
			disablePadding
			selected={isCurrentlyVisited}
			className={
				isCurrentlyVisited ? classes.sidebarItemVisited : classes.sidebarItem
			}
		>
			<Tooltip
				title={
					!isDrawerOpen ? <div className={classes.tooltip}>{title}</div> : ""
				}
				placement="right"
				arrow
			>
				<ListItemButton
					className={classes.sidebarItemBtn}
					sx={{
						justifyContent: isDrawerOpen ? "initial" : "center"
					}}
					onClick={handleClick}
				>
					<ListItemIcon
						className={
							isCurrentlyVisited
								? classes.sidebarItemIconVisited
								: classes.sidebarItemIcon
						}
						sx={{
							mr: isDrawerOpen ? 3 : "auto"
						}}
					>
						{icon}
					</ListItemIcon>
					<ListItemText
						primary={title}
						sx={{ opacity: isDrawerOpen ? 1 : 0 }}
						className={
							isCurrentlyVisited
								? classes.sidebarTextVisited
								: classes.sidebarText
						}
					/>
				</ListItemButton>
			</Tooltip>
		</ListItem>
	);
}

const mapGlobalDispatchToProps = (dispatch: any) => {
	return {
		logout: () => {
			dispatch(logout());
		}
	};
};
export default connect(undefined, mapGlobalDispatchToProps)(SidebarItem);
