import { AppState } from "AppState";
import { JobTracking } from "services/jobs/types";

export const runningJobsCountSelector = (
	state: AppState,
	clusterId?: number,
	nodeId?: number,
	hostId?: number
): number => {
	const filteredJobs = state.jobMonitor.runningJobList.filter(
		(job: JobTracking) => {
			const matchesCluster = job.meta.cluster_id === clusterId;
			const matchesNode = job.meta.node_id === nodeId;
			const matchesHost = job.meta.host_id === hostId;

			if (nodeId) {
				if (hostId) {
					return matchesCluster && matchesNode && matchesHost;
				} else {
					return matchesCluster && matchesNode;
				}
			} else if (hostId) {
				return matchesCluster && matchesHost;
			} else {
				return matchesCluster;
			}
		}
	);

	return filteredJobs.length;
};

export const jobsToTrackSelector = (state: AppState): number[] => {
	return state.jobMonitor.jobsToTrack
}
