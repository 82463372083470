import { HOST_SYSTEM, HOST_TYPE } from "../../../pages/management/host/types";
import { NODE_DB_ENGINE } from "../../../pages/management/node/types";
import { INPUT_VARIANT } from "../const";
import React from "react";
import {
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select
} from "@mui/material";
import HostUtils from "../../../pages/management/host/utils";
import SupportMatrix from "../../../services/supportMatrix/SupportMatrix";

interface Props {
	value: HOST_SYSTEM;

	hostType: HOST_TYPE;
	dbEngine: NODE_DB_ENGINE;

	readOnly: boolean;
	disabled: boolean;

	onChange: (value: HOST_SYSTEM) => void;
}

export const HostSystemFieldComponent = (props: Props) => {
	const { value, disabled, readOnly, hostType, dbEngine, onChange } = props;

	const getInfoMessage = (): string => {
		if (!readOnly && SupportMatrix.isSystemDeprecated(value, hostType)) {
			return `${value} is deprecated. It's not recommended to create new clusters using deprecated systems.`;
		} else {
			return "";
		}
	};

	return (
		<FormControl
			required={true}
			fullWidth={true}
			variant={INPUT_VARIANT}
			disabled={disabled}
			margin="dense"
		>
			<InputLabel htmlFor="host-system">Host system</InputLabel>
			<Select
				required
				variant={INPUT_VARIANT}
				disabled={disabled}
				label="Host system"
				data-testid="host-system-select-container"
				fullWidth={true}
				readOnly={readOnly}
				value={value}
				onChange={(e) => {
					const system = HostUtils.getHostSystemEnum(e.target.value as string);
					onChange(system);
				}}
				inputProps={{
					id: "host-system",
					"data-testid": "host-system-select"
				}}
			>
				{Object.values(HOST_SYSTEM)
					.filter((system: HOST_SYSTEM) =>
						SupportMatrix.isSystemSupported(system)
					)
					.map((system: HOST_SYSTEM) => (
						<MenuItem
							key={system}
							value={system}
							disabled={
								!SupportMatrix.isSystemSupportedByDb(system, dbEngine, hostType)
							}
						>
							{`${system}${
								!SupportMatrix.isSystemSupported(system, hostType)
									? ` (Not supported by ${hostType})`
									: !SupportMatrix.isSystemSupportedByDb(
											system,
											dbEngine,
											hostType
									  )
									? ` (Not supported by ${dbEngine})`
									: ""
							}${
								SupportMatrix.isSystemDeprecated(system, hostType)
									? " (Deprecated)"
									: ""
							}`}
						</MenuItem>
					))}
			</Select>
			<FormHelperText>{getInfoMessage()}</FormHelperText>
		</FormControl>
	);
};
