import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
	paper: {
		maxWidth: 900,
		margin: "auto",
		marginBottom: theme.spacing(6)
	},
	container: {
		padding: theme.spacing(3),
		[theme.breakpoints.up("sm")]: {
			paddingLeft: theme.spacing(6),
			paddingRight: theme.spacing(6),
			paddingBottom: theme.spacing(6)
		}
	},
	logoAndTitleContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexWrap: "wrap",
		marginTop: 10,
		marginBottom: 30
	},
	logoContainer: {
		display: "inline-block",
		width: 100,
		height: 100,
		minWidth: 100,
		marginRight: theme.spacing(5)
	},
	logo: {
		width: "100%",
		height: "100%"
	},
	title: {
		display: "inline-block"
	},
	titleAndVersion: {
		display: "flex",
		flexDirection: "column",
		alignContent: "flex-end",
		paddingTop: theme.spacing(4)
	},
	version: {
		marginLeft: theme.spacing(2),
		color: theme.palette.grey[600],
		lineHeight: 1.2,
		textAlign: "center",
		[theme.breakpoints.up("sm")]: {
			textAlign: "left"
		}
	},
	paragraph: {},
	link: {},
	latestChanges: {
		paddingTop: theme.spacing(8)
	},
	seeChangesBtn: {
		marginTop: theme.spacing(4)
	},
	changelogDiv: {
		"& h3": {
			fontSize: 24,
			marginTop: theme.spacing(4),
			marginBottom: theme.spacing(3)
		},
		"& ul": {
			marginTop: theme.spacing(3)
		},
		"& li": {
			color: theme.palette.grey[700],
			marginBottom: "0.5rem"
		},
		"& span.type": {
			fontWeight: "bold"
		}
	}
}));

export default useStyles;
