import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';

export const styles = (theme: Theme) =>
	createStyles({
		stateOk: {
			color: theme.palette.success.main
		},
		stateError: {
			color: theme.palette.error.main
		}
	});
