import Axios from "axios";
import { deattribute, serialise } from "kitsu-core";
import { Cluster, SerializedCluster } from "pages/management/cluster/types";
import APIUtils from "services/api/utils";
import AppEnvironment from "services/appEnvironment";
import { Job } from "services/jobs/types";

const ENDPOINT = "clusters";

let clusterList: Cluster[] = [];

class ClustersAPI {
	static async fetchList(): Promise<Cluster[]> {
		try {
			const clustersResponse = await Axios.get(APIUtils.getUrl(ENDPOINT));
			// console.log("cluster response", clustersResponse);

			const deserializedData = clustersResponse.data.data.map(
				(item: SerializedCluster) => deattribute({ ...item })
			);
			// console.log("deserializedData", deserializedData);

			clusterList = [...deserializedData];

			// console.log("cluster list", clusterList);
			return deserializedData;
		} catch (e) {
			throw new Error(APIUtils.APIErrorResponseHandler(e));
		}
	}

	static fetch(clusterID: number): any {
		return clusterList.find((cluster: Cluster) => {
			return cluster.id === clusterID;
		});
	}

	static async create(
		cluster: Cluster
	): Promise<{ job: Job; cluster: Cluster }> {
		// console.log("cluster create", cluster);

		try {
			const data = serialise("clusters", cluster, "POST");
			// console.log("cluster request data", data);
			const response = await Axios.post(APIUtils.getUrl(ENDPOINT), data);

			// console.log("response", response);
			const createdCluster = deattribute(response.data.data) as Cluster;

			// this endpoint call creates a new job which is returned in the meta
			const job = response.data.meta.jobInfo as Job;

			return { job: { ...job }, cluster: { ...createdCluster } };
		} catch (e) {
			throw new Error(APIUtils.APIErrorResponseHandler(e));
		}
	}

	static async delete(cluster: Cluster, force: boolean = false): Promise<Job> {
		const response = await Axios.delete(
			`${APIUtils.getUrl(ENDPOINT)}/${cluster.id}?recursive=1${
				force ? "&force=1" : ""
			}`
		);

		const job = { ...response.data.meta.jobInfo } as Job;
		// we will add that job to the redux store so we can start monitoring it immediately
		// appStore.dispatch(jobCreated(job));

		return { ...job };
	}

	static async recover(clusterId: number): Promise<Job> {
		try {
			const response = await Axios.post(
				`${AppEnvironment.getApiAddress()}/${AppEnvironment.getApiVersion()}/tasks`,
				{
					data: {
						attributes: {
							data: {
								clusterID: clusterId
							},
							type: "cluster-restore"
						},
						type: "tasks"
					}
				}
			);

			const job = response.data.meta.jobInfo as Job;
			// appStore.dispatch(jobCreated(job));

			return job;
		} catch (e) {
			throw new Error(APIUtils.APIErrorResponseHandler(e));
		}
	}

	static async changeDBRootPassword(
		clusterId: number,
		dbRootPassword: string
	): Promise<Job> {
		try {
			const response = await Axios.post(
				`${AppEnvironment.getApiAddress()}/${AppEnvironment.getApiVersion()}/tasks`,
				{
					data: {
						attributes: {
							data: {
								clusterID: clusterId,
								password: dbRootPassword,
								onlyRecord: true,
								user: "root"
							},
							type: "db-password-change"
						},
						type: "tasks"
					}
				}
			);

			const job = response.data.meta.jobInfo as Job;
			// appStore.dispatch(jobCreated(job));

			return job;
		} catch (e) {
			throw new Error(APIUtils.APIErrorResponseHandler(e));
		}
	}
}

export default ClustersAPI;
