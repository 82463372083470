import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';

export const styles = (theme: Theme) =>
	createStyles({
		card: {
			width: "100%"
			// [theme.breakpoints.up("sm")]: {
			// 	width: 700
			// }
		},
		noPaddingCardContent: {
			padding: 0,
			"&:last-child": {
				padding: 0
			}
		},
	});
