import { ErrorOutline } from "@mui/icons-material";
import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	List,
	ListItem,
	ListItemText,
	Typography
} from "@mui/material";
import { withStyles, WithStyles, WithTheme } from "@mui/styles";
import { styles } from "components/connectionTestDialog/styles";
import { CONNECTION_TEST_STATUS } from "components/connectionTestDialog/types";
import ConnectionTestUtils from "components/connectionTestDialog/utils";
import PasswordField from "components/form/passwordField/passwordFieldComponent";
import { CheckCircle } from "mdi-material-ui";
import { HOST_SYSTEM } from "pages/management/host/types";
import { NODE_DB_ENGINE } from "pages/management/node/types";
import React, { ChangeEvent } from "react";
import ClustersAPI from "services/api/ClustersAPI";
import HostsAPI from "services/api/HostsAPI";
import JobsService from "services/jobs/jobsService";
import { Job, JOB_STATUS, JobTracking } from "services/jobs/types";

interface LocalState {
	isOpen: boolean;
	sshConnectionStatus: CONNECTION_TEST_STATUS;
	sshConnectionError?: string;
	dbConnectionStatus: CONNECTION_TEST_STATUS;
	dbConnectionError?: string;
	newDBRootPassword?: string;
	isDBAccessDenied: boolean;
	updateDBRootPasswordStatus: undefined | "updating" | "success" | "error";
	updateDBRootPasswordMessage?: string;
}

interface LocalProps {
	hostSystem: HOST_SYSTEM;
	dbEngine?: NODE_DB_ENGINE;
	privateKey: string;
	sshAddress?: string;
	sshPort?: string;
	testDBConnection: boolean;
	dbRootPassword?: string;
	onSSHStatusChange?: (status: CONNECTION_TEST_STATUS) => void;
	onDBStatusChange?: (status: CONNECTION_TEST_STATUS) => void;
	onDBRootPasswordUpdate: (rootPassword: string) => void;
	clusterID?: number;
}

type Props = LocalProps & WithStyles<typeof styles> & WithTheme;

class ConnectionTestComponent extends React.Component<Props, LocalState> {
	defaultState: Readonly<LocalState> = {
		isOpen: false,
		sshConnectionStatus: CONNECTION_TEST_STATUS.READY,
		dbConnectionStatus: CONNECTION_TEST_STATUS.READY,
		updateDBRootPasswordStatus: undefined,
		isDBAccessDenied: false
	};

	constructor(props: Props) {
		super(props);

		this.state = {
			...this.defaultState,
			newDBRootPassword: props.dbRootPassword
		};
	}

	closeDialog = async () => {
		this.setState({
			isOpen: false,
			isDBAccessDenied: false,
			updateDBRootPasswordStatus: undefined,
			updateDBRootPasswordMessage: undefined
		});
	};

	onTestClick = async () => {
		const {
			privateKey,
			testDBConnection,
			sshAddress,
			sshPort,
			hostSystem,
			dbEngine,
			onSSHStatusChange,
			onDBStatusChange,
			dbRootPassword,
			clusterID
		} = this.props;

		const { newDBRootPassword } = this.state;

		this.setState({
			sshConnectionStatus: CONNECTION_TEST_STATUS.RUNNING,
			sshConnectionError: undefined,
			dbConnectionStatus: CONNECTION_TEST_STATUS.RUNNING,
			dbConnectionError: undefined
		});

		try {
			const job = await HostsAPI.testSSHConnection(
				hostSystem,
				sshAddress || "",
				sshPort || "",
				privateKey,
				testDBConnection ? newDBRootPassword : undefined,
				testDBConnection ? dbEngine : undefined
			);

			try {
				// if access check job succeeds
				const successJob = await JobsService.trackJob(job);
				console.log("job success", successJob);

				this.setState({
					sshConnectionStatus: CONNECTION_TEST_STATUS.SUCCESS,
					sshConnectionError: undefined,
					isDBAccessDenied: false
				});
				onSSHStatusChange && onSSHStatusChange(CONNECTION_TEST_STATUS.SUCCESS);

				// check db result if necessary
				if (testDBConnection) {
					// if mysqladmin reported error
					if (successJob.status !== JOB_STATUS.SUCCESS) {
						// TODO
						const parsedMysqladminMessage = "TODO: fetch message from REST API";
						// ConnectionTestUtils.parseMysqladminMessage(
						// 	successJob.executionInfo.payload.mysqladmin_status
						// );

						this.setState({
							dbConnectionStatus: CONNECTION_TEST_STATUS.ERROR,
							dbConnectionError: parsedMysqladminMessage,
							isDBAccessDenied:
								parsedMysqladminMessage?.includes("Access denied")
						});
						onDBStatusChange && onDBStatusChange(CONNECTION_TEST_STATUS.ERROR);

						// if mysqladmin successfully connected
					} else {
						this.setState({
							dbConnectionStatus: CONNECTION_TEST_STATUS.SUCCESS,
							dbConnectionError: undefined,
							isDBAccessDenied: false
						});
						onDBStatusChange &&
							onDBStatusChange(CONNECTION_TEST_STATUS.SUCCESS);

						// if new db root password was used
						if (dbRootPassword !== newDBRootPassword) {
							console.log("dbRootPassword changed", newDBRootPassword);

							// update db root password on cluster
							this.updateDBRootPassword(clusterID, newDBRootPassword);
						}
					}
				}
			} catch (e: any) {
				// if job fails
				this.setState({
					sshConnectionStatus: CONNECTION_TEST_STATUS.ERROR,
					sshConnectionError: `${e.executionInfo.error?.msg} (${e.executionInfo.error?.wrapped.msg})`,
					dbConnectionStatus: CONNECTION_TEST_STATUS.READY,
					dbConnectionError: undefined
				});
				onSSHStatusChange && onSSHStatusChange(CONNECTION_TEST_STATUS.ERROR);
				onDBStatusChange && onDBStatusChange(CONNECTION_TEST_STATUS.READY);
			}
		} catch (e: any) {
			this.setState({
				sshConnectionStatus: CONNECTION_TEST_STATUS.ERROR,
				sshConnectionError: e.msg,
				dbConnectionStatus: CONNECTION_TEST_STATUS.READY,
				dbConnectionError: undefined
			});
			onSSHStatusChange && onSSHStatusChange(CONNECTION_TEST_STATUS.ERROR);
			onDBStatusChange && onDBStatusChange(CONNECTION_TEST_STATUS.READY);
		}
	};

	updateDBRootPassword = async (
		clusterID?: number,
		newDBRootPassword?: string
	) => {
		if (clusterID && newDBRootPassword) {
			try {
				this.setState({
					updateDBRootPasswordStatus: "updating",
					updateDBRootPasswordMessage:
						"Updating DB root password. Please wait..."
				});

				const job = await ClustersAPI.changeDBRootPassword(
					clusterID,
					newDBRootPassword
				);

				JobsService.trackJob(job)
					.then((job: JobTracking) => {
						console.log("job done", job);
						this.setState({
							updateDBRootPasswordStatus: "success",
							updateDBRootPasswordMessage: `DB root password successfully updated.`
						});

						this.props.onDBRootPasswordUpdate(newDBRootPassword);
					})
					.catch((job: Job) => {
						console.log("job failed", job);
						this.setState({
							updateDBRootPasswordStatus: "error",
							updateDBRootPasswordMessage: `Failed to update password. ${job.executionInfo.error?.msg}`
						});
					});
			} catch (error: any) {
				console.log("job start error", error);
				this.setState({
					updateDBRootPasswordStatus: "error",
					updateDBRootPasswordMessage: `Failed to update password. ${error.message}`
				});
			}
		} else {
			this.setState({
				updateDBRootPasswordStatus: "error",
				updateDBRootPasswordMessage:
					"Missing cluster ID or new password. This should not happen, report it to support."
			});
		}
	};

	render() {
		const {
			isOpen,
			sshConnectionStatus,
			sshConnectionError,
			dbConnectionStatus,
			dbConnectionError,
			newDBRootPassword,
			isDBAccessDenied,
			updateDBRootPasswordStatus,
			updateDBRootPasswordMessage
		} = this.state;
		const { theme, testDBConnection, dbRootPassword, sshAddress, sshPort } =
			this.props;

		const isAddressEntered = sshAddress !== "" && sshPort;
		const isDBRootPasswordEntered = dbRootPassword !== "";

		const isCloseButtonDisabled =
			sshConnectionStatus === CONNECTION_TEST_STATUS.RUNNING ||
			(testDBConnection &&
				dbConnectionStatus === CONNECTION_TEST_STATUS.RUNNING);

		const isRunButtonDisabled =
			!isAddressEntered ||
			sshConnectionStatus === CONNECTION_TEST_STATUS.RUNNING ||
			(testDBConnection &&
				(dbConnectionStatus === CONNECTION_TEST_STATUS.RUNNING ||
					!isDBRootPasswordEntered));

		const sshConnectionMsg = isAddressEntered
			? ConnectionTestUtils.getStatusMessage(sshConnectionStatus)
			: "Please enter node's SSH address";

		const dbConnectionMsg = isAddressEntered
			? isDBRootPasswordEntered
				? ConnectionTestUtils.getStatusMessage(dbConnectionStatus)
				: "Please enter DB root password"
			: "Please enter node's SSH address";

		const dialogRender = () => (
			<>
				{theme.breakpoints.up("sm") ? (
					// desktop
					<Dialog
						open={isOpen}
						onClose={() => {
							this.setState({ isOpen: false });
						}}
						fullWidth={true}
						maxWidth={"sm"}
						aria-labelledby="form-dialog-title"
					>
						{dialogContentRender()}
					</Dialog>
				) : (
					// mobile
					<Dialog
						open={isOpen}
						onClose={() => {
							this.setState({ isOpen: false });
						}}
						fullWidth={true}
						fullScreen={true}
						aria-labelledby="form-dialog-title"
					>
						{dialogContentRender()}
					</Dialog>
				)}
			</>
		);

		const dialogContentRender = () => (
			<>
				<DialogContent>
					<DialogTitle>Access check</DialogTitle>
					<Grid container direction="column" spacing={4}>
						<Grid container item direction="column">
							<List>
								<ListItem>
									{isAddressEntered || sshConnectionError
										? ConnectionTestUtils.renderStatusIcon(
												sshConnectionStatus,
												theme
										  )
										: ConnectionTestUtils.renderNotReadyIcon()}
									<ListItemText
										primary="SSH connection status"
										secondary={sshConnectionError || sshConnectionMsg}
										secondaryTypographyProps={{
											color: sshConnectionError ? "error" : "textSecondary"
										}}
									/>
								</ListItem>
								{testDBConnection && (
									<ListItem>
										{isAddressEntered || sshConnectionError
											? isDBRootPasswordEntered || dbConnectionError
												? ConnectionTestUtils.renderStatusIcon(
														dbConnectionStatus,
														theme
												  )
												: ConnectionTestUtils.renderNotReadyIcon()
											: ConnectionTestUtils.renderNotReadyIcon()}
										<ListItemText
											primary="Local DB connection status"
											secondary={dbConnectionError || dbConnectionMsg}
											secondaryTypographyProps={{
												color: dbConnectionError ? "error" : "textSecondary"
											}}
										/>
									</ListItem>
								)}
							</List>
							{isDBAccessDenied && (
								<Grid item xs={10} style={{ marginLeft: theme.spacing(12) }}>
									<Typography variant="body2">
										Could not connect to DB. Please check DB root password and
										test access again.
									</Typography>
									<PasswordField
										disabled={
											dbConnectionStatus === CONNECTION_TEST_STATUS.RUNNING
										}
										required={true}
										value={newDBRootPassword}
										label="DB Root Password"
										onChange={(e: ChangeEvent) => {
											const target = e.target as HTMLFormElement;

											this.setState({
												newDBRootPassword: target.value as string
											});
										}}
									/>
									<Typography variant="body2">
										If newly entered password is correct, it will be updated on
										the cluster.
									</Typography>
								</Grid>
							)}
							{updateDBRootPasswordStatus && (
								<Grid
									item
									container
									xs={10}
									direction="row"
									style={{ marginLeft: theme.spacing(12) }}
								>
									{updateDBRootPasswordStatus === "updating" && (
										<CircularProgress size={16} />
									)}
									{updateDBRootPasswordStatus === "error" && (
										<ErrorOutline color="error" />
									)}
									{updateDBRootPasswordStatus === "success" && (
										<CheckCircle
											style={{ color: theme.palette.success.main }}
										/>
									)}
									<Typography
										variant="body2"
										style={{ marginLeft: theme.spacing(1) }}
									>
										{updateDBRootPasswordMessage}
									</Typography>
								</Grid>
							)}
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={(): void => {
							this.closeDialog();
						}}
						disabled={isCloseButtonDisabled}
					>
						Close
					</Button>

					<Button
						data-testid="test-connection-button"
						onClick={this.onTestClick}
						color="primary"
						variant="outlined"
						disabled={isRunButtonDisabled}
					>
						Check access
					</Button>
				</DialogActions>
			</>
		);

		return (
			<>
				<Button
					size="small"
					variant="outlined"
					style={{ marginLeft: theme.spacing(1) }}
					color="primary"
					onClick={(): void => {
						this.setState({ isOpen: true });
						isAddressEntered && this.onTestClick();
					}}
				>
					Check access
				</Button>
				{dialogRender()}
			</>
		);
	}
}

export default withStyles(styles, { withTheme: true })(ConnectionTestComponent);
