import { CircularProgress, Grid, Typography } from "@mui/material";

const PreloaderComponentHtml = () => {
	return (
		<Grid
			container
			style={{ height: "100%" }}
			direction="column"
			justifyContent="center"
			alignItems="center"
		>
			<Grid item>
				<Grid
					container
					direction="row"
					justifyContent="center"
					alignItems="center"
					spacing={2}
				>
					<Grid item>
						<CircularProgress color="secondary" />
					</Grid>
					<Grid item>
						<Typography variant="h5">Loading...</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default PreloaderComponentHtml;
