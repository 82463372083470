import axios from "axios";
import { User } from "pages/users/types";
import jwt from "jsonwebtoken";
import { deattribute, serialise } from "kitsu-core";
import APIUtils from "services/api/utils";
import AppEnvironment from "services/appEnvironment";
import { Credentials, JwtData } from "store/auth/types";
import { Config } from "services/config/Config";

const ENDPOINT = "login";

export default class AuthAPI {
	static async login(credentials: Credentials): Promise<User> {
		try {
			const data = serialise("login", credentials, "POST");
			const response = await axios.post(APIUtils.getUrl(ENDPOINT), data);
			return deattribute({ ...response.data.data }) as User;
		} catch (e) {
			throw new Error(APIUtils.APIErrorResponseHandler(e));
		}
	}

	static async logout() {
		try {
			await axios.post(APIUtils.getUrl("logout"));
		} catch (e) {
			throw new Error(APIUtils.APIErrorResponseHandler(e));
		}
	}

	static async refreshWsAccessToken(): Promise<string> {
		try {
			const response = await axios.get(
				`${AppEnvironment.getApiAddress()}/${AppEnvironment.getApiVersion()}/jwt/access`
			);

			AuthAPI.verifyJwtToken(response.data.access.value);
			return response.data.access.value;
		} catch (e: any) {
			throw new Error(APIUtils.APIErrorResponseHandler(e));
		}
	}

	static verifyJwtToken(token: string): JwtData {
		return jwt.verify(token, Config.jtw_public_token) as JwtData;
	}
}
