import React from "react";
// MUI
import {
	Divider,
	Grid,
	Icon,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Skeleton,
	TablePagination,
	Typography
} from "@mui/material";
import { DoneAll as DoneAllIcon } from "@mui/icons-material";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { styles } from "pages/management/node/jobs/styles";

import axios, { AxiosError, CancelTokenSource } from "axios";
import { Cluster } from "pages/management/cluster/types";
import { Host } from "pages/management/host/types";
import { Node } from "pages/management/node/types";
import JobsApi from "services/api/JobsApi";
import { Job } from "services/jobs/types";
import JobComponent from "pages/management/node/jobs/jobComponent/JobComponent";

// redux
import { connect } from "react-redux";
import { AppState } from "AppState";

import { runningJobsCountSelector } from "store/jobsMonitor/selectors";

interface LocalState {
	pageSize: number;
	currentPage: number;
	totalCount: number;
	jobs: Job[];
	isLoading: boolean;
	showSkeleton: boolean;
	errorMessage?: string;
	jobsAreRunning: boolean;
}

// pass node and/or host for which you want this component to display jobs
interface LocalProps {
	cluster?: Cluster;
	node?: Node;
	host?: Host;
}

interface ReduxStateProps {
	hasRunningJobs?: boolean;
	runningJobsCount?: number;
}

type Props = LocalProps & ReduxStateProps & WithStyles<typeof styles>;

class JobListComponent extends React.Component<Props, LocalState> {
	isComponentMounted: boolean = false;
	cancelTokenSource?: CancelTokenSource;

	constructor(props: Props) {
		super(props);

		this.state = {
			pageSize: 10,
			currentPage: 1,
			totalCount: 0,
			jobs: [],
			isLoading: true,
			showSkeleton: true,
			jobsAreRunning: false
		};

		this.loadJobs();
	}

	componentDidMount() {
		this.isComponentMounted = true;
	}

	componentWillUnmount() {
		this.isComponentMounted = false;
	}

	componentDidUpdate(
		prevProps: Readonly<Props>,
		prevState: Readonly<LocalState>,
		snapshot?: any
	) {
		if (
			prevProps.cluster !== this.props.cluster ||
			prevProps.host !== this.props.host ||
			prevProps.node !== this.props.node
		) {
			this.loadJobs(1);
			this.setState({ currentPage: 1 });
		}

		if (
			this.props.runningJobsCount !== prevProps.runningJobsCount &&
			this.state.currentPage === 1
		) {
			this.loadJobs(1);
		}
	}

	loadJobs = (newCurrentPage?: number, newPageSize?: number) => {
		const { cluster, host } = this.props;
		const { currentPage, pageSize } = this.state;

		if (this.cancelTokenSource) {
			console.log("another request in progress. cancelling...");
			this.cancelTokenSource.cancel("cancelled");
		}
		this.cancelTokenSource = axios.CancelToken.source();

		this.isComponentMounted && this.setState({ isLoading: true });

		setTimeout(() => {
			if (this.state.isLoading) {
				this.setState({ showSkeleton: true });
			}
		}, 500);

		if (cluster?.id || host?.clusterID) {
			JobsApi.fetchPaginatedList(
				newCurrentPage || currentPage,
				newPageSize || pageSize,
				host?.clusterID || cluster?.id || -1,
				host?.id,
				this.cancelTokenSource.token
			)
				.then(({ list, totalCount }) => {
					delete this.cancelTokenSource;

					this.setState({
						jobs: list,
						totalCount,
						isLoading: false,
						showSkeleton: false,
						errorMessage: undefined
					});
				})
				.catch((error: AxiosError) => {
					console.log("error", error, error.message);
					if (error.message !== "cancelled") {
						this.setState({
							isLoading: false,
							showSkeleton: false,
							errorMessage: error.message
						});
					}
				});
		}
	};

	render():
		| React.ReactElement<any, string | React.JSXElementConstructor<any>>
		| string
		| number
		| {}
		| React.ReactNodeArray
		| React.ReactPortal
		| boolean
		| null
		| undefined {
		const { jobs, currentPage, pageSize, showSkeleton, totalCount } =
			this.state;

		const handleChangePage = (
			event: React.MouseEvent<HTMLButtonElement> | null,
			newPage: number
		) => {
			this.setState({ currentPage: newPage + 1 });
			this.loadJobs(newPage + 1);
		};

		const handleChangeRowsPerPage = (
			event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
		) => {
			const pageSize = parseInt(event.target.value, 10);
			this.setState({
				currentPage: 1,
				pageSize
			});
			this.loadJobs(1, pageSize);
		};

		const tablePagination = (
			<TablePagination
				component="div"
				count={totalCount}
				page={currentPage - 1}
				onPageChange={handleChangePage}
				rowsPerPage={pageSize}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		);

		return (
			<>
				{tablePagination}
				{showSkeleton ? (
					<List>
						{jobs.map((job: Job) => (
							<ListItem button key={job.id}>
								<ListItemAvatar>
									<Skeleton variant="circular" height="30px" width="30px" />
								</ListItemAvatar>
								<ListItemText primary={<Skeleton />} secondary={<Skeleton />} />
							</ListItem>
						))}
						{jobs.length === 0 && (
							<ListItem button key="no-jobs">
								<ListItemAvatar>
									<Skeleton variant="circular" height="30px" width="30px" />
								</ListItemAvatar>
								<ListItemText primary={<Skeleton />} secondary={<Skeleton />} />
							</ListItem>
						)}
					</List>
				) : jobs.length > 0 ? (
					<List>
						<Divider />
						{jobs.map((job: Job) => (
							<JobComponent job={job} key={job.id} />
						))}
					</List>
				) : (
					<>
						<Grid container direction="row" spacing={2}>
							<Grid item>
								<Icon>
									<DoneAllIcon />
								</Icon>
							</Grid>
							<Grid item>
								<Typography variant="subtitle2">No jobs</Typography>
							</Grid>
						</Grid>
					</>
				)}
				{tablePagination}
			</>
		);
	}
}

const mapStateToProps = (state: AppState, props: LocalProps) => {
	return {
		runningJobsCount: runningJobsCountSelector(
			state,
			props.cluster?.id,
			props.node?.id,
			props.host?.id
		)
	};
};
export default withStyles(styles, { withTheme: true })(
	connect(mapStateToProps, undefined)(JobListComponent)
);
