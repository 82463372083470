import { CLUSTER_TYPE } from "pages/management/cluster/clusterCreateWizard/types";
import { Cluster } from "pages/management/cluster/types";
import { HOST_TYPE } from "pages/management/host/types";

export default class ClusterUtils {
	static getClusterType(cluster: Cluster): CLUSTER_TYPE {
		if (cluster.unmanaged) {
			return CLUSTER_TYPE.MONITORED;
		} else if (
			!cluster.unmanaged &&
			cluster.sharedConfig.host.type === HOST_TYPE.UNMANAGED
		) {
			return CLUSTER_TYPE.HYBRID;
		} else if (
			!cluster.unmanaged &&
			cluster.sharedConfig.host.type !== HOST_TYPE.UNMANAGED
		) {
			return CLUSTER_TYPE.MANAGED;
		} else {
			throw new Error("Unknown cluster type.");
		}
	}
}
