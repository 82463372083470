import { Job, JobTracking } from "services/jobs/types";
import {
	AddRunningJob,
	BootDone,
	JOB_MONITOR_ACTION,
	RemoveRunningJob,
	RemoveTrackedJob,
	SetRunningJobs,
	SetTrackedJob,
	ConsolidateTrackedJobs,
	TrackJob,
	UntrackJob
} from "store/jobsMonitor/types";

export const bootDone = (): BootDone => ({
	type: JOB_MONITOR_ACTION.BOOT_DONE
});

export const setRunningJobs = (jobs: JobTracking[]): SetRunningJobs => ({
	type: JOB_MONITOR_ACTION.SET_RUNNING_JOBS,
	payload: {
		jobs
	}
});

export const addRunningJob = (job: JobTracking): AddRunningJob => ({
	type: JOB_MONITOR_ACTION.ADD_RUNNING_JOB,
	payload: {
		job
	}
});

export const removeRunningJob = (jobId: number): RemoveRunningJob => ({
	type: JOB_MONITOR_ACTION.REMOVE_RUNNING_JOB,
	payload: {
		jobId
	}
});

export const trackJob = (jobId: number): TrackJob => ({
	type: JOB_MONITOR_ACTION.TRACK,
	payload: {
		jobId
	}
});

export const untrackJob = (jobId: number): UntrackJob => ({
	type: JOB_MONITOR_ACTION.UNTRACK,
	payload: {
		jobId
	}
});

export const consolidateTrackedJobs = (jobs: Job[]): ConsolidateTrackedJobs => ({
	type: JOB_MONITOR_ACTION.CONSOLIDATE_TRACKED_JOBS,
	payload: {
		jobs
	}
});

export const setTrackedJob = (job: JobTracking): SetTrackedJob => ({
	type: JOB_MONITOR_ACTION.SET_TRACKED_JOB,
	payload: {
		job
	}
});

export const removeTrackedJob = (jobId: number): RemoveTrackedJob => ({
	type: JOB_MONITOR_ACTION.REMOVE_TRACKED_JOB,
	payload: {
		jobId
	}
});
