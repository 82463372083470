import {
	CONNECTION_STATUS,
	SYSTEM_STATUS_ACTION,
	SystemStatusAction,
	SystemStatusState
} from "store/systemStatus/types";

const initialState: SystemStatusState = {
	backendConnectionStatus: CONNECTION_STATUS.ONLINE,
	influxDBConnectionStatus: CONNECTION_STATUS.ONLINE,
	wsConnectionStatus: CONNECTION_STATUS.OFFLINE
};

export function systemStatusReducer(
	state: SystemStatusState = initialState,
	action: SystemStatusAction
): SystemStatusState {
	switch (action.type) {
		case SYSTEM_STATUS_ACTION.BACKEND_SET_ONLINE:
			return {
				...state,
				backendConnectionStatus: CONNECTION_STATUS.ONLINE
			};

		case SYSTEM_STATUS_ACTION.BACKEND_SET_WEAK:
			return {
				...state,
				backendConnectionStatus: CONNECTION_STATUS.WEAK
			};

		case SYSTEM_STATUS_ACTION.BACKEND_SET_OFFLINE:
			return {
				...state,
				backendConnectionStatus: CONNECTION_STATUS.OFFLINE
			};

		case SYSTEM_STATUS_ACTION.INFLUXDB_SET_ONLINE:
			return {
				...state,
				influxDBConnectionStatus: CONNECTION_STATUS.ONLINE
			};

		case SYSTEM_STATUS_ACTION.INFLUXDB_SET_WEAK:
			return {
				...state,
				influxDBConnectionStatus: CONNECTION_STATUS.WEAK
			};

		case SYSTEM_STATUS_ACTION.INFLUXDB_SET_OFFLINE:
			return {
				...state,
				influxDBConnectionStatus: CONNECTION_STATUS.OFFLINE
			};

		case SYSTEM_STATUS_ACTION.WS_SET_ONLINE:
			return {
				...state,
				wsConnectionStatus: CONNECTION_STATUS.ONLINE
			}

		case SYSTEM_STATUS_ACTION.WS_SET_OFFLINE:
			return {
				...state,
				wsConnectionStatus: CONNECTION_STATUS.OFFLINE
			}

		default:
			return state;
	}
}
