import { WsrepLocalStateMetric } from "store/metricsStore/types";
import { WSREP_MEMBER_STATE } from "pages/management/cluster/types";

export default class MetricsUtils {
	static toRecord(localStateMetrics: WsrepLocalStateMetric[]) {
		let newLocalStateMetricRecords: Record<string, WsrepLocalStateMetric> = {};

		localStateMetrics.forEach((metric: WsrepLocalStateMetric) => {
			// newLocalStateMetricRecords[`${metric.cluster}`] = metric;
			newLocalStateMetricRecords[`${metric.cluster},${metric.node}`] = metric;
		});

		return newLocalStateMetricRecords;
	}

	static countJoinedNodes(
		localStateMetrics: WsrepLocalStateMetric[]
	): Record<string, number> {
		// console.log("countJoinedNodes", localStateMetrics);
		let joinedNodesPerClusterRecord: Record<string, number> = {};

		localStateMetrics.forEach((metric: WsrepLocalStateMetric) => {
			if (
				metric.value === WSREP_MEMBER_STATE.DONOR ||
				metric.value === WSREP_MEMBER_STATE.JOINED ||
				metric.value === WSREP_MEMBER_STATE.SYNCED
			) {
				joinedNodesPerClusterRecord[metric.cluster] =
					joinedNodesPerClusterRecord[metric.cluster]
						? joinedNodesPerClusterRecord[metric.cluster] + 1
						: 1;
			}
		});

		return joinedNodesPerClusterRecord;
	}
}
