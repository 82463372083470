import { Theme } from "@mui/material";

import createStyles from "@mui/styles/createStyles";

export const styles = (theme: Theme) =>
	createStyles({
		pulsating: {
			animation: "$pulse 1.2s infinite ease-in-out"
		},
		"@keyframes pulse": {
			"0%": {
				opacity: 1
			},
			"50%": {
				opacity: 0.5
			},
			"100%": {
				opacity: 1
			}
		}
	});
