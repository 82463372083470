import {
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select
} from "@mui/material";
import { IRegion } from "dots-wrapper/dist/region";
import React, { FormEvent, useState } from "react";

interface LocalProps {
	options: IRegion[];
	value: string;
	readOnly?: boolean;
	onChange?: (selectedRegion: string) => void;
	helperMsg?: string;
}

function RegionSelectComponent(props: LocalProps) {
	const { readOnly, options, value, onChange } = props;

	const [helperMsg, setHelperMsg] = useState("");
	const [isError, setIsError] = useState(false);

	return (
		<FormControl
			style={{ marginTop: "16px" }}
			required
			fullWidth={true}
			margin="dense"
			onInvalid={(e: FormEvent): void => {
				e.preventDefault();
				const form = e.target as HTMLFormElement;

				setIsError(true);
				setHelperMsg(form.validationMessage);
			}}
			error={isError}
		>
			<InputLabel htmlFor="digital-ocean-region">Region</InputLabel>
			<Select
				data-testid="digital-ocean-region-select-container"
				fullWidth={true}
				displayEmpty={true}
				error={isError}
				required
				label="Region"
				disabled={!options || readOnly}
				value={value}
				renderValue={(value: string) => value}
				onChange={(e) => {
					const regionSlug = e.target.value as string;

					setIsError(false);
					setHelperMsg("");

					onChange && onChange(regionSlug);
				}}
				inputProps={{
					id: "digital-ocean-region",
					"data-testid": "digital-ocean-region-select"
				}}
			>
				{options && options.length > 0 ? (
					options.map((region: IRegion) => (
						<MenuItem
							disabled={!region.available}
							key={region.slug}
							value={region.slug}
						>
							{region.name} {(!region.available && " (Unavailable)") || ""}
						</MenuItem>
					))
				) : (
					<MenuItem key={value} value={value}>
						{value}
					</MenuItem>
				)}
			</Select>
			{!readOnly && (
				<FormHelperText>{props.helperMsg || helperMsg}</FormHelperText>
			)}
		</FormControl>
	);
}

export default RegionSelectComponent;
