import {
	FormControl,
	FormHelperText,
	InputLabel,
	ListSubheader,
	MenuItem,
	Select
} from "@mui/material";
import { SizeOptionComponent } from "components/form/digitalOceanConfig/sizeSelect/sizeOption/SizeOptionComponent";
import { ISizeExt } from "components/form/digitalOceanConfig/types";
import React, { FormEvent, useState } from "react";

interface LocalProps {
	options: Map<string, ISizeExt[]>;
	availableOptions?: string[];
	value: string;
	readOnly?: boolean;
	onChange?: (selectedSizeSlug: string) => void;
	helperMsg?: string;
}

function SizeSelectComponent(props: LocalProps) {
	const { readOnly, options, value, onChange, availableOptions } = props;

	const [helperMsg, setHelperMsg] = useState("");
	const [isError, setIsError] = useState(false);

	return (
		<FormControl
			required
			fullWidth={true}
			margin="dense"
			onInvalid={(e: FormEvent): void => {
				e.preventDefault();
				const form = e.target as HTMLFormElement;

				setIsError(true);
				setHelperMsg(form.validationMessage);
			}}
			error={isError}
		>
			<InputLabel htmlFor="digital-ocean-size">Droplet size</InputLabel>
			<Select
				data-testid="digital-ocean-size-select-container"
				fullWidth={true}
				error={isError}
				required
				label="Droplet size"
				renderValue={(value: string) => value}
				disabled={!options || readOnly}
				value={value}
				onChange={(e) => {
					const sizeSlug = e.target.value as string;

					setIsError(false);
					setHelperMsg("");

					onChange && onChange(sizeSlug);
				}}
				inputProps={{
					id: "digital-ocean-size",
					"data-testid": "digital-ocean-size-select"
				}}
			>
				{options && options.size > 0 ? (
					Array.from(options).map((value: [string, ISizeExt[]]) => {
						const categoryName = value[0];
						const sizes = value[1];

						return [
							<ListSubheader>{categoryName.toUpperCase()}</ListSubheader>,
							sizes.map((size: ISizeExt) => (
								<MenuItem
									style={{ marginLeft: 10 }}
									disabled={
										availableOptions && !availableOptions?.includes(size.slug)
									}
									key={size.slug}
									value={size.slug}
								>
									<SizeOptionComponent
										size={size}
										disabled={
											!!availableOptions &&
											!availableOptions?.includes(size.slug)
										}
									/>
								</MenuItem>
							))
						];
					})
				) : (
					<MenuItem key={value} value={value}>
						{value}
					</MenuItem>
				)}
			</Select>
			{!readOnly && (
				<FormHelperText>{props.helperMsg || helperMsg}</FormHelperText>
			)}
		</FormControl>
	);
}

export default SizeSelectComponent;
