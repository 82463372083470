// @flow
import { CircularProgress, InputAdornment, Tooltip } from "@mui/material";

interface LocalProps {
	tooltipTitle?: string;
}

export function LoadingAdornment(props: LocalProps) {
	return (
		<div>
			<InputAdornment data-testid='input-adornment-loading' position="end">
				<Tooltip title={props.tooltipTitle ? props.tooltipTitle : 'Loading'}>
					<CircularProgress size={16} />
				</Tooltip>
			</InputAdornment>
		</div>
	);
};