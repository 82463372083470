import { Theme } from "@mui/material";

import createStyles from "@mui/styles/createStyles";

export const styles = (theme: Theme) =>
	createStyles({
		root: {
			width: "100%",
			[theme.breakpoints.up("sm")]: {
				width: 340
			},
			backgroundColor: theme.palette.background.paper
		}
	});
