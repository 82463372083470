import { NODE_DB_ENGINE, NodeSharedConfig } from "pages/management/node/types";

export const DEFAULT_NODE_SHARED_CONFIG: Readonly<NodeSharedConfig> = {
	dbEngine: NODE_DB_ENGINE.MARIADB_10_7,
	settings: {
		logs: {
			generalLog: {
				enabled: false
			}
		}
	}
};
