import { ThemeProvider } from "@mui/material/styles";
import { rootReducer } from "AppState";
import ClusterSideEffects from "store/cluster/sideEffects";
import HostSideEffects from "store/host/sideEffects";
import NodeSideEffects from "store/node/sideEffects";
import GMRouter from "Router";
import GMDialog from "components/dialog/DialogComponent";
import SnackbarSideEffects from "components/snackbar/sideEffects";
import GMSnackbar from "components/snackbar/SnackbarComponent";
import UserSideEffects from "pages/users/sideEffects";
import AuthSideEffects from "store/auth/sideEffects";
import DashboardSideEffects from "store/dashboard/sideEffects";
import { initializeInterceptors } from "services/Interceptors";
import JobSideEffects from "store/jobsMonitor/sideEffects";
import MetricsStoreSideEffects from "store/metricsStore/sideEffects";
import PreloaderSideEffects from "store/preloader/sideEffects";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { applyMiddleware, compose, createStore, Store } from "redux";
import createSagaMiddleware from "redux-saga";
import { theme } from "theme";
import * as serviceWorker from "./serviceWorker";

const sagaMiddleware = createSagaMiddleware();

export const appStore: Store = createStore(
	rootReducer,
	compose(
		applyMiddleware(sagaMiddleware),
		((window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
			(window as any).__REDUX_DEVTOOLS_EXTENSION__({ serialize: true })) ||
			compose
	)
);

sagaMiddleware.run(SnackbarSideEffects);
sagaMiddleware.run(UserSideEffects);
sagaMiddleware.run(ClusterSideEffects);
sagaMiddleware.run(NodeSideEffects);
sagaMiddleware.run(HostSideEffects);
sagaMiddleware.run(JobSideEffects);
sagaMiddleware.run(PreloaderSideEffects);
sagaMiddleware.run(MetricsStoreSideEffects);
sagaMiddleware.run(AuthSideEffects);
sagaMiddleware.run(DashboardSideEffects);

initializeInterceptors(appStore);

ReactDOM.render(
	<Provider store={appStore}>
		<ThemeProvider theme={theme}>
			<GMRouter />
			<GMSnackbar />
			<GMDialog />
		</ThemeProvider>
	</Provider>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

if (process.env.NODE_ENV !== "development") {
	console.log = () => {};
}
