import Axios, { AxiosResponse } from "axios";
import { Config } from "services/config/Config";

class MetricsApi {
	static async fetch(query: string): Promise<AxiosResponse> {
		const {
			influx_host: host,
			influx_port: port,
			influx_password: password,
			influx_protocol: protocol,
			influx_username: username,
			influx_db: db
		} = Config;

		return await Axios.get(`${protocol}://${host}:${port}/query`, {
			auth: {
				username,
				password
			},
			params: {
				q: query,
				db
			}
		});
	}
}

export default MetricsApi;
