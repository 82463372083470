import { HOST_SYSTEM, HOST_TYPE } from "pages/management/host/types";
import { NODE_DB_ENGINE } from "pages/management/node/types";
import { Config } from "services/config/Config";
import { SupportMatrixSystem } from "./types";

export default class SupportMatrix {
	/**
	 * @desc Returns support matrix for given cloud provider
	 * @param {HOST_TYPE} cloudProvider
	 * @returns {any}
	 */
	static getSupportMatrix = (
		cloudProvider?: HOST_TYPE
	): SupportMatrixSystem[] => {
		switch (cloudProvider) {
			case HOST_TYPE.EC2:
				return Config.support_matrix_ec2;
			case HOST_TYPE.DIGITAL_OCEAN:
				return Config.support_matrix_digitalocean;
			default:
				return Config.support_matrix;
		}
	};

	/**
	 * Returns list of supported DBs
	 * @param {HOST_SYSTEM} system
	 * @param cloudProvider
	 * @returns {NODE_DB_ENGINE[]}
	 */
	static getSupportedDbEngines = (
		system?: HOST_SYSTEM,
		cloudProvider?: HOST_TYPE
	): NODE_DB_ENGINE[] => {
		const support_matrix = SupportMatrix.getSupportMatrix(cloudProvider);

		if (!support_matrix) {
			return [];
		} else {
			// get all supported and non-deprecated db engines
			let allDbEngines: NODE_DB_ENGINE[] = [];

			support_matrix.forEach((supportMatrixSystem) => {
				if (system) {
					if (system === supportMatrixSystem.id) {
						const dbEngines = supportMatrixSystem.dbEngines
							// .filter(
							// 	(supportMatrixDbEngine) => !supportMatrixDbEngine.deprecated
							// )
							.map((supportMatrixDbEngine) => supportMatrixDbEngine.id);
						allDbEngines = [...allDbEngines, ...dbEngines];
					}
				} else {
					const dbEngines = supportMatrixSystem.dbEngines
						// .filter(
						// 	(supportMatrixDbEngine) => !supportMatrixDbEngine.deprecated
						// )
						.map((supportMatrixDbEngine) => supportMatrixDbEngine.id);
					allDbEngines = [...allDbEngines, ...dbEngines];
				}
			});

			allDbEngines = [...new Set(allDbEngines)];

			return allDbEngines;
		}
	};

	/**
	 * Returns list of supported systems
	 * @param {NODE_DB_ENGINE} dbEngine
	 * @param cloudProvider
	 * @returns {HOST_SYSTEM[]}
	 */
	static getSupportedHostSystems = (
		dbEngine?: NODE_DB_ENGINE,
		cloudProvider?: HOST_TYPE
	): HOST_SYSTEM[] => {
		const support_matrix = SupportMatrix.getSupportMatrix(cloudProvider);

		if (!support_matrix) {
			return [];
		} else {
			// if no db engine, get all supported and non-deprecated systems
			if (!dbEngine) {
				return (
					support_matrix
						// .filter((supportMatrixSystem) => !supportMatrixSystem.deprecated)
						.map((supportMatrixSystem) => supportMatrixSystem.id)
				);
			} else {
				return (
					support_matrix
						// .filter((supportMatrixSystem) => !supportMatrixSystem.deprecated)
						.filter((supportMatrixSystem) =>
							supportMatrixSystem.dbEngines
								.map((supportMatrixDbEngine) => supportMatrixDbEngine.id)
								.includes(dbEngine)
						)
						.map((supportMatrixSystem) => supportMatrixSystem.id)
				);
			}
		}
	};

	/**
	 * Checks if system is supported by Galera Manager
	 * @param {string} hostSystem
	 * @param cloudProvider
	 * @returns {boolean}
	 */
	static isSystemSupported(
		hostSystem: HOST_SYSTEM,
		cloudProvider?: HOST_TYPE
	): boolean {
		return (
			SupportMatrix.getSupportMatrix(cloudProvider)
				// .filter((supportMatrixSystem) => !supportMatrixSystem.deprecated)
				.some((supportMatrixSystem) => supportMatrixSystem.id === hostSystem)
		);
	}

	/**
	 * Checks if db engine is supported by Galera Manager
	 * @param {string} dbEngine
	 * @param cloudProvider
	 * @returns {boolean}
	 */
	static isDbSupported(
		dbEngine: NODE_DB_ENGINE,
		cloudProvider?: HOST_TYPE
	): boolean {
		const supportMatrix = SupportMatrix.getSupportMatrix(cloudProvider);

		let isSupported = false;

		supportMatrix
			// .filter((supportMatrixSystem) => !supportMatrixSystem.deprecated)
			.forEach((supportMatrixSystem) => {
				supportMatrixSystem.dbEngines.forEach((supportMatrixDbEngine) => {
					if (
						// !supportMatrixDbEngine.deprecated &&
						supportMatrixDbEngine.id === dbEngine
					)
						isSupported = true;
				});
			});

		return isSupported;
	}

	/**
	 * Checks if system is supported by db engine
	 * @param {string} hostSystem
	 * @param {string} dbEngine
	 * @param cloudProvider
	 * @returns {boolean}
	 */
	static isSystemSupportedByDb(
		hostSystem: HOST_SYSTEM,
		dbEngine: NODE_DB_ENGINE,
		cloudProvider?: HOST_TYPE
	): boolean {
		const supportMatrix = SupportMatrix.getSupportMatrix(cloudProvider);

		const supportMatrixSystem = supportMatrix
			// .filter((supportMatrixSystem) => !supportMatrixSystem.deprecated)
			.find((supportMatrixSystem) => supportMatrixSystem.id === hostSystem);

		return supportMatrixSystem
			? supportMatrixSystem.dbEngines
					// .filter((supportMatrixDbEngine) => !supportMatrixDbEngine.deprecated)
					.some(
						(supportMatrixDbEngine) => supportMatrixDbEngine.id === dbEngine
					)
			: false;
	}

	/**
	 * Checks if db is supported on system
	 * @param {string} dbEngine
	 * @param {string} hostSystem
	 * @param cloudProvider
	 * @returns {boolean}
	 */
	static isDbSupportedOnSystem(
		dbEngine: NODE_DB_ENGINE,
		hostSystem: HOST_SYSTEM,
		cloudProvider?: HOST_TYPE
	): boolean {
		const supportMatrix = SupportMatrix.getSupportMatrix(cloudProvider);

		return supportMatrix
			? supportMatrix
					// .filter((supportMatrixSystem) => !supportMatrixSystem.deprecated)
					.find((supportMatrixSystem) => supportMatrixSystem.id === hostSystem)
					?.dbEngines // .filter(
					// 	(supportMatrixDbEngine) => !supportMatrixDbEngine.deprecated
					// )
					.some(
						(supportMatrixDbEngine) => supportMatrixDbEngine.id === dbEngine
					) || false
			: false;
	}

	/**
	 * Checks if system is marked as deprecated in support matrix
	 * @param hostSystem
	 * @param cloudProvider
	 */
	static isSystemDeprecated(
		hostSystem: HOST_SYSTEM,
		cloudProvider?: HOST_TYPE
	) {
		const supportMatrix = SupportMatrix.getSupportMatrix(cloudProvider);

		const supportMatrixSystem = supportMatrix
			// .filter((supportMatrixSystem) => !supportMatrixSystem.deprecated)
			.find((supportMatrixSystem) => supportMatrixSystem.id === hostSystem);

		return supportMatrixSystem ? supportMatrixSystem.deprecated : false;
	}

	/**
	 * Checks is db engine is marked as deprecated in support matrix
	 * @param dbEngine
	 * @param hostSystem
	 * @param cloudProvider
	 */
	static isDbDeprecated(
		dbEngine: NODE_DB_ENGINE,
		hostSystem: HOST_SYSTEM,
		cloudProvider?: HOST_TYPE
	): boolean {
		const supportMatrix = SupportMatrix.getSupportMatrix(cloudProvider);

		const deprecated =
			supportMatrix &&
			supportMatrix
				.find((supportMatrixSystem) => supportMatrixSystem.id === hostSystem)
				?.dbEngines.find(
					(supportMatrixDbEngine) => supportMatrixDbEngine.id === dbEngine
				)?.deprecated;

		return !!deprecated;
	}
}
