export interface PreloaderState {
	publicPreloader: PublicPreloaderState;
	securePreloader: SecurePreloaderState;
}

export interface SecurePreloaderState {
	isPreloadDone: boolean;
	errorMsg?: string;
}

export enum SECURE_PRELOADER_ACTION {
	PRELOAD = "SECURE_PRELOADER/PRELOAD",
	PRELOAD_SUCCESS = "SECURE_PRELOADER/PRELOAD_SUCCESS",
	PRELOAD_FAILED = "SECURE_PRELOADER/PRELOAD_FAILED"
}

export interface SecurePreloadAction {
	type: SECURE_PRELOADER_ACTION.PRELOAD;
}

export interface SecurePreloadSuccessAction {
	type: SECURE_PRELOADER_ACTION.PRELOAD_SUCCESS;
}

export interface SecurePreloadFailedAction {
	type: SECURE_PRELOADER_ACTION.PRELOAD_FAILED;
	payload: {
		errorMsg: string;
	};
}

export type SecurePreloaderAction =
	| SecurePreloadAction
	| SecurePreloadSuccessAction
	| SecurePreloadFailedAction;

// public preloader types
export interface PublicPreloaderState {
	isPreloadDone: boolean;
	errorMsg?: string;
}

export enum PUBLIC_PRELOADER_ACTION {
	PRELOAD = "PUBLIC_PRELOADER/PRELOAD",
	PRELOAD_SUCCESS = "PUBLIC_PRELOADER/PRELOAD_SUCCESS",
	PRELOAD_FAILED = "PUBLIC_PRELOADER/PRELOAD_FAILED"
}

export interface PublicPreloadAction {
	type: PUBLIC_PRELOADER_ACTION.PRELOAD;
}

export interface PublicPreloadSuccessAction {
	type: PUBLIC_PRELOADER_ACTION.PRELOAD_SUCCESS;
}

export interface PublicPreloadFailedAction {
	type: PUBLIC_PRELOADER_ACTION.PRELOAD_FAILED;
	payload: {
		errorMsg: string;
	};
}

export type PublicPreloaderAction =
	| PublicPreloadAction
	| PublicPreloadSuccessAction
	| PublicPreloadFailedAction;
