import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';

export const styles = (theme: Theme) =>
	createStyles({
		statusSuccess: {
			color: theme.palette.success.main
		}
	});
