import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';

export const sharedStyles = (theme: Theme) =>
	createStyles({
		nestedTreeViewItem: {
			paddingLeft: theme.spacing(6)
		},
		doubleNestedTreeViewItem: {
			paddingLeft: theme.spacing(10)
		}

	});
