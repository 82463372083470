import { Close } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { Component } from "react";
import { styles } from "./styles";

interface LocalState {}

interface LocalProps {
	onRemoveClick?: () => void;
}

type Props = LocalProps & WithStyles<typeof styles>;

class ChartActionsComponent extends Component<Props, LocalState> {
	render() {
		const { classes, onRemoveClick } = this.props;

		return (
			<div className={classes.root}>
				<Tooltip enterDelay={500} placement={"top"} title={"Remove chart"}>
					<IconButton
						size="small"
						onClick={() => {
							console.log("onRemoveClick");
							onRemoveClick && onRemoveClick();
						}}
					>
						<Close style={{ fontSize: "1em" }} />
					</IconButton>
				</Tooltip>
			</div>
		);
	}
}

export default withStyles(styles, { withTheme: true })(ChartActionsComponent);
