import { Grid } from "@mui/material";
import useStyles from "components/form/styles";

const FormWrapper = (props: any) => {
	const classes = useStyles();

	return (
		<Grid container item direction="column" className={classes.wrapper}>
			<Grid item xs={12}>
				{props.children}
			</Grid>
		</Grid>
	);
};

export default FormWrapper;
