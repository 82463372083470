// MUI
import { Tooltip, Typography } from "@mui/material";
// redux
import { useSelector } from "react-redux";
import { AppState } from "AppState";

import { CONNECTION_STATUS } from "store/systemStatus/types";
// css
import useStyles from "./styles";

function ConnectionStatusComp() {
	const {
		backendConnectionStatus,
		influxDBConnectionStatus,
		wsConnectionStatus
	} = useSelector((state: AppState) => state.systemStatus);
	const classes = useStyles();

	let mainStatus = CONNECTION_STATUS.ONLINE;

	if (
		[
			backendConnectionStatus,
			influxDBConnectionStatus,
			wsConnectionStatus
		].includes(CONNECTION_STATUS.OFFLINE)
	) {
		mainStatus = CONNECTION_STATUS.OFFLINE;
	} else if (
		[
			backendConnectionStatus,
			influxDBConnectionStatus,
			wsConnectionStatus
		].includes(CONNECTION_STATUS.WEAK)
	) {
		mainStatus = CONNECTION_STATUS.WEAK;
	}

	// console.log("main Status:", mainStatus);
	// console.log("backendConnectionStatus:", backendConnectionStatus);
	// console.log("influxDBConnectionStatus:", influxDBConnectionStatus);

	const getIndicatorClass = (status: CONNECTION_STATUS) => {
		switch (status) {
			case CONNECTION_STATUS.ONLINE:
				return classes.indicatorGreen;
			case CONNECTION_STATUS.WEAK:
				return classes.indicatorWeak;
			case CONNECTION_STATUS.OFFLINE:
				return classes.indicatorError;
		}
	};

	const circleMain = getIndicatorClass(mainStatus);

	const tooltip = (
		<div className={classes.tooltip}>
			<div className={classes.tooltipStatus}>
				<div className={getIndicatorClass(backendConnectionStatus)} />
				<Typography
					variant="body2"
					color="inherit"
					noWrap
					className={classes.text}
				>
					Management: {backendConnectionStatus}
				</Typography>
			</div>
			<div className={classes.space} />
			<div className={classes.tooltipStatus}>
				<div className={getIndicatorClass(influxDBConnectionStatus)} />
				<Typography
					variant="body2"
					color="inherit"
					noWrap
					className={classes.text}
				>
					Live monitoring: {influxDBConnectionStatus}
				</Typography>
			</div>
			<div className={classes.space} />
			<div className={classes.tooltipStatus}>
				<div className={getIndicatorClass(wsConnectionStatus)} />
				<Typography
					variant="body2"
					color="inherit"
					noWrap
					className={classes.text}
				>
					Live application updates: {wsConnectionStatus}
				</Typography>
			</div>
		</div>
	);

	const mainStatusComponent = (
		<Tooltip title={tooltip}>
			<div className={classes.navbarStatus}>
				<div className={circleMain} />
				<Typography
					variant="body2"
					color="inherit"
					noWrap
					className={classes.text}
					sx={{ display: { xs: "none", sm: "block" } }}
				>
					Connection status: {mainStatus}
				</Typography>
			</div>
		</Tooltip>
	);

	return <>{mainStatus !== CONNECTION_STATUS.ONLINE && mainStatusComponent}</>;
}

export default ConnectionStatusComp;
