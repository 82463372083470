// @flow
import { VerifiedUser } from "@mui/icons-material";
import { InputAdornment, Tooltip } from "@mui/material";

export function VerifiedAdornment() {
	return (
		<InputAdornment data-testid="input-adornment-verified" position="end">
			<Tooltip title="Verified">
				<VerifiedUser color="success" />
			</Tooltip>
		</InputAdornment>
	);
}
