import { Dashboard } from "components/monitoring/dashboard/types";

export interface DashboardState {
	dashboards: Map<string, Dashboard>;
	saveInProgress: boolean;
	getErrorMsg?: string;
	saveErrorMsg?: string;
}

export enum DASHBOARD_ACTION {
	GET = "DASHBOARD/GET",
	GET_SUCCESS = "DASHBOARD/GET_SUCCESS",
	GET_FAILED = "DASHBOARD/GET_FAILED",
	SAVE = "DASHBOARD/SAVE",
	SAVE_SUCCESS = "DASHBOARD/SAVE_SUCCESS",
	SAVE_FAILED = "DASHBOARD/SAVE_FAILED"
}

export interface DashboardGetAction {
	type: DASHBOARD_ACTION.GET;
}

export interface DashboardGetSuccessAction {
	type: DASHBOARD_ACTION.GET_SUCCESS;
	payload: {
		dashboards: Dashboard[];
	};
}

export interface DashboardGetFailedAction {
	type: DASHBOARD_ACTION.GET_FAILED;
	payload: {
		errorMsg: string;
	};
}

export interface DashboardSaveAction {
	type: DASHBOARD_ACTION.SAVE;
	payload: {
		dashboard: Dashboard;
	};
}

export interface DashboardSaveSuccessAction {
	type: DASHBOARD_ACTION.SAVE_SUCCESS;
	payload: {
		dashboards: Dashboard[]
	}
}

export interface DashboardSaveFailedAction {
	type: DASHBOARD_ACTION.SAVE_FAILED;
	payload: {
		errorMsg: string;
	};
}

export type DashboardAction =
	| DashboardGetAction
	| DashboardGetSuccessAction
	| DashboardGetFailedAction
	| DashboardSaveAction
	| DashboardSaveSuccessAction
	| DashboardSaveFailedAction;
