import { call, put } from "typed-redux-saga";
import { takeLatest } from "redux-saga/effects";
import NodesAPI from "services/api/NodesAPI";
import {
	NODE_ACTION,
	NodeCreateRequestedAction,
	NodeListFetchRequestedAction
} from "pages/management/node/types";
import {
	nodeCreateSucceeded,
	nodeListFetchSucceeded
} from "store/node/actions";

function* fetchNodeList(action: NodeListFetchRequestedAction) {
	// console.log("fetchNodeList saga");

	const { clusterID } = action.payload;
	try {
		// todo:
		const nodeList = yield* call(NodesAPI.fetchList, clusterID);
		// console.log("saga node list fetched", nodeList);

		yield put(nodeListFetchSucceeded(clusterID, nodeList));
	} catch (e) {
		console.error(e);
	}
}

function* createNode(action: NodeCreateRequestedAction) {
	yield call(NodesAPI.create, action.payload.node);
	yield put(nodeCreateSucceeded(action.payload.node));
}

function* NodeSideEffects() {
	yield takeLatest(NODE_ACTION.LIST_FETCH_REQUESTED, fetchNodeList);
	yield takeLatest(NODE_ACTION.CREATE_REQUESTED, createNode);
}

export default NodeSideEffects;
