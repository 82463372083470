// icons
import {
	AddCircleOutline as AddCircleOutlineIcon,
	DeviceHub as DeviceHubIcon,
	ExpandLess as ExpandLessIcon,
	ExpandMore as ExpandMoreIcon,
	Help as HelpIcon
} from "@mui/icons-material";
// MUI
import {
	Alert,
	Avatar,
	Badge,
	Collapse,
	Grid,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	ListSubheader,
	Tooltip,
	Typography
} from "@mui/material";
import { WithStyles, WithTheme } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { AppState } from "AppState";
import { BlinkingBadge } from "components/BlinkingBadge/BlinkingBadge";
// other
import { Cluster } from "pages/management/cluster/types";
import { Host } from "pages/management/host/types";
import { nodeCreateWizardShow } from "pages/management/node/nodeDeploymentDialog/actions";
import { Node } from "pages/management/node/types";
import { styles } from "pages/management/treeView/clusterItem/styles";
import SegmentItemComponent from "pages/management/treeView/segmentItem/SegmentItemComponent";
import StrayHostItemComponent from "pages/management/treeView/strayHostItem/StrayHostItemComponent";
import React from "react";
// redux
import { connect } from "react-redux";
import { StaticContext } from "react-router";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { createSelector } from "reselect";
import { JOB_STATUS, JobTracking } from "services/jobs/types";
import { makeJoinedNodesSelector } from "store/metricsStore/selectors";
import { MetricsStoreState } from "store/metricsStore/storeTypes";

// component local state interface
interface State {
	isExpanded: boolean;
	isSelected: boolean;
}

// PROPS
interface LocalProps {
	clusterID: number;
}

interface DispatchProps {
	nodeCreateWizardShow: (cluster: Cluster) => void;
}

interface ReduxStateProps {
	cluster: Cluster;
	filteredNodeList: Node[];
	filteredHostList: Host[];
	joinedNodes: number;
	committedTransactions: number;
	hasRunningJobs: boolean;
	unusedHosts: Host[];
}

type Props = LocalProps &
	ReduxStateProps &
	DispatchProps &
	WithStyles<typeof styles> &
	WithTheme &
	RouteComponentProps<any, StaticContext, any>;

// COMPONENT
class ClusterItemComponent extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		// is cluster currently open
		const isClusterOpen = props.location.pathname.endsWith(
			`/clusters/${props.clusterID}`
		);

		// is node from this cluster currently open
		const isNodeOpen = props.filteredNodeList.some((node: Node) =>
			props.location.pathname.endsWith(`/nodes/${node.id}`)
		);

		this.state = {
			isExpanded: isClusterOpen || isNodeOpen,
			isSelected: false
		};
	}

	componentDidMount(): void {
		this.onRouteChanged();
	}

	componentDidUpdate(prevProps: Props) {
		if (this.props.location !== prevProps.location) {
			this.onRouteChanged();
		}
	}

	onRouteChanged() {
		const isSelected =
			this.props.location.pathname === `/clusters/${this.props.clusterID}`;

		this.setState({
			isSelected
		});
	}

	onItemClick = () => {
		this.props.history.push(`/clusters/${this.props.clusterID}`);
		if (!this.state.isExpanded)
			this.setState((state) => ({ ...state, isExpanded: true }));
	};

	onExpandClick = (event: any): void => {
		event.stopPropagation();
		event.preventDefault();
		this.setState((state) => ({ ...state, isExpanded: !state.isExpanded }));
	};

	render(): React.ReactNode {
		const {
			theme,
			classes,
			clusterID,
			cluster,
			filteredHostList,
			joinedNodes,
			committedTransactions,
			hasRunningJobs,
			unusedHosts
		} = this.props;
		const { isSelected, isExpanded } = this.state;

		const getSegments = (cluster: Cluster): Set<number> => {
			let segments: Set<number> = new Set();

			if (cluster.nodes) {
				filteredHostList.forEach((host: Host) => {
					segments.add(host.segment);
				});
			}
			return segments;
		};

		const segments = getSegments(cluster);

		const addNodeItem = (
			<ListItem
				button
				onClick={(): void => {
					this.props.nodeCreateWizardShow(cluster);
				}}
				className={classes.nested}
			>
				<ListItemAvatar>
					<Avatar className={classes.clusterItemAvatar}>
						<AddCircleOutlineIcon className={classes.clusterItemAvatarIcon} />
					</Avatar>
				</ListItemAvatar>
				<ListItemText
					primary="Add node"
					// secondary="Cluster is empty until you add nodes"
				/>
			</ListItem>
		);

		const avatar = (
			<Avatar style={{ backgroundColor: theme.palette.primary.main }}>
				<DeviceHubIcon />
			</Avatar>
		);

		const strayHostSentence = `${unusedHosts.length} Stray host${
			unusedHosts.length > 1 ? "s" : ""
		} in
		cluster!`;

		const alertInsideCluster = (
			<Alert icon={false} className={classes.alertStyle}>
				{strayHostSentence}
			</Alert>
		);

		return (
			<>
				<ListItem
					selected={isSelected}
					data-testid="cluster-item"
					button
					onClick={this.onItemClick}
					className={classes.clusterElement}
				>
					<ListItemAvatar>
						{hasRunningJobs && unusedHosts.length > 0 ? (
							<Badge
								overlap="rectangular"
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "right"
								}}
								badgeContent={
									<Tooltip title={strayHostSentence}>
										<HelpIcon className={classes.badgeHelpIcon} />
									</Tooltip>
								}
							>
								<BlinkingBadge variant="dot" color="primary">
									{avatar}
								</BlinkingBadge>
							</Badge>
						) : hasRunningJobs ? (
							<BlinkingBadge variant="dot" color="primary">
								{avatar}
							</BlinkingBadge>
						) : unusedHosts.length > 0 ? (
							<Badge
								overlap="rectangular"
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "right"
								}}
								badgeContent={
									<Tooltip title={strayHostSentence}>
										<HelpIcon className={classes.badgeHelpIcon} />
									</Tooltip>
								}
							>
								{avatar}
							</Badge>
						) : (
							avatar
						)}
					</ListItemAvatar>
					<ListItemText
						data-testid="cluster-item-text"
						primary={
							<Grid container direction="row" spacing={1}>
								<Grid item>
									<Typography
										data-testid="tree-view-item-cluster-name"
										variant="body2"
									>
										{cluster.name}
									</Typography>
								</Grid>
								<Grid item>
									<Typography variant="body2" color="textSecondary">
										({committedTransactions} tx)
									</Typography>
								</Grid>
							</Grid>
						}
						secondary={
							<>
								<Typography variant="body2" color="textSecondary">
									{joinedNodes !== -1 ? joinedNodes : "-"}/
									{(cluster.nodes && cluster.nodes.length) || 0} joined/total
									nodes
								</Typography>
								{unusedHosts.length > 0 && alertInsideCluster}
							</>
						}
						disableTypography
						secondaryTypographyProps={
							(joinedNodes !==
								((cluster.nodes && cluster.nodes.length) || 0) && {
								color: "error"
							}) ||
							{}
						}
					/>
					{isExpanded ? (
						<ExpandLessIcon onClick={this.onExpandClick} />
					) : (
						<ExpandMoreIcon onClick={this.onExpandClick} />
					)}
				</ListItem>

				<Collapse in={isExpanded} timeout="auto" unmountOnExit>
					{segments.size > 0 &&
						Array.from(segments)
							.sort()
							.map((segment: number) => {
								return (
									<SegmentItemComponent
										key={segment}
										segment={segment}
										clusterID={clusterID}
									/>
								);
							})}

					{segments.size === 0 && addNodeItem}

					{unusedHosts.length > 0 && (
						<List
							subheader={
								<ListSubheader
									inset={true}
									className={classes.strayHostsSubheader}
								>
									Stray hosts
								</ListSubheader>
							}
						>
							{unusedHosts
								.sort((host1: Host, host2: Host) =>
									host1.name
										.toLowerCase()
										.localeCompare(host2.name.toLowerCase())
								)
								.map((host: Host) => {
									return <StrayHostItemComponent key={host.name} host={host} />;
								})}
						</List>
					)}
				</Collapse>
			</>
		);
	}
}

// selectors
const makeClusterSelector = () =>
	createSelector(
		(state: AppState) => state.clusterList,
		(state: AppState, props: LocalProps) => props.clusterID,
		(clusterList: Cluster[], clusterID: number): Cluster => {
			const cluster = clusterList.find(
				(cluster: Cluster) => cluster.id === clusterID
			);

			if (cluster) {
				return cluster;
			} else {
				throw Error(`Cannot find cluster.`);
			}
		}
	);

const makeFilteredNodeListSelector = () =>
	createSelector(
		(state: AppState) => state.nodeList,
		(state: AppState, props: LocalProps) => props.clusterID,
		(nodeMap: Map<number, Node[]>, clusterID: number): Node[] => {
			return nodeMap.get(clusterID) || [];
		}
	);

const makeFilteredHostListSelector = () =>
	createSelector(
		(state: AppState) => state.hostList,
		(state: AppState, props: LocalProps) => props.clusterID,
		(hostMap: Map<number, Host[]>, clusterID: number): Host[] => {
			return hostMap.get(clusterID) || [];
		}
	);

// const makeJoinedNodesSelector = () =>
// 	createSelector(
// 		(state: AppState) => state.metrics,
// 		(state: AppState) => state.clusterList,
// 		(state: AppState, props: LocalProps) => props.clusterID,
// 		(
// 			metricsStore: MetricsStoreState,
// 			clusterList: Cluster[],
// 			clusterID: number
// 		): number => {
// 			const cluster = clusterList.find(
// 				(cluster: Cluster) => cluster.id === clusterID
// 			);
//
// 			if (cluster) {
// 				return metricsStore.joinedNodes[cluster.name] || 0;
// 			} else {
// 				throw Error(`Cannot find cluster.`);
// 			}
// 		}
// 	);

const makeCommittedTransactionsSelector = () =>
	createSelector(
		(state: AppState) => state.metrics,
		(state: AppState) => state.clusterList,
		(state: AppState, props: LocalProps) => props.clusterID,
		(
			metricsRecord: MetricsStoreState,
			clusterList: Cluster[],
			clusterID: number
		): number => {
			const cluster = clusterList.find(
				(cluster: Cluster) => cluster.id === clusterID
			);

			if (cluster) {
				const lastCommitted =
					metricsRecord.wsrepLastCommittedMetrics[cluster.name];
				return lastCommitted?.value || 0;
			} else {
				throw Error(`Cannot find cluster.`);
			}
		}
	);

const makeHasRunningJobs = () =>
	createSelector(
		(state: AppState) => state.jobMonitor.runningJobList,
		(state: AppState, props: LocalProps) => props.clusterID,
		(jobList: JobTracking[] = [], clusterID: number) => {
			return jobList.some(
				(job: JobTracking) =>
					job.meta.cluster_id === clusterID && job.status === JOB_STATUS.RUNNING
			);
		}
	);

// selectors
const makeUnusedHostsSelector = () =>
	createSelector(
		(state: AppState) => state.hostList,
		(state: AppState, props: LocalProps) => props.clusterID,
		(hostMap: Map<number, Host[]>, clusterID: number): Host[] => {
			const hostList = hostMap.get(clusterID) || [];
			return hostList.filter((host: Host) => !host.nodeID) || [];
		}
	);

// REDUX MAPPINGS
const mapGlobalStateToProps = (state: AppState, props: LocalProps) => {
	const clusterSelector = makeClusterSelector();
	const filteredNodeListSelector = makeFilteredNodeListSelector();
	const filteredHostListSelector = makeFilteredHostListSelector();
	const joinedNodesSelector = makeJoinedNodesSelector();
	const committedTransactionsSelector = makeCommittedTransactionsSelector();
	const hasRunningJobsSelector = makeHasRunningJobs();
	const unusedHostsSelector = makeUnusedHostsSelector();

	return {
		cluster: clusterSelector(state, props),
		filteredNodeList: filteredNodeListSelector(state, props),
		filteredHostList: filteredHostListSelector(state, props),
		joinedNodes: joinedNodesSelector(state, props.clusterID),
		committedTransactions: committedTransactionsSelector(state, props),
		hasRunningJobs: hasRunningJobsSelector(state, props),
		unusedHosts: unusedHostsSelector(state, props)
	};
};

const mapGlobalDispatchToProps = (dispatch: any) => {
	return {
		nodeCreateWizardShow: (cluster: Cluster) => {
			dispatch(nodeCreateWizardShow(cluster));
		}
	};
};

export default withStyles(styles, { withTheme: true })(
	withRouter(
		connect(
			mapGlobalStateToProps,
			mapGlobalDispatchToProps
		)(ClusterItemComponent)
	)
);
