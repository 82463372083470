import { HelpOutline } from "@mui/icons-material";
import {
	FormControl,
	Grid,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Tooltip,
	Typography
} from "@mui/material";
import { WithStyles, WithTheme } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { CLUSTER_TYPE } from "pages/management/cluster/clusterCreateWizard/types";
import { Cluster } from "pages/management/cluster/types";
import ClusterUtils from "pages/management/cluster/utils";
import {
	EC2Config,
	FirewallRule,
	HOST_SYSTEM,
	HOST_TYPE
} from "pages/management/host/types";
import HostUtils from "pages/management/host/utils";
import { NODE_DB_ENGINE } from "pages/management/node/types";
import DbEngineConfigButtonComponent from "components/form/dbEngineConfigEditor/DbEngineConfigButtonComponent";
import DigitalOceanConfigComponent from "components/form/digitalOceanConfig/DigitalOceanConfigComponent";
import { DigitalOceanConfig } from "components/form/digitalOceanConfig/types";
import EC2ConfigComponent from "components/form/ec2Config/EC2ConfigComponent";
import FirewallRulesEditorComponent from "components/form/firewallRulesEditor/FirewallRulesEditorComponent";
import PasswordField from "components/form/passwordField/passwordFieldComponent";
import SSHKeysEditor from "components/form/SSHKeysEditor/SSHKeysEditorComponent";
import SupportMatrix from "services/supportMatrix/SupportMatrix";
import React, { ChangeEvent, FormEvent } from "react";
import { styles } from "pages/management/cluster/clusterForm/styles";
import FormTitle from "components/form/title/FormTitle";

import { INPUT_VARIANT } from "components/form/const";
import { DbEngineFieldComponent } from "../../../../components/form/formFields/DbEngine.component";
import { HostSystemFieldComponent } from "../../../../components/form/formFields/HostSystemFieldComponent";

// component local state interface
interface LocalState {
	formValidation: {
		clusterName?: {
			isInvalid: boolean;
			message: string;
		};
		dbEngine?: {
			isInvalid: boolean;
			message: string;
		};
		databaseSizeGiB?: {
			isInvalid: boolean;
			message: string;
		};
		hostSystem?: {
			isInvalid: boolean;
			message: string;
		};
	};
	isDeploymentConfigValid?: boolean;
}

interface LocalProps {
	cluster: Cluster;
	onSubmit?: (cluster: Cluster) => void;
	onChange?: (cluster: Cluster) => void;
	readOnly?: boolean;
}

// PROPS
type Props = LocalProps & WithStyles<typeof styles> & WithTheme;

// COMPONENT
class ClusterFormComponent extends React.Component<Props, LocalState> {
	constructor(props: Props) {
		super(props);
		// console.log("clusterForm", DEFAULT_CLUSTER);

		this.state = {
			formValidation: {}
		};
	}

	onSubmit = (e: FormEvent) => {
		e.preventDefault();
		// console.log("onSubmit", e.type, e.);

		if (this.validate()) {
			!this.props.readOnly &&
				this.props.onSubmit &&
				this.props.onSubmit(this.props.cluster);
		}
	};

	validate = (): boolean => {
		let isValid = true;

		// check if cluster name already exists
		// const existingClusterNames = appStore
		// 	.getState()
		// 	.clusterList.map((cluster: Cluster) => cluster.name);
		//
		// if (existingClusterNames.includes(this.props.cluster.name)) {
		// 	console.log("cluster name taken!");
		//
		// 	this.setState((state: LocalState) => ({
		// 		...state,
		// 		formValidation: {
		// 			...state.formValidation,
		// 			clusterName: {
		// 				...state.formValidation.clusterName,
		// 				isInvalid: true,
		// 				message: "Cluster with that name already exists."
		// 			}
		// 		}
		// 	}));
		//
		// 	isValid = false;
		// }

		// check if cloud access is verified
		if (
			[HOST_TYPE.DIGITAL_OCEAN, HOST_TYPE.EC2].includes(
				this.props.cluster.sharedConfig.host.type || HOST_TYPE.UNMANAGED
			) &&
			!this.state.isDeploymentConfigValid
		) {
			isValid = false;
			// console.log("cloud access is not verified");
		}

		// console.log("isValid", isValid);
		return isValid;
	};

	render(): React.ReactNode {
		const { readOnly, cluster, onChange } = this.props;
		const { formValidation } = this.state;
		const disabled = readOnly !== undefined ? readOnly : false;

		const clusterType = ClusterUtils.getClusterType(cluster);

		const showDBRootPassword = clusterType === CLUSTER_TYPE.MONITORED;

		const showCloudProvider = [HOST_TYPE.DIGITAL_OCEAN, HOST_TYPE.EC2].includes(
			cluster.sharedConfig.host.type || HOST_TYPE.UNMANAGED
		);
		const showDigitalOcean =
			cluster.sharedConfig.host.type === HOST_TYPE.DIGITAL_OCEAN &&
			cluster.sharedConfig.host.hostTypeSpecific;

		const showMaxDBSize =
			clusterType === CLUSTER_TYPE.MANAGED &&
			[HOST_TYPE.EC2, HOST_TYPE.DIGITAL_OCEAN].includes(
				cluster.sharedConfig.host.type
			);

		const showSSHKey = [HOST_TYPE.DIGITAL_OCEAN, HOST_TYPE.EC2].includes(
			cluster.sharedConfig.host.type || HOST_TYPE.UNMANAGED
		);

		return (
			<form
				id={`clusterForm${readOnly ? "-readonly" : ""}`}
				onSubmit={this.onSubmit}
			>
				<Grid
					container
					item
					direction="column"
					sx={{
						maxWidth: 600,
						margin: "auto",
						marginBottom: "12px"
					}}
				>
					<FormTitle title="General" />

					{/* Cluster Name  */}

					<Grid container item direction="row" spacing={2}>
						<Grid item xs={12}>
							<FormControl
								onInvalid={(e: FormEvent): void => {
									e.preventDefault();
									const form = e.target as HTMLFormElement;

									this.setState((state: LocalState) => ({
										...state,
										formValidation: {
											...state.formValidation,
											clusterName: {
												...state.formValidation.clusterName,
												isInvalid: true,
												message: form.validationMessage
											}
										}
									}));
								}}
								error={formValidation.clusterName?.isInvalid}
								fullWidth={true}
								required
								// disabled={disabled}
							>
								<TextField
									label="Cluster name"
									error={formValidation.clusterName?.isInvalid}
									autoFocus={!readOnly}
									required //
									autoComplete="off" //
									variant={INPUT_VARIANT}
									disabled={disabled}
									inputProps={{
										readOnly: readOnly,
										maxLength: 20,
										"data-testid": "cluster-name"
									}}
									margin="dense"
									name="clusterName"
									helperText={formValidation.clusterName?.message}
									value={cluster.name}
									onChange={(e: ChangeEvent) => {
										const field = e.target as HTMLFormElement;

										onChange &&
											onChange({
												...cluster,
												name: field.value
											});

										if (field.checkValidity()) {
											this.setState((state: LocalState) => ({
												formValidation: {
													...state.formValidation,
													clusterName: {
														...state.formValidation.clusterName,
														isInvalid: false,
														message: ""
													}
												}
											}));
										} else {
											this.setState((state: LocalState) => ({
												...state,
												formValidation: {
													...state.formValidation,
													clusterName: {
														...state.formValidation.clusterName,
														isInvalid: true,
														message: field.validationMessage
													}
												}
											}));
										}
									}}
								/>
							</FormControl>
						</Grid>
						{/* Node DB Engine */}
						<Grid
							item
							// sm={6}
							xs={12}
						>
							<DbEngineFieldComponent
								value={cluster.sharedConfig.node.dbEngine}
								hostType={cluster.sharedConfig.host.type}
								hostSystem={cluster.sharedConfig.host.system}
								readOnly={!!readOnly}
								disabled={disabled}
								onChange={(value: NODE_DB_ENGINE) => {
									onChange &&
										onChange({
											...cluster,
											sharedConfig: {
												...cluster.sharedConfig,
												node: {
													...cluster.sharedConfig.node,
													dbEngine: value
												}
											}
										});
								}}
							/>
						</Grid>

						{/* Host system */}
						<Grid item xs={12}>
							<HostSystemFieldComponent
								value={cluster.sharedConfig.host.system}
								hostType={cluster.sharedConfig.host.type}
								dbEngine={cluster.sharedConfig.node.dbEngine}
								readOnly={!!readOnly}
								disabled={disabled}
								onChange={(system: HOST_SYSTEM) => {
									onChange &&
										onChange({
											...cluster,
											sharedConfig: {
												...cluster.sharedConfig,
												host: {
													...cluster.sharedConfig.host,
													system
												}
											}
										});
								}}
							/>
						</Grid>

						{/* Custom DB engine configuration  */}
						{!cluster.unmanaged && (
							<Grid item xs={12}>
								<DbEngineConfigButtonComponent
									config={cluster.sharedConfig.node.userConfig || ""}
									readOnly={readOnly || false}
									onChange={(config: string) => {
										onChange &&
											onChange({
												...cluster,
												sharedConfig: {
													...cluster.sharedConfig,
													node: {
														...cluster.sharedConfig.node,
														userConfig: config
													}
												}
											});
									}}
									subtitleText={
										readOnly
											? "This is default DB engine configuration that will be used as default when deploying nodes"
											: "Here you can enter custom DB engine configuration that will be used as default when deploying nodes"
									}
								/>
							</Grid>
						)}
						{(clusterType === CLUSTER_TYPE.MANAGED ||
							clusterType === CLUSTER_TYPE.HYBRID) && (
							<Grid item>
								<FormTitle title="Access Control" />
							</Grid>
						)}

						{/*

							SSH Keys

						*/}
						{showSSHKey && (
							<Grid container direction="row" spacing={2}>
								{/*

									SSH Keys

								*/}

								{(readOnly && cluster.id) ||
								clusterType === CLUSTER_TYPE.MANAGED ? (
									<Grid item xs={12}>
										<Typography variant="subtitle1" sx={{ paddingLeft: 2 }}>
											Default node configuration{" "}
											<Tooltip title="Options to be set as default when adding node to the cluster. All options can be changed before node deployment. ">
												<HelpOutline fontSize="small" />
											</Tooltip>
										</Typography>

										<SSHKeysEditor
											clusterType={clusterType}
											readOnly={readOnly || false}
											authorizedKeys={
												cluster.sharedConfig.host.authorizedKeys || []
											}
											onAdd={(key: string): void => {
												onChange &&
													onChange({
														...cluster,
														sharedConfig: {
															host: {
																...cluster.sharedConfig.host,
																authorizedKeys: [
																	...(cluster.sharedConfig.host
																		.authorizedKeys || []),
																	key
																]
															},
															node: {
																...cluster.sharedConfig.node
															}
														}
													});
											}}
											onRemove={(deletedKey: string): void => {
												const filteredKeys =
													this.props.cluster.sharedConfig.host.authorizedKeys?.filter(
														(publicKey: string) => publicKey !== deletedKey
													);

												onChange &&
													onChange({
														...cluster,
														sharedConfig: {
															host: {
																...cluster.sharedConfig.host,
																authorizedKeys: filteredKeys
															},
															node: {
																...cluster.sharedConfig.node
															}
														}
													});
											}}
										/>
									</Grid>
								) : undefined}
							</Grid>
						)}
						{/*

							Firewall
							or
							Remote Acces Control ( item )

						*/}
						{/* FireWall start */}
						{(clusterType === CLUSTER_TYPE.MANAGED ||
							clusterType === CLUSTER_TYPE.HYBRID) && (
							<>
								<FirewallRulesEditorComponent
									readOnly={readOnly}
									firewallRules={cluster.firewallRules}
									onRemove={(removedRule: FirewallRule) => {
										onChange &&
											onChange({
												...cluster,
												firewallRules: cluster.firewallRules.filter(
													(rule: FirewallRule) =>
														rule.description !== removedRule.description
												)
											});
									}}
									onAdd={(addedRule: FirewallRule) => {
										onChange &&
											onChange({
												...cluster,
												firewallRules: [...cluster.firewallRules, addedRule]
											});
									}}
								/>
							</>
						)}
						{/* FireWall end */}

						{(clusterType === CLUSTER_TYPE.MANAGED ||
							clusterType === CLUSTER_TYPE.MONITORED) && (
							<Grid item>
								<FormTitle title="Deployment" />
							</Grid>
						)}

						{/*

								DB Root Password

							*/}
						{showDBRootPassword && (
							<Grid item xs={12}>
								<PasswordField
									required={true}
									value={cluster ? cluster.sharedConfig.node.rootPassword : ""}
									label="DB Root Password"
									onChange={(e: ChangeEvent) => {
										const target = e.target as HTMLFormElement;

										onChange &&
											onChange({
												...cluster,
												sharedConfig: {
													...cluster.sharedConfig,
													node: {
														...cluster.sharedConfig.node,
														rootPassword: target.value as string
													}
												}
											});
									}}
								/>
							</Grid>
						)}
					</Grid>
				</Grid>

				<Grid
					container
					item
					direction="column"
					sx={{
						maxWidth: 600,
						margin: "auto",
						marginBottom: "24px"
					}}
				>
					{/*

								Entire Access Control ---

							*/}

					{showCloudProvider && (
						<Grid container direction="row" spacing={2}>
							<Grid item xs={12}>
								{/*

									Cloud Provider

								*/}

								<FormControl
									required
									fullWidth={true}
									disabled={disabled}
									margin="dense"
								>
									<InputLabel htmlFor="host-type">Cloud provider</InputLabel>
									<Select
										displayEmpty={false}
										required
										fullWidth={true}
										variant={INPUT_VARIANT}
										disabled={disabled}
										label="Cloud provider"
										data-testid="cloud-provider-select-container"
										readOnly={readOnly}
										value={cluster.sharedConfig.host.type}
										onChange={(e) => {
											const selectedCloudProvider = e.target.value as HOST_TYPE;

											const defaultHostTypeSpecificConfig =
												HostUtils.getHostTypeSpecificDefaults(
													selectedCloudProvider
												);

											const isCurrentSystemSupported =
												SupportMatrix.isSystemSupported(
													cluster.sharedConfig.host.system,
													selectedCloudProvider
												);

											if (!isCurrentSystemSupported) {
												this.setState((state: LocalState) => ({
													...state,
													formValidation: {
														...state.formValidation,
														hostSystem: {
															isInvalid: false,
															message: `${cluster.sharedConfig.host.system} is not supported on ${selectedCloudProvider}`
														}
													}
												}));
											}

											onChange &&
												onChange({
													...cluster,
													sharedConfig: {
														...cluster.sharedConfig,
														host: {
															...cluster.sharedConfig.host,
															type: selectedCloudProvider,
															hostTypeSpecific: defaultHostTypeSpecificConfig,
															system: cluster.sharedConfig.host.system
														}
													}
												});
										}}
										inputProps={{
											id: "host-type",
											"data-testid": "cloud-provider-select"
										}}
									>
										<MenuItem
											key={HOST_TYPE.EC2}
											value={HOST_TYPE.EC2}
											disabled={
												!SupportMatrix.isSystemSupported(
													cluster.sharedConfig.host.system,
													HOST_TYPE.EC2
												)
											}
										>
											AWS EC2
											{!SupportMatrix.isSystemSupported(
												cluster.sharedConfig.host.system,
												HOST_TYPE.EC2
											) &&
												` (${cluster.sharedConfig.host.system} cannot be deployed on AWS EC2)`}
										</MenuItem>
										<MenuItem
											key={HOST_TYPE.DIGITAL_OCEAN}
											value={HOST_TYPE.DIGITAL_OCEAN}
											disabled={
												!SupportMatrix.isSystemSupported(
													cluster.sharedConfig.host.system,
													HOST_TYPE.DIGITAL_OCEAN
												)
											}
										>
											DigitalOcean
											{!SupportMatrix.isSystemSupported(
												cluster.sharedConfig.host.system,
												HOST_TYPE.DIGITAL_OCEAN
											) &&
												` (${cluster.sharedConfig.host.system} cannot be deployed on DigitalOcean)`}
										</MenuItem>
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					)}

					{showMaxDBSize && (
						<Grid item xs={12}>
							<Tooltip
								title={
									"The actual disk size will be larger to accommodate for cache, SSTs, and other features necessary for optimal DB engine operation."
								}
							>
								<FormControl
									onInvalid={(e: FormEvent): void => {
										e.preventDefault();
										const form = e.target as HTMLFormElement;

										this.setState((state: LocalState) => ({
											...state,
											formValidation: {
												...state.formValidation,
												databaseSizeGiB: {
													...state.formValidation.databaseSizeGiB,
													isInvalid: true,
													message: form.validationMessage
												}
											}
										}));
									}}
									error={formValidation.databaseSizeGiB?.isInvalid}
									fullWidth={true}
									required
								>
									<TextField
										error={formValidation.databaseSizeGiB?.isInvalid}
										helperText={formValidation.databaseSizeGiB?.message}
										fullWidth={true}
										type="number"
										required
										margin="dense"
										label="Expected max DB size"
										variant={INPUT_VARIANT}
										disabled={disabled}
										value={cluster.sharedConfig.host.databaseSizeGiB}
										onChange={(e) => {
											const databaseSizeGiB = parseInt(e.target.value);

											onChange &&
												onChange({
													...cluster,
													sharedConfig: {
														...cluster.sharedConfig,
														host: {
															...cluster.sharedConfig.host,
															databaseSizeGiB
														}
													}
												});

											this.setState((state: LocalState) => ({
												formValidation: {
													...state.formValidation,
													databaseSizeGiB: {
														...state.formValidation.databaseSizeGiB,
														isInvalid: false,
														message: ""
													}
												}
											}));
										}}
										inputProps={{
											id: "ebs-volume-size",
											"data-testid": "ebs-volume-size",
											readOnly: readOnly,
											min: 1,
											max: 65536
										}}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">GiB</InputAdornment>
											)
										}}
									/>
								</FormControl>
							</Tooltip>
						</Grid>
					)}

					{showDigitalOcean && (
						<Grid item>
							<DigitalOceanConfigComponent
								readOnly={readOnly}
								config={
									cluster.sharedConfig.host
										.hostTypeSpecific as DigitalOceanConfig
								}
								onChange={(config: DigitalOceanConfig) => {
									// console.log("onDOChange", config);

									onChange &&
										onChange({
											...cluster,
											sharedConfig: {
												...cluster.sharedConfig,
												host: {
													...cluster.sharedConfig.host,
													hostTypeSpecific: config
												}
											}
										});
								}}
								onValidationChange={(isValid: boolean) => {
									console.log("onValidationChange", isValid);
									this.setState({
										isDeploymentConfigValid: isValid
									});
								}}
							/>
						</Grid>
					)}

					{/* {console.log(
						"cluster.sharedConfig.host.type === HOST_TYPE.EC2 - ",
						cluster.sharedConfig.host.type === HOST_TYPE.EC2
					)}
					{console.log(
						"cluster.sharedConfig.host.hostTypeSpecific - ",
						cluster.sharedConfig.host.hostTypeSpecific
					)}
					{console.log("showEC2 - ", showEC2)} */}

					{cluster.sharedConfig.host.type === HOST_TYPE.EC2 &&
						cluster.sharedConfig.host.hostTypeSpecific && (
							<Grid item>
								<EC2ConfigComponent
									config={cluster.sharedConfig.host.hostTypeSpecific}
									readOnly={readOnly}
									requirements={{
										minRAM:
											cluster.sharedConfig.node.dbEngine ===
											NODE_DB_ENGINE.MYSQL_8_0
												? 2048
												: 1024
									}}
									onChange={(ec2Config: EC2Config) => {
										// console.log("onEC2 config change", ec2Config);

										onChange &&
											onChange({
												...cluster,
												sharedConfig: {
													...cluster.sharedConfig,
													host: {
														...cluster.sharedConfig.host,
														hostTypeSpecific: ec2Config
													}
												}
											});
									}}
									onValidationChange={(isValid: boolean) => {
										// console.log("onValidationChange", isValid);
										this.setState({
											isDeploymentConfigValid: isValid
										});
									}}
								/>
							</Grid>
						)}
				</Grid>
			</form>
		);
	}

	// private getDbEngineValidationMessage(): string {
	// 	if (this.state.formValidation.dbEngine?.message) {
	// 		return this.state.formValidation.dbEngine.message;
	// 	} else if (
	// 		!this.props.readOnly &&
	// 		SupportMatrix.isDbDeprecated(
	// 			this.props.cluster.sharedConfig.node.dbEngine,
	// 			this.props.cluster.sharedConfig.host.system,
	// 			this.props.cluster.sharedConfig.host.type
	// 		)
	// 	) {
	// 		return `${this.props.cluster.sharedConfig.node.dbEngine} is deprecated. It's not recommended to create new clusters using deprecated db engines.`;
	// 	} else {
	// 		return "";
	// 	}
	// }

	private getHostSystemValidationMessage(): string {
		if (this.state.formValidation.hostSystem?.message) {
			return this.state.formValidation.hostSystem?.message;
		} else if (
			!this.props.readOnly &&
			SupportMatrix.isSystemDeprecated(
				this.props.cluster.sharedConfig.host.system,
				this.props.cluster.sharedConfig.host.type
			)
		) {
			return `${this.props.cluster.sharedConfig.host.system} is deprecated. It's not recommended to create new clusters using deprecated systems.`;
		} else {
			return "";
		}
	}
}

export default withStyles(styles, { withTheme: true })(ClusterFormComponent);
