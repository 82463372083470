import { Job, JOB_STATUS, JobTracking } from "../../services/jobs/types";

export default class JobUtils {
	static getJobTracking(job: Job): JobTracking {
		return {
			id: job.id,
			status: job.executionInfo.status,
			meta: job.meta
		};
	}

	private jobStatusWeights = {
		new: 0,
		running: 1,
		success: 2,
		failure: 2,
		aborted: 2,
		missing: 2
	};

	private static getJobStatusWeight(status: JOB_STATUS): number {
		switch (status) {
			case JOB_STATUS.NEW:
				return 0;
			case JOB_STATUS.RUNNING:
				return 1;
			case JOB_STATUS.SUCCESS:
			case JOB_STATUS.ABORTED:
			case JOB_STATUS.FAILURE:
			case JOB_STATUS.MISSING:
				return 2;
		}
	}

	static compareStatuses(status1: JOB_STATUS, status2: JOB_STATUS): -1 | 0 | 1 {
		if (this.getJobStatusWeight(status1) > this.getJobStatusWeight(status2)) {
			return 1;
		} else if (
			this.getJobStatusWeight(status1) < this.getJobStatusWeight(status2)
		) {
			return -1;
		} else {
			return 0;
		}
	}
}
