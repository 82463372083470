import { ChartMetric } from "components/monitoring/charts/const";

export interface Chart {
	id: string;
	title: string;
	metric: ChartMetric;
	position: number;
	// resolution: string;
	aggregation: AGGREGATION;
	unit?: string;
	dataScaling?: number;
	yAxisMax?: number;
}

export interface Dashboard {
	name: string;
	config: DashboardConfig;
}

export interface DashboardConfig {
	charts: Chart[];
	tileSize: DASHBOARD_TILE_SIZE;
	// refreshInterval: number;
	period: string;
}

export enum DASHBOARD_TILE_SIZE {
	"SMALL" = "sm",
	"MEDIUM" = "md",
	"LARGE" = "lg"
}

export enum AGGREGATION {
	RAW = "raw",
	DIFFERENTIAL = "differential"
}
