import {
	Close,
	DeleteForever,
	MoreVert,
	PlayArrow,
	Stop
} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
	Avatar,
	Button,
	CardHeader,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Tab,
	Tabs,
	Tooltip,
	Typography
} from "@mui/material";
import { ConsoleNetworkOutline as ConsoleNetworkOutlineIcon } from "mdi-material-ui";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import { WithStyles, WithTheme } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { AppState } from "AppState";
import { AxiosError, AxiosResponse } from "axios";
import { clusterListFetchRequested } from "store/cluster/actions";
import { DEFAULT_CLUSTER } from "pages/management/cluster/const";
import { Cluster } from "pages/management/cluster/types";
import { hostListFetchRequested } from "store/host/actions";
import {
	Host,
	HOST_SYSTEM,
	HOST_TYPE,
	HostLog
} from "pages/management/host/types";
import { nodeListFetchRequested } from "store/node/actions";
import JobListComponent from "pages/management/node/jobs/JobListComponent";
import NodeFormComponent from "pages/management/node/nodeForm/NodeFormComponent";
import {
	DB_STATE,
	Node,
	NODE_DB_ENGINE,
	NODE_STATE
} from "pages/management/node/types";
import DashboardComponent from "components/monitoring/dashboard/DashboardComponent";
import { BlinkingBadge } from "components/BlinkingBadge/BlinkingBadge";
import { GMDialogService } from "components/dialog/DialogService";
import DeploymentLogMessage from "components/logViewer/DeploymentLogMessage";
import LogViewer from "components/logViewer/LogViewerComponent";
import NodeStateComponent from "components/monitoring/nodeState/nodeStateComponent";
import { showSnackbar } from "components/snackbar/actionCreators";
import {
	SNACKBAR_TYPE,
	SnackbarActionPayload
} from "components/snackbar/types";
import { Console, Database } from "mdi-material-ui";
import HostsAPI from "services/api/HostsAPI";
import LogsApi from "services/api/LogsApi";
import NodesAPI from "services/api/NodesAPI";
import JobsService from "services/jobs/jobsService";
import { Job, JOB_STATUS, JobTracking } from "services/jobs/types";
import { MetricsStoreState } from "store/metricsStore/storeTypes";
import React, { ChangeEvent } from "react";
import { connect } from "react-redux";
import { StaticContext } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import { createSelector } from "reselect";
import { styles } from "pages/management/node/styles";

// component local state interface
interface LocalState {
	cluster: Cluster;
	node: Node;
	host: Host;
	activeTab: number;
	logs: any[];
	anchorEl: any;
	showLog: boolean;
	isLogCopied: boolean;
}

interface LocalProps {}

// PROPS
interface ReduxStateProps {
	node: Node | undefined;
	host: Host | undefined;
	cluster: Cluster | undefined;
	hasRunningJobs: boolean;
	nodeState: NODE_STATE;
}

interface ReduxDispatchProps {
	showSnackbar: (snackbar: SnackbarActionPayload) => void;
	nodeListFetchRequested: (clusterID: number) => void;
	hostListFetchRequested: (clusterID: number) => void;
	reloadClusters: () => void;
}

type Props = LocalProps &
	ReduxStateProps &
	ReduxDispatchProps &
	WithStyles<typeof styles> &
	WithTheme &
	RouteComponentProps<any, StaticContext, any>;

interface Snapshot {
	hasNodeSwitched: boolean; // indicates if another node has been selected
	hasNodeUpdated: boolean; // indicates if current node has been changed
}

enum TABS {
	MONITORING,
	LOGS,
	CONFIGURATION,
	JOBS
}

// COMPONENT
class NodeManagerComponent extends React.Component<Props, LocalState> {
	_isMounted = false;

	_cancelRequest: any;
	_scheduledFetch: any;

	constructor(props: Props) {
		super(props);

		// console.log("NodeManagerComponent", props.node, props.host, props.cluster);

		if (props.cluster && props.node && props.host) {
			this.state = {
				cluster: props.cluster,
				node: props.node,
				host: props.host,
				activeTab: TABS.MONITORING,
				logs: [],
				anchorEl: undefined,
				showLog: false,
				isLogCopied: false
			};
			this.getLogs();
		} else {
			this.props.showSnackbar({
				msg: `Error loading node data`,
				snackbarType: SNACKBAR_TYPE.ERROR
			});
		}
	}

	getLogs = () => {
		this.props.host &&
			LogsApi.fetchHostLog(this.props.host).then((response: AxiosResponse) => {
				// console.log("deployment logs", response.data);

				const splitLogs = response.data.data.attributes.contents.split("\n");
				// console.log("split logs", splitLogs);

				this.setState((state: LocalState) => ({
					...state,
					logs: splitLogs.reduce((result: any, row: string) => {
						// console.log("raw line", row);

						try {
							const line = JSON.parse(row);

							// console.log("line", line);

							let channelType;
							if (line["channel-type"] === "app") {
								channelType = "galera-manager";
							} else {
								channelType = `host-${line["channel-type"]}`;
							}

							result.push({
								time: line.time,
								msg: line.msg,
								"channel-type": channelType
							});
						} catch (e) {
							// console.warn("Parsing log line failed", e);
						}

						return result;
					}, [])
				}));
			});
	};

	getSnapshotBeforeUpdate(
		prevProps: Readonly<Props>,
		prevState: Readonly<LocalState>
	): Snapshot {
		return {
			hasNodeSwitched:
				prevProps.match.params.clusterID !==
					this.props.match.params.clusterID ||
				prevProps.match.params.nodeID !== this.props.match.params.nodeID,
			hasNodeUpdated:
				prevProps.node?.settings.logs.generalLog.enabled !==
				this.props.node?.settings.logs.generalLog.enabled
		};
	}

	componentDidUpdate(
		prevProps: Readonly<Props>,
		prevState: Readonly<LocalState>,
		snapshot: Snapshot
	): void {
		if (snapshot.hasNodeSwitched) {
			const { node, host, cluster } = this.props;

			if (cluster && node && host) {
				this.setState(() => ({
					cluster,
					node,
					host,
					logs: [],
					anchorEl: undefined
				}));
				this.getLogs();
				this.clearAsyncRequests();
				// this.getNodeState();
			} else {
				this.props.showSnackbar({
					msg: `Error loading data.`
				});
			}
		} else if (snapshot.hasNodeUpdated) {
			this.props.node && this.setState({ node: this.props.node });
		}
	}

	componentDidMount(): void {
		this._isMounted = true;
		// this.getNodeState();
	}

	componentWillUnmount(): void {
		this._isMounted = false;

		this.clearAsyncRequests();
	}

	clearAsyncRequests(): void {
		if (this._cancelRequest) this._cancelRequest();
		if (this._scheduledFetch) clearTimeout(this._scheduledFetch);
	}

	onDeleteClick = (host: Host, node: Node, force?: boolean): void => {
		const message = force
			? `Node will be uninstalled and host will be deleted if possible. If deletion fails, node and host will still be removed from Galera Manager.`
			: `Node will be uninstalled and host will be deleted.`;

		GMDialogService.showConfirm({
			title: force
				? `Force delete node ${this.state.node.name}?`
				: `Delete node ${this.state.node.name}?`,
			message,
			confirmText: force ? "Force delete" : "Delete",
			declineText: "Cancel",
			destructiveConfirm: true
		}).then(
			() => {
				HostsAPI.delete(host, force).then(
					(job: Job) => {
						// console.log("host delete job id", jobId);
						JobsService.trackJob(job).then(
							() => {
								this.props.showSnackbar({
									msg: `Node ${node.name} successfully deleted`
								});

								// redirect to /clusters if node still open
								if (
									this.props.location.pathname.endsWith(
										`/clusters/${node.clusterID}/nodes/${node.id}`
									)
								) {
									this.props.history.push(`/clusters/${node.clusterID}`);
								}

								this.props.nodeListFetchRequested(node.clusterID);
								this.props.hostListFetchRequested(node.clusterID);
								this.props.reloadClusters();
							},
							(err: any) => {
								console.error("Node deletion job monitor error:", err);

								this.props.showSnackbar({
									msg: `Failed to delete node ${node.name}.`,
									snackbarType: SNACKBAR_TYPE.ERROR
								});
							}
						);
					},
					(err: AxiosError) => {
						console.error("Error deleting node:", err);

						this.props.showSnackbar({
							msg: `Node '${node.name}' could not be deleted`,
							snackbarType: SNACKBAR_TYPE.ERROR
						});
					}
				);

				this.props.showSnackbar({
					msg: `Node ${node.name} is being deleted`
				});
			},
			() => {}
		);

		this.setState({
			anchorEl: null
		});
	};

	onStartClick = (): void => {
		GMDialogService.showConfirm({
			message: `Start node ${this.state.node.name}?`,
			confirmText: "Start",
			declineText: "Cancel"
		}).then(
			() => {
				NodesAPI.start(this.state.node).then((job: Job) => {
					JobsService.trackJob(job).then(
						() => {
							this.props.showSnackbar({
								snackbarType: SNACKBAR_TYPE.SUCCESS,
								msg: `Node ${this.state.node.name} started`
							});
							// console.log("node start job done!", node.name);
						},
						(job: Job) => {
							this.props.showSnackbar({
								snackbarType: SNACKBAR_TYPE.SUCCESS,
								msg: `Failed to start node ${this.state.node.name}`
							});
							// console.log("node start job failed", node.name, job);
						}
					);
				});
			},
			() => {}
		);

		this.setState({
			anchorEl: null
		});
	};

	onStopClick = (): void => {
		GMDialogService.showConfirm({
			message: `Stop node ${this.state.node.name}?`,
			confirmText: "Stop",
			declineText: "Cancel"
		}).then(
			() => {
				NodesAPI.stop(this.state.node).then((job: Job) => {
					JobsService.trackJob(job).then(
						() => {
							this.props.showSnackbar({
								snackbarType: SNACKBAR_TYPE.SUCCESS,
								msg: `Node ${this.state.node.name} stopped`
							});
							// console.log("node start job done!", node.name);
						},
						(job: Job) => {
							this.props.showSnackbar({
								snackbarType: SNACKBAR_TYPE.SUCCESS,
								msg: `Failed to stop node ${this.state.node.name}`
							});
							// console.log("node start job failed", node.name, job);
						}
					);
				});
			},
			() => {}
		);

		this.setState({
			anchorEl: null
		});
	};

	onShowLogsClick = (): void => {
		this.setState({
			anchorEl: null,
			showLog: true
		});
	};

	onOpenSSHTerminal = (): void => {
		// open ssh terminal in new tab
		const clusterID = this.state.node.clusterID;
		const hostID = this.state.node.hostID;
		const url = `/v2/term?cluster_id=${clusterID}&host_id=${hostID}`;

		window.open(url, "_blank");
	};

	onMoreClick = (event: any) => {
		// isExpanded menu
		this.setState({ ...this.state, anchorEl: event.currentTarget });
	};

	onMoreMenuClose = () => {
		this.setState({ ...this.state, anchorEl: null });
	};

	onToggleGeneralLog = () => {
		const node = this.state.node;
		const isEnabled = node.settings.logs.generalLog.enabled;

		GMDialogService.showConfirm({
			title: `${isEnabled ? "Disable" : "Enable"} general log on ${node.name}?`,
			message: isEnabled
				? "Disabling general log means that DB engine will stop printing messages into the general log file, and you will stop seeing them in the log viewer."
				: "Enabling general log means that DB engine will start printing messages into the general log file, and you should start seeing those messages in log viewer immediately. IMPORTANT WARNING: Enabling general log consumes large amount of disk space over time on the Galera Manager host, please make sure you have enough free disk space.",
			confirmText: isEnabled ? "Disable" : "Enable",
			declineText: "Cancel"
		}).then(() => {
			this.setState({ activeTab: TABS.JOBS });
			NodesAPI.toggleGeneralLog(node).then(
				(job: Job) => {
					// console.log("toggle general log job", jobId);

					JobsService.trackJob(job).then(
						() => {
							this.props.showSnackbar({
								msg: `General log is successfully ${
									isEnabled ? "disabled" : "enabled"
								} on ${node.name}`
							});

							this.props.nodeListFetchRequested(node.clusterID);
						},
						(err: any) => {
							console.error("General log toggle job monitor error:", err);

							this.props.showSnackbar({
								msg: `Failed to ${
									isEnabled ? "disable" : "enable"
								} general log on ${node.name}`,
								snackbarType: SNACKBAR_TYPE.ERROR
							});
						}
					);
				},
				(err: AxiosError) => {
					console.error("Error toggling general log node:", err);

					this.props.showSnackbar({
						msg: `Failed to ${
							isEnabled ? "disable" : "enable"
						} general log on ${node.name}`,
						snackbarType: SNACKBAR_TYPE.ERROR
					});
				}
			);
		});

		this.setState({
			anchorEl: null
		});
	};

	handleCopyDeploymentLogsClick = () => {
		const copyJSON = JSON.stringify(this.state.logs);

		if (navigator.clipboard) {
			navigator.clipboard.writeText(copyJSON).then(
				() => {
					this.props.showSnackbar({
						msg: "Copied deployment logs to clipboard"
					});

					this.setState({
						isLogCopied: true
					});
				},
				(err: Error) => {
					console.error("Deployment logs copy error:", err);
					this.props.showSnackbar({
						msg: "Couldn't copy deployment logs to clipboard"
					});
				}
			);
		} else {
			this.props.showSnackbar({
				msg: "Couldn't copy deployment logs because the application is not served over HTTPS"
			});
		}
	};

	render(): React.ReactNode {
		const { classes, theme, hasRunningJobs, nodeState } = this.props;

		if (!this.state) return false;

		const {
			node,
			host,
			cluster,
			activeTab,
			anchorEl,
			showLog,
			logs,
			isLogCopied
		} = this.state;

		return (
			<Card className={classes.card}>
				<CardHeader
					avatar={
						<Avatar
							style={{ backgroundColor: theme.palette.primary.main }}
							aria-label="Node"
						>
							<Database />
						</Avatar>
					}
					action={
						<Grid container direction="row" alignItems="center">
							<Grid item>{<NodeStateComponent nodeState={nodeState} />}</Grid>
							<Grid item>
								<IconButton
									data-testid="node-manager-more-button"
									onClick={this.onMoreClick}
									size="large"
								>
									<MoreVert />
								</IconButton>
								<Menu
									id="long-menu"
									anchorEl={anchorEl}
									open={Boolean(anchorEl)}
									onClose={this.onMoreMenuClose}
								>
									<MenuItem data-testid="start-node" onClick={this.onStartClick}>
										<ListItemIcon>
											<PlayArrow />
										</ListItemIcon>
										<ListItemText primary="Start" />
									</MenuItem>
									<MenuItem data-testid="stop-node" onClick={this.onStopClick}>
										<ListItemIcon>
											<Stop />
										</ListItemIcon>
										<ListItemText primary="Stop" />
									</MenuItem>

									<Divider variant={"middle"} />

									<MenuItem
										data-testid="show-deployment-logs"
										onClick={this.onShowLogsClick}
									>
										<ListItemIcon>
											<Console />
										</ListItemIcon>
										<ListItemText primary="Show deployment logs" />
									</MenuItem>

									<Tooltip
										title="Open SSH Terminal in new tab"
										placement="left"
										enterDelay={400}
									>
										<MenuItem
											data-testid="show-deployment-logs"
											onClick={this.onOpenSSHTerminal}
										>
											<ListItemIcon>
												<ConsoleNetworkOutlineIcon />
											</ListItemIcon>
											<ListItemText primary="SSH Terminal" />
										</MenuItem>
									</Tooltip>

									{/*<MenuItem*/}
									{/*	data-testid="toggle-general-log"*/}
									{/*	onClick={this.onToggleGeneralLog}*/}
									{/*>*/}
									{/*	<ListItemIcon>*/}
									{/*		<ToggleOff />*/}
									{/*	</ListItemIcon>*/}
									{/*	<ListItemText*/}
									{/*		primary={`${*/}
									{/*			isEnabled ? "Disable" : "Enable"*/}
									{/*		} general log`}*/}
									{/*	/>*/}
									{/*</MenuItem>*/}
									<Divider variant={"middle"} />

									<MenuItem
										data-testid="delete-node"
										onClick={() => {
											this.onDeleteClick(host, node);
										}}
									>
										<ListItemIcon>
											<DeleteIcon />
										</ListItemIcon>
										<ListItemText primary="Delete node" />
									</MenuItem>
									<MenuItem
										data-testid="delete-node-force"
										onClick={() => {
											this.onDeleteClick(host, node, true);
										}}
									>
										<ListItemIcon>
											<DeleteForever />
										</ListItemIcon>
										<ListItemText primary="Force delete node" />
									</MenuItem>
								</Menu>
							</Grid>
						</Grid>
					}
					title={node.name}
					subheader={node.dbEngine}
				/>
				<Divider style={{ margin: 0 }} />
				<Tabs
					value={activeTab}
					onChange={(event: ChangeEvent<{}>, newValue: any) => {
						this.setState((state: LocalState) => ({
							...state,
							activeTab: newValue
						}));
					}}
					variant="fullWidth"
				>
					<Tab label={<Typography>Monitor</Typography>} />
					<Tab label={<Typography data-testid="logs-tab">Logs</Typography>} />
					<Tab
						label={
							<Typography data-testid="configuration-tab">Configuration</Typography>
						}
					/>
					<Tab
						label={
							hasRunningJobs ? (
								<BlinkingBadge variant="dot" color="primary">
									<Typography>Jobs</Typography>
								</BlinkingBadge>
							) : (
								<Typography>Jobs</Typography>
							)
						}
					/>
				</Tabs>
				<CardContent
					className={
						activeTab === TABS.LOGS ? classes.noPaddingCardContent : ""
					}
				>
					{(activeTab === TABS.MONITORING && (
						<DashboardComponent cluster={cluster} node={node} />
					)) ||
						(activeTab === TABS.LOGS && (
							<Grid container item sm style={{ height: 590 }}>
								{/*<LogViewer nodes={[node]} hosts={[host]} />*/}
								<LogViewer cluster={cluster} node={node} />
							</Grid>
						)) ||
						(activeTab === TABS.CONFIGURATION && (
							<NodeFormComponent
								cluster={cluster}
								node={node}
								host={host}
								readOnly={true}
								onToggleGeneralLog={this.onToggleGeneralLog}
							/>
						)) ||
						(activeTab === TABS.JOBS && (
							<JobListComponent cluster={cluster} node={node} host={host} />
						))}
				</CardContent>

				<Dialog
					onClose={(event: Event, reason: string) => {
						if (reason === "escapeKeyDown") {
							this.setState({ showLog: false, isLogCopied: false });
						}
					}}
					maxWidth="xl"
					fullWidth={true}
					open={showLog}
				>
					<DialogTitle>
						{`${node.name} deployment logs`}
						<IconButton
							aria-label="close"
							className={classes.closeButton}
							onClick={() => {
								this.setState({ showLog: false, isLogCopied: false });
							}}
							size="large"
						>
							<Close />
						</IconButton>
					</DialogTitle>
					<DialogContent className={classes.logContent}>
						<Button
							variant="contained"
							onClick={this.handleCopyDeploymentLogsClick}
							className={classes.copyDeploymentLogsBtn}
						>
							{isLogCopied ? "Copied" : "Copy"}
							{/* Deployment Logs */}
						</Button>
						<Grid container direction="row">
							{logs.map((log: HostLog, index: number) => (
								<DeploymentLogMessage key={index} logLine={log} />
							))}
							<div id="bottom-element" ref={() => {}} />
						</Grid>
					</DialogContent>
				</Dialog>
			</Card>
		);
	}
}

// selectors
const makeNodeSelector = () =>
	createSelector(
		(state: AppState) => state.nodeList,
		(state: AppState, props: Props) => props.match.params.clusterID,
		(state: AppState, props: Props) => props.match.params.nodeID,
		(state: AppState, props: Props) => props.history,
		(
			nodeMap: Map<number, Node[]>,
			clusterID: number,
			nodeID: number,
			history
		): Node => {
			const nodeList = nodeMap.get(clusterID) || [];
			const node = nodeList.find((node: Node) => node.id === nodeID);

			if (node) return node;
			else {
				console.warn(`Node not found.`);
				history.push("/clusters");
				return {
					name: "",
					clusterID: -1,
					dbEngine: NODE_DB_ENGINE.MARIADB_10_3,
					hostID: -1,
					settings: {
						logs: {
							generalLog: {
								enabled: false
							}
						}
					}
				};
				// throw Error(`Could not find node '${nodeID}'`);
			}
		}
	);

const makeHostSelector = () =>
	createSelector(
		(state: AppState) => state.nodeList,
		(state: AppState) => state.hostList,
		(state: AppState, props: Props) => props.match.params.clusterID,
		(state: AppState, props: Props) => props.match.params.nodeID,
		(state: AppState, props: Props) => props.history,
		(
			nodeMap: Map<number, Node[]>,
			hostMap: Map<number, Host[]>,
			clusterID: number,
			nodeID: number,
			history
		): Host => {
			const nodeList = nodeMap.get(clusterID) || [];
			const hostList = hostMap.get(clusterID) || [];

			const node = nodeList.find((node: Node) => node.id === nodeID);
			if (!node) {
				console.warn(`Node ${nodeID} not found.`);
				history.push("/clusters");
				return {
					name: "",
					clusterID: -1,
					segment: 0,
					type: HOST_TYPE.UNMANAGED,
					system: HOST_SYSTEM.CENTOS_7,
					privateKey: "",
					privateKeyPassword: "",
					authorizedKeys: []
				};
				// throw Error(`Could not find node '${nodeID}'`);
			}

			const host = hostList.find((host: Host) => host.id === node.hostID);
			if (!host) {
				console.warn(`Host ${node.hostID} not found.`);
				history.push("/clusters");
				return {
					name: "",
					clusterID: -1,
					segment: 0,
					type: HOST_TYPE.UNMANAGED,
					system: HOST_SYSTEM.CENTOS_7,
					privateKey: "",
					privateKeyPassword: "",
					authorizedKeys: []
				};
				// throw Error(`Could not find host '${node.host}'`);
			}

			return host;
		}
	);

const makeClusterSelector = () =>
	createSelector(
		// (state: AppState) => state.nodeList,
		(state: AppState) => state.clusterList,
		(state: AppState, props: Props) => props.match.params.clusterID,
		(state: AppState, props: Props) => props.history,
		(
			// nodeList: Node[],
			clusterList: Cluster[],
			clusterID: number,
			history
		): Cluster => {
			// const node = nodeList.find((node: Node) => node.name === nodeName);
			// if (!node) {
			// 	console.warn(`Node ${nodeName} not found.`);
			// 	history.push("/clusters");
			// 	return { ...DEFAULT_CLUSTER };
			// 	// throw Error(`Could not find node ${nodeName}`);
			// }

			const cluster = clusterList.find(
				(cluster: Cluster) => cluster.id === clusterID
			);

			if (!cluster) {
				console.warn(`Cluster not found.`);
				history.push("/clusters");
				return { ...DEFAULT_CLUSTER };
				// throw Error(`Could not find cluster '${node.cluster}'`);
			}

			return cluster;
		}
	);

const makeHasRunningJobs = () =>
	createSelector(
		[makeNodeSelector(), (state: AppState) => state.jobMonitor.runningJobList],
		(node: Node, jobList: JobTracking[]) =>
			jobList.some(
				(job: JobTracking) =>
					(job.meta.node_id === node?.id ||
						job.meta.host_id === node?.hostID) &&
					job.meta.cluster_id === node?.clusterID &&
					job.status === JOB_STATUS.RUNNING
			)
	);

const makeNodeStateSelector = () =>
	createSelector(
		[
			(state: AppState) => state.metrics,
			makeClusterSelector(),
			makeNodeSelector()
		],
		(
			metricsStore: MetricsStoreState,
			cluster: Cluster,
			node: Node
		): NODE_STATE => {
			if (node && cluster) {
				const localStateMetric =
					metricsStore.wsrepLocalStateMetrics[`${cluster.name},${node.name}`];
				return localStateMetric?.value || DB_STATE.UNKNOWN;
			} else {
				return DB_STATE.UNKNOWN;
			}
		}
	);

// REDUX MAPPINGS
const mapGlobalStateToProps = (state: AppState, props: Props) => {
	const nodeSelector = makeNodeSelector();
	const hostSelector = makeHostSelector();
	const clusterSelector = makeClusterSelector();
	const hasRunningJobsSelector = makeHasRunningJobs();
	const nodeStateSelector = makeNodeStateSelector();

	return {
		node: nodeSelector(state, props),
		host: hostSelector(state, props),
		cluster: clusterSelector(state, props),
		hasRunningJobs: hasRunningJobsSelector(state, props),
		nodeState: nodeStateSelector(state, props)
	};
};

const mapGlobalDispatchToProps = (dispatch: any) => {
	return {
		nodeListFetchRequested: (clusterID: number) => {
			dispatch(nodeListFetchRequested(clusterID));
		},
		hostListFetchRequested: (clusterID: number) => {
			dispatch(hostListFetchRequested(clusterID));
		},
		reloadClusters: () => {
			dispatch(clusterListFetchRequested());
		},
		showSnackbar: (snackbar: SnackbarActionPayload) => {
			dispatch(showSnackbar(snackbar));
		}
	};
};

export default withStyles(styles, { withTheme: true })(
	connect(mapGlobalStateToProps, mapGlobalDispatchToProps)(NodeManagerComponent)
);
