import { ISizeExt } from "components/form/digitalOceanConfig/types";

export default class DigitalOceanUtils {
	/**
	 * @desc coverts array of sizes to map (with description being a key) and sorts
	 * @param {ISizeExt[]} sizes
	 * @returns {Map<string, ISizeExt[]>}
	 */
	static convertSizesToMap(sizes: ISizeExt[]): Map<string, ISizeExt[]> {
		let categorizedSizes: Map<string, ISizeExt[]> = new Map<
			string,
			ISizeExt[]
		>();

		// sort by description and fills into map
		sizes
			.sort((size1: ISizeExt, size2: ISizeExt) =>
				size1.description
					.toLowerCase()
					.localeCompare(size2.description.toLowerCase())
			)
			.forEach((size: ISizeExt) => {
				const categoryName = size.description;
				const sizesInCategory = categorizedSizes.get(categoryName);

				if (!sizesInCategory) {
					categorizedSizes.set(categoryName, [size]);
				} else {
					categorizedSizes.set(categoryName, [...sizesInCategory, size]);
				}
			});

		// sort sizes in map by price
		categorizedSizes.forEach((sizes: ISizeExt[], category: string) => {
			// console.log("category", category);
			categorizedSizes.set(
				category,
				sizes.sort(
					(size1: ISizeExt, size2: ISizeExt) =>
						size1.price_monthly - size2.price_monthly
				)
			);
		});

		return categorizedSizes;
	}

	static getSelectFieldMessage(
		isAccessTokenVerified: boolean,
		isLoading: boolean,
		msg: string = ""
	): string {
		if (!isAccessTokenVerified) {
			return "Enter valid DigitalOcean access token";
		} else if (isLoading) {
			return "Loading options from DigitalOcean...";
		} else {
			return msg;
		}
	}
}
