import { Theme } from "@mui/material";

import createStyles from "@mui/styles/createStyles";

const loginFormWidth = 340;

export const styles = (theme: Theme) =>
	createStyles({
		logo: {
			margin: "40px",
			height: "200px",
			[theme.breakpoints.down(600)]: {
				margin: "15px",
				height: "150px"
			}
		},
		formGridItem: {
			[theme.breakpoints.down(loginFormWidth)]: {
				width: "100%"
			}
		},
		formContainer: {
			width: loginFormWidth,
			[theme.breakpoints.down(loginFormWidth)]: {
				width: "100%"
			}
		},
		loginLoader: {
			marginRight: 10
		}
	});
