import Axios, { CancelToken } from "axios";
import { deattribute } from "kitsu-core";
import APIUtils from "services/api/utils";
import AppEnvironment from "services/appEnvironment";
import { Job, JobTracking, SerializedJob } from "services/jobs/types";

const ENDPOINT = "jobs";

class JobsApi {
	static async fetchRunningJobList(): Promise<JobTracking[]> {
		let params: any = {
			"page[number]": 1,
			"page[size]": 100,
			"filter[job_status]": "running"
		};

		try {
			const jobsResponse = await Axios.get(APIUtils.getUrl(ENDPOINT), {
				params
			});

			return jobsResponse.data.data.map((item: SerializedJob) =>
				deattribute({ ...item })
			);
		} catch (e) {
			throw new Error(APIUtils.APIErrorResponseHandler(e));
		}
	}

	static async fetchFilteredJobList(job_ids: number[]): Promise<Job[]> {
		let params: any = {
			"page[number]": 1,
			"page[size]": 100
		};

		params["filter[job_id]"] = job_ids.join(",");

		try {
			const jobsResponse = await Axios.get(APIUtils.getUrl(ENDPOINT), {
				params
			});
			// console.log("tracked jobList", jobsResponse.data.data);

			return jobsResponse.data.data.map((item: SerializedJob) =>
				deattribute({ ...item })
			);
		} catch (e) {
			throw new Error(APIUtils.APIErrorResponseHandler(e));
		}
	}

	static async fetchPaginatedList(
		page: number,
		pageSize: number,
		clusterID: number,
		hostID?: number,
		cancelToken?: CancelToken
	): Promise<{ list: Job[]; totalCount: number }> {
		try {
			const jobsResponse = await Axios.get(APIUtils.getUrl(ENDPOINT), {
				cancelToken: cancelToken,
				params: {
					"page[number]": page,
					"page[size]": pageSize,
					"filter[cluster_id]": clusterID,
					"filter[host_id]": hostID
				}
			});

			const list = jobsResponse.data.data.map((item: SerializedJob) =>
				deattribute({ ...item })
			);

			const totalCount: number = jobsResponse.data.meta.total;

			return { list, totalCount };
		} catch (e) {
			throw new Error(APIUtils.APIErrorResponseHandler(e));
		}
	}

	static async fetch(id: number): Promise<Job> {
		try {
			const response = await Axios.get(
				`${AppEnvironment.getApiAddress()}/${AppEnvironment.getApiVersion()}/jobs/${id}`
			);

			return deattribute(response.data.data);
		} catch (e) {
			throw new Error(APIUtils.APIErrorResponseHandler(e));
		}
	}
}

export default JobsApi;
