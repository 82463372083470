import { Subject } from "rxjs";
import {
	AlertDialog,
	ConfirmDialog
} from "components/dialog/types";

export class GMDialogService {
	private static instance: GMDialogService;

	static getInstance(): GMDialogService {
		if (!GMDialogService.instance) {
			GMDialogService.instance = new GMDialogService();
		}
		return GMDialogService.instance;
	}

	// ALERT
	private _alertDialog: Subject<AlertDialog> = new Subject<AlertDialog>();

	static get alertDialog(): Subject<any> {
		const instance = this.getInstance();
		return instance._alertDialog;
	}

	public static async showInfo(content: AlertDialog): Promise<undefined> {
		const instance = this.getInstance();

		return new Promise((resolve) => {
			instance._alertDialog.next({ ...content, resolve });
		});
	}

	// CONFIRM
	private _confirmDialog: Subject<ConfirmDialog> = new Subject<ConfirmDialog>();

	static get confirmDialog(): Subject<any> {
		const instance = this.getInstance();
		return instance._confirmDialog;
	}

	public static async showConfirm(content: ConfirmDialog): Promise<undefined> {
		const instance = this.getInstance();
		return new Promise((resolve, reject) => {
			instance._confirmDialog.next({ ...content, resolve, reject });
		});
	}
}
