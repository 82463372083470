import { ErrorOutline, Info, InfoOutlined } from "@mui/icons-material";
import { CircularProgress, ListItemIcon, Theme } from "@mui/material";
import { CONNECTION_TEST_STATUS } from "components/connectionTestDialog/types";
import { CheckCircle } from "mdi-material-ui";
import React from "react";

export default class ConnectionTestUtils {
	static getStatusMessage = (status: CONNECTION_TEST_STATUS): string => {
		switch (status) {
			// case CONNECTION_TEST_STATUS.NOT_READY:
			// 	return "Please enter node's SSH address";
			case CONNECTION_TEST_STATUS.READY:
				return "Ready to test";
			case CONNECTION_TEST_STATUS.SUCCESS:
				return "Successfully connected";
			case CONNECTION_TEST_STATUS.RUNNING:
				return "Testing connection. Please wait...";
			case CONNECTION_TEST_STATUS.ERROR:
				return "";
		}
	};

	// static getDBConnectionMessage = (status: CONNECTION_TEST_STATUS): string => {
	// 	switch (status) {
	// 		// case CONNECTION_TEST_STATUS.NOT_READY:
	// 		// 	return "Please enter DB root password first";
	// 		case CONNECTION_TEST_STATUS.READY:
	// 			return "Ready to test";
	// 		case CONNECTION_TEST_STATUS.SUCCESS:
	// 			return "Successfully connected";
	// 		case CONNECTION_TEST_STATUS.RUNNING:
	// 			return "Testing connection. Please wait...";
	// 		case CONNECTION_TEST_STATUS.ERROR:
	// 			return "";
	// 	}
	// };

	static renderStatusIcon = (status: CONNECTION_TEST_STATUS, theme: Theme) => {
		switch (status) {
			// case CONNECTION_TEST_STATUS.NOT_READY:
			// 	return (
			// 		<ListItemIcon>
			// 			<Info color="primary" />
			// 		</ListItemIcon>
			// 	);
			case CONNECTION_TEST_STATUS.READY:
				return (
					<ListItemIcon>
						<InfoOutlined />
					</ListItemIcon>
				);
			case CONNECTION_TEST_STATUS.RUNNING:
				return (
					<ListItemIcon>
						<CircularProgress size={16} />
					</ListItemIcon>
				);
			case CONNECTION_TEST_STATUS.SUCCESS:
				return (
					<ListItemIcon>
						<CheckCircle style={{ color: theme.palette.success.main }} />
					</ListItemIcon>
				);
			case CONNECTION_TEST_STATUS.ERROR:
				return (
					<ListItemIcon>
						<ErrorOutline color="error" />
					</ListItemIcon>
				);
		}
	};

	static renderNotReadyIcon = () => {
		return (
			<ListItemIcon>
				<Info color="primary" />
			</ListItemIcon>
		);
	};

	static parseMysqladminMessage = (msg: string): string => {
		// console.log(
		// 	"parseMysqladminMessage",
		// 	msg,
		// 	msg.split("\n"),
		// 	msg.split("\n").filter((line: string) => {
		// 		return !line.includes(
		// 			"Using a password on the command line interface can be insecure"
		// 		);
		// 	})
		// );

		return msg
			.replace("\x07", "")
			.split("\n")
			.filter((line: string) => {
				return !line.includes(
					"Using a password on the command line interface can be insecure"
				);
			})
			.join(". ");
	};
}
