import {
	CLUSTER_CREATE_WIZARD_ACTION,
	ClusterCreateWizardAction,
	ClusterCreateWizardState
} from "pages/management/cluster/clusterCreateWizard/types";

const initialState: ClusterCreateWizardState = {
	isOpen: false,
};

export function clusterCreateWizardReducer(
	state: ClusterCreateWizardState = initialState,
	action: ClusterCreateWizardAction
): ClusterCreateWizardState {
	switch (action.type) {
		case CLUSTER_CREATE_WIZARD_ACTION.SHOW:
			return { ...initialState, isOpen: true };
		case CLUSTER_CREATE_WIZARD_ACTION.HIDE:
			return { ...state, isOpen: false };
		default:
			return state;
	}
}
