import { Theme } from "@mui/material";

import { makeStyles } from "@mui/styles";

export const drawerWidth = 240;
export const drawerMiniWidth = 48;
export const drawerMobile = 0;

export const useStyles = makeStyles((theme: Theme) => ({
	sidebar: {
		minWidth: drawerMiniWidth
	},
	drawerPaper: {
		width: drawerWidth,
		paddingTop: theme.spacing(12),
		overflow: "hidden"
	},
	drawerPaperClosed: {
		width: drawerMiniWidth,
		paddingTop: theme.spacing(12),
		overflow: "hidden"
	},
	sidebarItem: {
		display: "block"
	},
	sidebarItemVisited: {
		display: "block",
		backgroundColor: theme.palette.primary.main
	},
	sidebarItemBtn: {
		height: drawerMiniWidth,
		padding: 0
	},
	sidebarItemIcon: {
		minWidth: 0,
		justifyContent: "center",
		alignItems: "center",
		height: drawerMiniWidth,
		width: drawerMiniWidth
	},
	sidebarItemIconVisited: {
		minWidth: 0,
		justifyContent: "center",
		alignItems: "center",
		color: theme.palette.common.white,
		backgroundColor: theme.palette.primary.main,
		height: drawerMiniWidth,
		width: drawerMiniWidth
	},
	sidebarText: {},
	sidebarTextVisited: {
		color: theme.palette.common.black
	},
	tooltip: {
		fontSize: 16
	}
}));
