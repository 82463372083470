import { Job, JOB_STATUS, JobTracking } from "services/jobs/types";
import { appStore } from "index";
import { AppState } from "AppState";
import moment from "moment";
import { setTrackedJob, trackJob, untrackJob } from "store/jobsMonitor/actions";

const TIME_TO_MISSING = 30000; // ms to wait before reporting missing jobs

class JobsService {
	private static getJobTracking(jobId: number): JobTracking | undefined {
		const state = appStore.getState() as AppState;

		return state.jobMonitor.trackedJobList.find(
			(jobTracking: JobTracking) => jobTracking.id === jobId
		);
	}

	public static async trackJob(job: Job): Promise<JobTracking> {
		const trackingStartTimestamp = moment();

		const {
			id,
			executionInfo: { status },
			meta
		} = job;
		// console.log("dispatch trackJob", id);
		appStore.dispatch(trackJob(id));
		appStore.dispatch(
			setTrackedJob({
				id,
				status,
				meta
			})
		);

		// create new promise and return it
		return new Promise<JobTracking>((resolve, reject) => {
			const jobTracking = this.getJobTracking(id);
			// console.log("tracking job", jobId, job);

			processJobStatus(jobTracking);

			// TODO: optimize - run only when jobMonitor state is changed
			const unsubscribe = appStore.subscribe(() => {
				const job = this.getJobTracking(id);
				// console.log("checking job status", job);
				processJobStatus(job);
			});

			async function processJobStatus(jobTracking: JobTracking | undefined) {
				// console.log("processJobStatus", job?.executionInfo.status);

				if (
					!jobTracking &&
					moment()
						.subtract(TIME_TO_MISSING, "ms")
						.isBefore(trackingStartTimestamp)
				) {
					// console.info(
					// 	`Couldn't find job ${jobId}, but it's still too early to stop tracking...`
					// );
				} else if (!jobTracking) {
					console.warn(`Could not find job ${id}!`);
					reject({
						id: -1,
						executionInfo: {
							status: JOB_STATUS.MISSING
						}
					});
					unsubscribe && unsubscribe();
					appStore.dispatch(untrackJob(id));
					reject(jobTracking);
				} else {
					if (jobTracking.status === JOB_STATUS.SUCCESS) {
						unsubscribe && unsubscribe();
						appStore.dispatch(untrackJob(id));
						resolve(jobTracking);
					} else if (
						jobTracking.status === JOB_STATUS.ABORTED ||
						jobTracking.status === JOB_STATUS.FAILURE ||
						jobTracking.status === JOB_STATUS.MISSING
					) {
						// TODO: fetch full job from REST API to get error details
						console.warn(
							`Job ${id} failed with status ${jobTracking.status}`,
							jobTracking
						);
						unsubscribe && unsubscribe();
						appStore.dispatch(untrackJob(id));

						reject(jobTracking);
					}
				}
			}
		});
	}
}

export default JobsService;
