import {
	SYSTEM_STATUS_ACTION,
	BackendSetOnlineAction,
	BackendSetOfflineAction,
	BackendSetWeakAction,
	InfluxDBSetOnlineAction,
	InfluxDBSetWeakAction,
	InfluxDBSetOfflineAction,
	WsSetOnlineAction,
	WsSetOfflineAction
} from "store/systemStatus/types";

export const setBackendOnline = (): BackendSetOnlineAction => ({
	type: SYSTEM_STATUS_ACTION.BACKEND_SET_ONLINE
});

export const setBackendWeak = (): BackendSetWeakAction => ({
	type: SYSTEM_STATUS_ACTION.BACKEND_SET_WEAK
});

export const setBackendOffline = (): BackendSetOfflineAction => ({
	type: SYSTEM_STATUS_ACTION.BACKEND_SET_OFFLINE
});

export const setInfluxDBOnline = (): InfluxDBSetOnlineAction => ({
	type: SYSTEM_STATUS_ACTION.INFLUXDB_SET_ONLINE
});

export const setInfluxDBWeak = (): InfluxDBSetWeakAction => ({
	type: SYSTEM_STATUS_ACTION.INFLUXDB_SET_WEAK
});

export const setInfluxDBOffline = (): InfluxDBSetOfflineAction => ({
	type: SYSTEM_STATUS_ACTION.INFLUXDB_SET_OFFLINE
});

export const setWsOnline = (): WsSetOnlineAction => ({
	type: SYSTEM_STATUS_ACTION.WS_SET_ONLINE
});

export const setWsOffline = (): WsSetOfflineAction => ({
	type: SYSTEM_STATUS_ACTION.WS_SET_OFFLINE
});
