export interface ClusterCreateWizardState {
	isOpen: boolean;
}

export enum CLUSTER_TYPE {
	MONITORED,
	HYBRID,
	MANAGED
}

export enum CLUSTER_CREATE_WIZARD_ACTION {
	SHOW = "CLUSTER_CREATE_WIZARD/SHOW",
	HIDE = "CLUSTER_CREATE_WIZARD/HIDE",
}

export interface ClusterCreateWizardShowAction {
	type: CLUSTER_CREATE_WIZARD_ACTION.SHOW;
}

export interface ClusterCreateWizardHideAction {
	type: CLUSTER_CREATE_WIZARD_ACTION.HIDE;
}

export type ClusterCreateWizardAction =
	| ClusterCreateWizardShowAction
	| ClusterCreateWizardHideAction
