import React from "react";
import { Typography } from "@mui/material";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { JOB_STATUS } from "services/jobs/types";
import { styles } from "pages/management/node/jobs/jobStatus/styles";
import { AnimateKeyframes } from "react-simple-animate/dist";

interface LocalState {}

// default private key is used only as indicator that
interface LocalProps {
	jobStatus: JOB_STATUS;
}

type Props = LocalProps & WithStyles<typeof styles>;

class JobStatusComponent extends React.PureComponent<Props, LocalState> {
	render() {
		const { jobStatus, classes } = this.props;

		const renderJobStatus = (jobStatus: JOB_STATUS) => {
			switch (jobStatus) {
				case JOB_STATUS.NEW:
					return <Typography color="primary">NEW</Typography>;
				case JOB_STATUS.RUNNING:
					return (
						<AnimateKeyframes
							play
							iterationCount="infinite"
							duration={1.5}
							keyframes={["opacity: 0.3", "opacity: 1", "opacity: 0.3"]}
						>
							<Typography color="primary">RUNNING</Typography>
						</AnimateKeyframes>
					);
				case JOB_STATUS.SUCCESS:
					return (
						<Typography className={classes.statusSuccess}>SUCCESS</Typography>
					);
				case JOB_STATUS.FAILURE:
					return <Typography color="error">FAILURE</Typography>;
				case JOB_STATUS.ABORTED:
					return <Typography color="secondary">ABORTED</Typography>;
				case JOB_STATUS.MISSING:
					return <Typography color="error">MISSING</Typography>;

				default:
					return <Typography>UNKNOWN</Typography>;
			}
		};

		return <>{renderJobStatus(jobStatus)}</>;
	}
}

export default withStyles(styles, {})(JobStatusComponent);
