import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';

export const styles = (theme: Theme) =>
	createStyles({
		root: {
			width: "100%"
		},
		publicKey: {
			fontSize: "0.9em",
			wordBreak: "break-all",
			color: "white",
			backgroundColor: "black",
			fontFamily: "monospace",
			padding: theme.spacing(1)
		}
	});
