import axios from "axios";
import { ISizeExt } from "components/form/digitalOceanConfig/types";
import { createApiClient } from "dots-wrapper/dist";
import { IAccount } from "dots-wrapper/dist/account";
import { IRegion } from "dots-wrapper/dist/region";

const URL = "https://api.digitalocean.com/v2";

export default class DigitalOceanAPI {
	static async verifyToken(
		token: string,
		abortController?: AbortController
	): Promise<IAccount> {
		return new Promise<IAccount>(async (resolve, reject) => {
			try {
				const { data } = await axios.get(`${URL}/account`, {
					headers: {
						authorization: `Bearer ${token}}`
					},
					signal: abortController?.signal
				});
				resolve(data.account);
			} catch (error: any) {
				reject(error);
			}
		});
	}

	static async fetchRegions(token: string): Promise<IRegion[]> {
		const dots = createApiClient({ token });

		const {
			data: { regions }
		} = await dots.region.listRegions({ page: 1, per_page: 500 });

		return regions;
	}

	static async fetchAllSizes(token: string): Promise<ISizeExt[]> {
		const dots = createApiClient({ token });

		const {
			data: { sizes }
		} = await dots.size.listSizes({ page: 1, per_page: 500 });

		return sizes as ISizeExt[];
	}
}
