import { AppState } from "AppState";
import { takeLatest } from "redux-saga/effects";
import JobsApi from "services/api/JobsApi";
import { JobTracking } from "services/jobs/types";
import { WSHandler } from "services/webSocketHandler/WSHandler.ws";
import {
	bootDone,
	setRunningJobs,
	consolidateTrackedJobs
} from "store/jobsMonitor/actions";
import { METRICS_ACTION } from "store/metricsStore/storeTypes";
import { SYSTEM_STATUS_ACTION } from "store/systemStatus/types";
import { call, delay, put, select } from "typed-redux-saga";
import { SECURE_PRELOADER_ACTION } from "../../components/preloader/types";
import { JOB_MONITOR_ACTION } from "./types";
import { jobsToTrackSelector } from "./selectors";

function* keepaliveWs() {
	// reconnect to WS if disconnected
	while (true) {
		try {
			// console.log("ws connection keepalive tick");
			if (!WSHandler.isConnected()) {
				// console.log("connecting to ws");
				yield WSHandler.connect();
			}
		} finally {
			yield delay(10000);
		}
	}
}

function* startWS() {
	yield WSHandler.connect();
	const runningJobs = yield* call(JobsApi.fetchRunningJobList);

	const runningJobsTracking: JobTracking[] = runningJobs.map((job: any) => ({
		id: job.id,
		status: job.executionInfo.status,
		meta: job.meta
	}));
	yield put(setRunningJobs(runningJobsTracking));
	yield put(bootDone());
}

function stopWS() {
	WSHandler.disconnect();
}

function* runConsolidation() {
	// jobs to track
	const jobsToTrack = yield* select(jobsToTrackSelector);

	// fetch jobs from api
	const trackedJobs = yield* call(JobsApi.fetchFilteredJobList, jobsToTrack);

	yield put(consolidateTrackedJobs(trackedJobs));
}

function* JobSideEffects() {
	yield takeLatest(SECURE_PRELOADER_ACTION.PRELOAD, startWS);
	yield takeLatest(SECURE_PRELOADER_ACTION.PRELOAD_SUCCESS, keepaliveWs);
	yield takeLatest(METRICS_ACTION.STOP_MONITOR, stopWS);
	yield takeLatest(METRICS_ACTION.START_MONITOR, startWS);
	yield takeLatest(METRICS_ACTION.START_MONITOR, runConsolidation);
	yield takeLatest(SYSTEM_STATUS_ACTION.BACKEND_SET_ONLINE, startWS);
	yield takeLatest(JOB_MONITOR_ACTION.TRACK, runConsolidation);
}

export const monitoredJobsSelector = (state: AppState): number[] =>
	state.jobMonitor.jobsToTrack;

export default JobSideEffects;
