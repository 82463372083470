// MUI
import { Box } from "@mui/material";
import useStyles from "components/JobErrorDetails/styles";
// packages
import yaml from "react-syntax-highlighter/dist/esm/languages/hljs/yaml";
// theme for SyntaxHighlighter, try out different themes and languages
// here: https://react-syntax-highlighter.github.io/react-syntax-highlighter/demo/
import { vs2015 } from "react-syntax-highlighter/dist/esm/styles/hljs";
import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/light";

SyntaxHighlighter.registerLanguage("yaml", yaml);

export default function JobErrorDetails(props: { formattedYaml: string }) {
	const classes = useStyles();

	const formattedYaml = props.formattedYaml;

	return (
		<div>
			<Box className={classes.container}>
				<SyntaxHighlighter
					language="yaml"
					wrapLongLines
					style={vs2015}
					customStyle={{
						marginTop: 8,
						marginBottom: 0,
						paddingTop: 16,
						lineHeight: 1.5
					}}
					// showLineNumbers
				>
					{formattedYaml}
				</SyntaxHighlighter>
			</Box>
		</div>
	);
}
