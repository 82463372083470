import { Host } from "pages/management/host/types";
import { Node } from "pages/management/node/types";
import { JobTracking } from "services/jobs/types";
import { Cluster } from "pages/management/cluster/types";

export interface DeploymentJob {
	cluster: Cluster;
	host: Host;
	node: Node;
	hostJob?: JobTracking;
	nodeJob?: JobTracking;
	nodeStartJob?: JobTracking;
	isDone: boolean;
	startNodeAfterDeployment?: boolean;
	log: any[];
	steps: DeploymentStep[];
}

export interface DeploymentStep {
	type: DEPLOYMENT_STEP_TYPE;
	status: DEPLOYMENT_STEP_STATUS;
	errorMessage?: string;
}

export enum DEPLOYMENT_TYPE {
	MONITORED,
	HYBRID,
	MANAGED
}

export enum DEPLOYMENT_STEP_TYPE {
	HOST_DEPLOY,
	NODE_INSTALL,
	NODE_START
}

export enum DEPLOYMENT_STEP_STATUS {
	CANCELLED = -1,
	PENDING,
	RUNNING,
	FAILED,
	DONE
}

export interface NodeCreateWizardState {
	isOpen: boolean;
	cluster: Cluster;
}

export enum NODE_CREATE_WIZARD_ACTION {
	SHOW = "NODE_CREATE_WIZARD/SHOW",
	HIDE = "NODE_CREATE_WIZARD/HIDE"
}

export interface NodeCreateWizardShowAction {
	type: NODE_CREATE_WIZARD_ACTION.SHOW;
	payload: {
		cluster: Cluster;
	};
}

export interface NodeCreateWizardHideAction {
	type: NODE_CREATE_WIZARD_ACTION.HIDE;
}

export type NodeCreateWizardAction =
	| NodeCreateWizardShowAction
	| NodeCreateWizardHideAction;
