// @flow
import { Grid, Typography } from "@mui/material";
import { ISizeExt } from "components/form/digitalOceanConfig/types";

interface Props {
	size: ISizeExt;
	disabled: boolean;
}

export function SizeOptionComponent(props: Props) {
	const { size, disabled } = props;
	return (
		<Grid container direction="column" spacing={0}>
			<Grid item>
				<Typography variant="subtitle1">
					{size.slug} {disabled ? " (Not available)" : ""}
				</Typography>
			</Grid>
			<Grid item>
				<Typography style={{ opacity: 0.67 }} variant="subtitle2">
					{size.vcpus} CPU, {size.memory / 1024} GB RAM, {size.disk} GB Disk,{" "}
					{size.price_monthly} $/mo
				</Typography>
			</Grid>
		</Grid>
	);
}
