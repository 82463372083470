import { Theme } from '@mui/material'

import createStyles from '@mui/styles/createStyles';

export const styles = (theme: Theme) =>
	createStyles({
        listItem: {
            paddingLeft: 8,
            paddingRight: 0,
        },
		consoleIcon: {
			minWidth: 36, // TODO: remove this, since it isn't displayed BKBK
			[theme.breakpoints.up('sm')]: {
				minWidth: 48
			}
		},
        collapsibleItem: {
            paddingLeft: 8,
			[theme.breakpoints.up('md')]: {
                paddingLeft: 56 // listItem.paddingLeft + consoleIcon.minWidth
			}
        },
		openCloseIcon: {
			width: 34,
			height: 34,
			minWidth: 34
		},
		buttonContainer: {
			display: "flex", justifyContent: "space-between"
		}
	});
