import moment from "moment";
import { INanoDate, toNanoDate } from "influx";
import { Theme } from "@mui/material/styles";

export class Utils {
	static generateRandomStringArray(
		arrayLength: number,
		stringLength: number
	): string[] {
		return [...Array(arrayLength)].map(() =>
			[...Array(~~stringLength)]
				.map(() => String.fromCharCode(Math.random() * (123 - 97) + 97))
				.join("")
		);
	}

	static toNanoDate(date: Date): INanoDate {
		return toNanoDate(`${moment(date).valueOf()}000000`);
	}

	static mergeStyles(...styles: any) {
		return function CombineStyles(theme: Theme) {
			const outStyles = styles.map((arg: any) => {
				// Apply the "theme" object for style functions.
				if (typeof arg === "function") {
					return arg(theme);
				}
				// Objects need no change.
				return arg;
			});

			return outStyles.reduce((acc: any, val: any) => Object.assign(acc, val));
		};
	}
}
