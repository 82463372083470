import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
	wrapper: {
		maxWidth: 600,
		margin: "auto",
		marginBottom: theme.spacing(4)
	},
	fieldWrapper: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2)
	},
	primaryTitle: {
		marginTop: theme.spacing(10),
		marginBottom: theme.spacing(3),
		textAlign: "center"
	},
	secondaryTitle: {
		marginTop: theme.spacing(6),
		marginBottom: theme.spacing(1)
	},
	container: {
		padding: theme.spacing(3),
		[theme.breakpoints.up("sm")]: {
			paddingLeft: theme.spacing(6),
			paddingRight: theme.spacing(6),
			paddingBottom: theme.spacing(6)
		}
	}
}));

export default useStyles;
