import React from "react";
// redux
import { connect } from "react-redux";
import { AppState } from "AppState";
import { versionInfoSelector } from "store/version/selectors";
import { showSnackbar } from "components/snackbar/actionCreators";
import { SnackbarActionPayload } from "components/snackbar/types";
// MUI
import { Typography } from "@mui/material";
import { withStyles, WithStyles } from "@mui/styles";
// components
import { styles } from "components/appInfo/styles";

interface LocalProps {}

interface ReduxStateProps {
	versionInfo: string;
}

interface DispatchProps {
	showSnackbar: (snackbar: SnackbarActionPayload) => void;
}

type Props = LocalProps &
	ReduxStateProps &
	DispatchProps &
	WithStyles<typeof styles>;

class AppInfo extends React.Component<Props> {
	render() {
		const { versionInfo } = this.props;

		return (
			<Typography
				data-testid="public-version"
				variant="caption"
				className={this.props.classes.appInfo}
				title="Click to copy to clipboard"
				onClick={() => {
					navigator.clipboard.writeText(versionInfo).then(
						() => {
							this.props.showSnackbar({
								msg: "Version info copied to clipboard!"
							});
						},
						(err: Error) => {
							console.error("Version copy to clipboard error:", err);
							this.props.showSnackbar({
								msg: "Couldn't copy version info to clipboard"
							});
						}
					);
				}}
			>
				Version:{versionInfo}
			</Typography>
		);
	}
}

// REDUX MAPPINGS
const mapStateToProps = (state: AppState) => {
	return {
		versionInfo: versionInfoSelector(state)
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		showSnackbar: (snackbar: SnackbarActionPayload) => {
			dispatch(showSnackbar(snackbar));
		}
	};
};

export default withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(AppInfo)
);
