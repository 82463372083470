// MUI
import { Divider, Drawer, List, Theme, useMediaQuery } from "@mui/material";
// components
import SidebarItem from "components/sidebar/SidebarItem";
// css
import { useStyles } from "components/sidebar/styles";

interface PropsWindow {
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	window?: () => Window;
	handleDrawerToggle: (isDesktop: boolean) => void;
}

interface LocalProps {
	isDrawerOpen: boolean;
	mobileOpen: boolean;
	handleCloseMobileSidebar: () => void;
}

type Props = PropsWindow & LocalProps;

function Sidebar(props: Props) {
	const classes = useStyles();

	const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

	const {
		isDrawerOpen,
		mobileOpen,
		handleDrawerToggle,
		handleCloseMobileSidebar
	} = props;

	const drawerVariant = isDesktop ? "permanent" : "temporary";
	const drawerOpen = isDesktop ? isDrawerOpen : mobileOpen;

	return (
		<nav>
			<Drawer
				variant={drawerVariant}
				open={drawerOpen}
				onClose={handleDrawerToggle}
				classes={{
					paper: isDrawerOpen ? classes.drawerPaper : classes.drawerPaperClosed
				}}
			>
				{/* Logo was here but it was hidden below top appbar */}
				{/* <div className="logo-container">
					<img
						className="galera-cluster-logo"
						src={gc_logo}
						alt="Galera Cluster Manager"
					/>
				</div> */}

				<List className={classes.sidebar}>
					<SidebarItem
						title="Management"
						isDrawerOpen={isDrawerOpen}
						handleCloseMobileSidebar={handleCloseMobileSidebar}
					/>
					<SidebarItem
						title="Users"
						isDrawerOpen={isDrawerOpen}
						handleCloseMobileSidebar={handleCloseMobileSidebar}
					/>
					<SidebarItem
						title="System Log"
						isDrawerOpen={isDrawerOpen}
						handleCloseMobileSidebar={handleCloseMobileSidebar}
					/>
					<SidebarItem
						title="About"
						isDrawerOpen={isDrawerOpen}
						handleCloseMobileSidebar={handleCloseMobileSidebar}
					/>

					<Divider />

					<div id="LogOutButton">
						<SidebarItem
							title="Logout"
							isDrawerOpen={isDrawerOpen}
							logoutBtn={true}
						/>
					</div>
				</List>
			</Drawer>
		</nav>
	);
}

export default Sidebar;
