import { Job, JobTracking } from "services/jobs/types";

export interface JobMonitorState {
	jobsToTrack: number[];
	trackedJobList: JobTracking[];
	runningJobList: JobTracking[];
	jobsDoneDuringBoot: number[];
	isBootDone: boolean;
	isWsConnected: boolean;
}

export enum JOB_MONITOR_ACTION {
	BOOT_DONE = "JOB_MONITOR/BOOT_DONE",
	SET_RUNNING_JOBS = "JOB_MONITOR/SET_RUNNING_JOBS",
	ADD_RUNNING_JOB = "JOB_MONITOR/ADD_RUNNING_JOB",
	REMOVE_RUNNING_JOB = "JOB_MONITOR/REMOVE_RUNNING_JOB",

	TRACK = "JOB_MONITOR/TRACK",
	UNTRACK = "JOB_MONITOR/UNTRACK",

	CONSOLIDATE_TRACKED_JOBS = "JOB_MONITOR/CONSOLIDATE_TRACKED_JOBS",
	SET_TRACKED_JOB = "JOB_MONITOR/SET_TRACKED_JOB",
	REMOVE_TRACKED_JOB = "JOB_MONITOR/REMOVE_TRACKED_JOB"
}

export interface BootDone {
	type: JOB_MONITOR_ACTION.BOOT_DONE;
}

export interface SetRunningJobs {
	type: JOB_MONITOR_ACTION.SET_RUNNING_JOBS;
	payload: {
		jobs: JobTracking[];
	};
}

export interface AddRunningJob {
	type: JOB_MONITOR_ACTION.ADD_RUNNING_JOB;
	payload: {
		job: JobTracking;
	};
}

export interface RemoveRunningJob {
	type: JOB_MONITOR_ACTION.REMOVE_RUNNING_JOB;
	payload: {
		jobId: number;
	};
}

export interface TrackJob {
	type: JOB_MONITOR_ACTION.TRACK;
	payload: {
		jobId: number;
	};
}

export interface UntrackJob {
	type: JOB_MONITOR_ACTION.UNTRACK;
	payload: {
		jobId: number;
	};
}

export interface ConsolidateTrackedJobs {
	type: JOB_MONITOR_ACTION.CONSOLIDATE_TRACKED_JOBS;
	payload: {
		jobs: Job[];
	};
}

export interface SetTrackedJob {
	type: JOB_MONITOR_ACTION.SET_TRACKED_JOB;
	payload: {
		job: JobTracking;
	};
}

export interface RemoveTrackedJob {
	type: JOB_MONITOR_ACTION.REMOVE_TRACKED_JOB;
	payload: {
		jobId: number;
	};
}

export type JobMonitorAction =
	| BootDone
	| SetRunningJobs
	| AddRunningJob
	| RemoveRunningJob
	| TrackJob
	| UntrackJob
	| ConsolidateTrackedJobs
	| SetTrackedJob
	| RemoveTrackedJob;
