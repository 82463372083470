import { AppState } from "AppState";
import axios, { AxiosError, AxiosResponse } from "axios";
import { Store } from "redux";
import AppEnvironment from "services/appEnvironment";
import { logoutSuccess } from "store/auth/actions";
import {
	setBackendOffline,
	setBackendOnline
} from "store/systemStatus/actions";
import { CONNECTION_STATUS } from "store/systemStatus/types";

export const initializeInterceptors = (store: Store): void => {
	initialize401ResponseHandler(store);
	initializeBackendMonitor(store);
};

const initializeBackendMonitor = (store: Store) => {
	axios.interceptors.response.use(
		(response: AxiosResponse): AxiosResponse => {
			if (response.config?.url?.startsWith(AppEnvironment.getApiAddress())) {
				const state = store.getState() as AppState;
				const isOnline =
					state.systemStatus.backendConnectionStatus ===
					CONNECTION_STATUS.ONLINE;

				if (!isOnline) {
					store.dispatch(setBackendOnline());
				}
			}

			return response;
		},
		async (error: AxiosError): Promise<any> => {
			if (
				error.config?.url?.startsWith(AppEnvironment.getApiAddress()) &&
				error.message === "Network Error"
			) {
				const state = store.getState() as AppState;
				const isOffline =
					state.systemStatus.backendConnectionStatus ===
					CONNECTION_STATUS.OFFLINE;

				if (!isOffline) {
					store.dispatch(setBackendOffline());
				}
			}

			return Promise.reject(error);
		}
	);
};

const initialize401ResponseHandler = (store: Store) => {
	axios.interceptors.response.use(
		(response: AxiosResponse): AxiosResponse => response,
		async (error: AxiosError): Promise<any> => {
			const shouldSkip =
				error.config?.url?.includes("/login") ||
				!error.config?.url?.startsWith(AppEnvironment.getApiAddress());

			if (error.response && error.response.status === 401 && !shouldSkip) {
				handleUnauthorized(store);
				throw error;
			} else {
				return Promise.reject(error);
			}
		}
	);
};

const handleUnauthorized = (store: Store) => {
	store.dispatch(logoutSuccess());
};
