import { authReducer } from "store/auth/reducer";
import { dashboardReducer } from "store/dashboard/reducer";
import { combineReducers } from "redux";
import snackbarReducer from "components/snackbar/reducer";
import { userListReducer } from "pages/users/reducer";
import { clusterListReducer } from "store/cluster/reducer";
import { nodeListReducer } from "store/node/reducer";
import { hostListReducer } from "store/host/reducer";
import { jobMonitorReducer } from "store/jobsMonitor/reducer";
import { clusterCreateWizardReducer } from "pages/management/cluster/clusterCreateWizard/reducer";
import { nodeCreateWizardReducer } from "pages/management/node/nodeDeploymentDialog/reducer";
import { preloaderReducer } from "store/preloader/reducer";
import { userCreateWizardReducer } from "pages/users/userCreateWizard/reducer";
import { metricsReducer } from "store/metricsStore/reducer";
import { systemStatusReducer } from "store/systemStatus/reducer";
import { versionReducer } from "store/version/reducer";

export const rootReducer = combineReducers({
	preloader: preloaderReducer,
	auth: authReducer,
	dashboard: dashboardReducer,
	snackbar: snackbarReducer,
	userList: userListReducer,
	clusterList: clusterListReducer,
	nodeList: nodeListReducer,
	hostList: hostListReducer,
	jobMonitor: jobMonitorReducer,
	clusterCreateWizard: clusterCreateWizardReducer,
	nodeCreateWizard: nodeCreateWizardReducer,
	userCreateWizard: userCreateWizardReducer,
	metrics: metricsReducer,
	systemStatus: systemStatusReducer,
	versionInfo: versionReducer
});

export type AppState = ReturnType<typeof rootReducer>;
