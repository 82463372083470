import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';

export const styles = (theme: Theme) =>
	createStyles({
		destructiveConfirm: {},
		holdToConfirmButton: {
			color: theme.palette.error.main,
			background: `linear-gradient(to left, transparent 50%, ${theme.palette.error.dark} 50%) right`,
			backgroundSize: "200%",
			transition: "2s linear",
			"&:active&:hover": {
				backgroundPosition: "left",
				color: "white"
			}
		}
	});
