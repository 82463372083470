import {
	Cluster,
	DEPLOYMENT_STATUS
} from "pages/management/cluster/types";
import {
	HOST_TYPE_SPECIFIC_AWS_DEFAULT_CONFIG,
	HOST_TYPE_SPECIFIC_DIGITAL_OCEAN_DEFAULT_CONFIG
} from "pages/management/host/const";
import {
	HOST_STATE,
	HOST_STATUS,
	HOST_SYSTEM,
	HOST_TYPE
} from "pages/management/host/types";

class HostUtils {
	static getHostSystemEnum = (value: string): HOST_SYSTEM => {
		switch (value) {
			case HOST_SYSTEM.CENTOS_7:
				return HOST_SYSTEM.CENTOS_7;
			case HOST_SYSTEM.CENTOS_8:
				return HOST_SYSTEM.CENTOS_8;
			case HOST_SYSTEM.REDHAT_8:
				return HOST_SYSTEM.REDHAT_8;
			case HOST_SYSTEM.UBUNTU_18:
				return HOST_SYSTEM.UBUNTU_18;
			case HOST_SYSTEM.UBUNTU_20:
				return HOST_SYSTEM.UBUNTU_20;
			case HOST_SYSTEM.DEBIAN_10:
				return HOST_SYSTEM.DEBIAN_10;
			case HOST_SYSTEM.DEBIAN_11:
				return HOST_SYSTEM.DEBIAN_11;
			default:
				console.warn(
					`Unrecognised host system: ${value}. Returning default value instead.`
				);
				return HOST_SYSTEM.UBUNTU_20;
		}
	};

	static getHostTypeEnum = (value: string): HOST_TYPE => {
		switch (value) {
			// case HOST_TYPE.LOCAL_LXD:
			// 	return HOST_TYPE.LOCAL_LXD;
			case HOST_TYPE.EC2:
				return HOST_TYPE.EC2;
			case HOST_TYPE.UNMANAGED:
				return HOST_TYPE.UNMANAGED;
			default:
				return HOST_TYPE.EC2;
		}
	};

	static getHostTypeSpecificDefaults = (
		hostType: HOST_TYPE,
		cluster?: Cluster
	): any => {
		switch (hostType) {
			case HOST_TYPE.EC2:
				return (
					cluster?.sharedConfig.host.hostTypeSpecific ||
					HOST_TYPE_SPECIFIC_AWS_DEFAULT_CONFIG
				);
			case HOST_TYPE.DIGITAL_OCEAN:
				return (
					cluster?.sharedConfig.host.hostTypeSpecific ||
					HOST_TYPE_SPECIFIC_DIGITAL_OCEAN_DEFAULT_CONFIG
				);
			case HOST_TYPE.UNMANAGED:
				return {};
			// case HOST_TYPE.LOCAL_LXD:
			default:
				return undefined;
		}
	};

	static getHostState(deploymentStatus?: string): HOST_STATE {
		switch (deploymentStatus) {
			case "pending":
				return DEPLOYMENT_STATUS.PENDING;
			case "error":
				return DEPLOYMENT_STATUS.FAILURE;
			case "ok":
				return DEPLOYMENT_STATUS.SUCCESS;

			default:
				console.warn("Unknown host status:", deploymentStatus);
				return HOST_STATUS.UNKNOWN;
		}
	}
}

export default HostUtils;
