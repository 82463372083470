// MUI
import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	Grid,
	Step,
	StepLabel,
	Stepper,
	TextField,
	Tooltip
} from "@mui/material";
import { withStyles, WithStyles, WithTheme } from "@mui/styles";
// redux
import { AppState } from "AppState";

import { AxiosError } from "axios";
import { clusterListFetchRequested } from "store/cluster/actions";
import { CLUSTER_TYPE } from "pages/management/cluster/clusterCreateWizard/types";
import ClusterUtils from "pages/management/cluster/utils";
import { hostListFetchRequested } from "store/host/actions";
import { Host, HOST_TYPE } from "pages/management/host/types";
import { nodeListFetchRequested } from "store/node/actions";
import { nodeCreateWizardHide } from "pages/management/node/nodeDeploymentDialog/actions";
import NodeDeploymentMonitorComponent from "pages/management/node/nodeDeploymentDialog/nodeDeploymentMonitor/NodeDeploymentMonitorComponent";
import {
	DEPLOYMENT_STEP_STATUS,
	DEPLOYMENT_STEP_TYPE,
	DEPLOYMENT_TYPE,
	DeploymentJob,
	DeploymentStep,
	NodeCreateWizardState
} from "pages/management/node/nodeDeploymentDialog/types";
import NodeFormComponent from "pages/management/node/nodeForm/NodeFormComponent";
import { Node } from "pages/management/node/types";
import ConnectionTestComponent from "components/connectionTestDialog/ConnectionTestComponent";
import { CONNECTION_TEST_STATUS } from "components/connectionTestDialog/types";
import { GMDialogService } from "components/dialog/DialogService";
import { cloneDeep } from "lodash";
import HostsAPI from "services/api/HostsAPI";
import NodesAPI from "services/api/NodesAPI";
import { GMDErrorData } from "services/api/types";
import JobsService from "services/jobs/jobsService";
import { Job, JobTracking } from "services/jobs/types";
import { Utils } from "utils/utils";
import React, { ChangeEvent } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, StaticContext, withRouter } from "react-router";
import { checkIfDesktop } from "utils/checkIfDesktop";
import { styles } from "pages/management/node/nodeDeploymentDialog/styles";
import { INPUT_VARIANT } from "components/form/const";
import FormWrapper from "components/form/wrappers/FormWrapper";

// component local state interface
interface LocalState {
	deploymentType: DEPLOYMENT_TYPE;
	nodesToDeploy: number;
	activeStep: number;
	node: Node;
	host: Host;
	isDeploymentDone: boolean;
	startNodeAfterDeployment: boolean;
	deploymentJobs: DeploymentJob[];
	sshTestStatus: CONNECTION_TEST_STATUS;
	dbTestStatus: CONNECTION_TEST_STATUS;
	formIsChanged: boolean;
}

// PROPS
interface ReduxStateProps {
	nodeCreateWizard: NodeCreateWizardState;
}

interface ReduxDispatchProps {
	nodeCreateWizardHide: () => void;
	nodeListFetchRequested: (clusterID: number) => void;
	hostListFetchRequested: (clusterID: number) => void;
	clusterListFetchRequested: () => void;
}

type Props = ReduxStateProps &
	ReduxDispatchProps &
	WithStyles<typeof styles> &
	WithTheme &
	RouteComponentProps<any, StaticContext, any>;

class NodeDeploymentDialogComponent extends React.Component<Props, LocalState> {
	constructor(props: Props) {
		super(props);

		this.state = this.getInitialState(props);
	}

	getInitialState = (props: Props) => {
		const { cluster } = props.nodeCreateWizard;
		const clusterType = ClusterUtils.getClusterType(cluster);

		console.log("getInitialState", cluster);

		const nodeDefaults: Node = {
			name: "",
			hostID: -1,
			clusterID: cluster.id || -1,
			unmanaged: cluster.sharedConfig.host.type === HOST_TYPE.UNMANAGED,
			// dbEngine: NODE_DB_ENGINE.MARIADB_10_6,
			// settings: {
			// 	logs: {
			// 		generalLog: {
			// 			enabled: false
			// 		}
			// 	}
			// },
			...cluster.sharedConfig.node
		};

		const hostDefaults: Host = {
			name: "",
			clusterID: cluster.id || -1,
			segment: 0,
			// type: HOST_TYPE.UNMANAGED,
			// system: HOST_SYSTEM.UBUNTU_20,
			// firewallRules: [
			// 	{
			// 		description: "first firewall rule",
			// 		port: 3306,
			// 		protocol: "tcp",
			// 		ranges: ""
			// 	}
			// ],
			...cluster.sharedConfig.host,
			privateKey: "",
			authorizedKeys: [],
			ssh: {
				address: "",
				port: "22"
			}
		};

		const initialState: LocalState = {
			sshTestStatus: CONNECTION_TEST_STATUS.READY,
			dbTestStatus: CONNECTION_TEST_STATUS.READY,
			deploymentType: DEPLOYMENT_TYPE.MONITORED, // todo: deployment type seems redundant with cluster type
			nodesToDeploy: 1,
			startNodeAfterDeployment: clusterType !== CLUSTER_TYPE.MONITORED,
			activeStep: 0,
			isDeploymentDone: false,
			node: nodeDefaults,
			host: hostDefaults,
			deploymentJobs: [
				// {
				// 	host: hostDefaults,
				// 	node: { ...nodeDefaults, name: "node01" },
				// 	cluster: DEFAULT_CLUSTER,
				// 	isDone: false,
				// 	log: [],
				// 	steps: [
				// 		{
				// 			type: DEPLOYMENT_STEP_TYPE.HOST_DEPLOY,
				// 			status: DEPLOYMENT_STEP_STATUS.DONE
				// 		},
				// 		{
				// 			type: DEPLOYMENT_STEP_TYPE.NODE_INSTALL,
				// 			status: DEPLOYMENT_STEP_STATUS.RUNNING
				// 		},
				// 		{
				// 			type: DEPLOYMENT_STEP_TYPE.NODE_START,
				// 			status: DEPLOYMENT_STEP_STATUS.PENDING
				// 		}
				// 	]
				// }
			],
			formIsChanged: false // if form is modified, ask user if they want to abandon changes they made
		};

		// console.log("initial state", initialState);
		return cloneDeep(initialState);
	};

	deploy(host: Host, node: Node): void {
		function generateSteps(
			startNodeAfterDeployment: boolean
		): DeploymentStep[] {
			let steps: DeploymentStep[] = [
				{
					type: DEPLOYMENT_STEP_TYPE.HOST_DEPLOY,
					status: DEPLOYMENT_STEP_STATUS.PENDING
				},
				{
					type: DEPLOYMENT_STEP_TYPE.NODE_INSTALL,
					status: DEPLOYMENT_STEP_STATUS.PENDING
				}
			];

			if (startNodeAfterDeployment) {
				steps.push({
					type: DEPLOYMENT_STEP_TYPE.NODE_START,
					status: DEPLOYMENT_STEP_STATUS.PENDING
				});
			}

			return steps;
		}

		const { cluster } = this.props.nodeCreateWizard;

		this.setState((state: LocalState) => ({
			deploymentJobs: [
				...state.deploymentJobs,
				{
					cluster,
					host,
					node,
					startNodeAfterDeployment: this.state.startNodeAfterDeployment,
					isDone: false,
					log: [],
					steps: generateSteps(this.state.startNodeAfterDeployment)
				}
			]
		}));

		console.log("create host", host);
		HostsAPI.create(host).then(
			(response: { job: Job; host: Host }) => {
				console.log("host create job started", response);

				const { job, host } = response;

				node = {
					...node,
					hostID: host.id || -1
				};

				this.setState((state: LocalState) => ({
					deploymentJobs: state.deploymentJobs.map(
						(deploymentJob: DeploymentJob) => {
							if (host.name === deploymentJob.host.name) {
								deploymentJob.host = host;
								deploymentJob.hostJob = {
									id: job.id,
									status: job.executionInfo.status,
									meta: job.meta
								};
								deploymentJob.steps[0].status = DEPLOYMENT_STEP_STATUS.RUNNING;
							}
							return deploymentJob;
						}
					)
				}));

				JobsService.trackJob(job).then(
					(jobTracking: JobTracking) => {
						console.log("Host installation job done!", host.name);

						this.setState((state: LocalState) => ({
							deploymentJobs: state.deploymentJobs.map(
								(deploymentJob: DeploymentJob) => {
									if (host.name === deploymentJob.host.name) {
										deploymentJob = {
											...deploymentJob,
											hostJob: jobTracking
										};
										deploymentJob.steps[0].status = DEPLOYMENT_STEP_STATUS.DONE;
										deploymentJob.steps[1].status =
											DEPLOYMENT_STEP_STATUS.RUNNING;
									}
									return deploymentJob;
								}
							)
						}));

						setTimeout(
							() => {
								this.installNode(node);
							},
							host.type === HOST_TYPE.DIGITAL_OCEAN ? 120000 : 0 // temporary solution - need to delay install to give host time to initialize
						);
						// this.props.clusterListFetchRequested();
						// this.props.hostListFetchRequested(host.clusterID);
					},
					(job: JobTracking) => {
						// console.log("job failed", host.name, job.executionInfo);
						this.setState((state: LocalState) => ({
							deploymentJobs: state.deploymentJobs.map(
								(deploymentJob: DeploymentJob) => {
									if (host.name === deploymentJob.host.name) {
										deploymentJob = {
											...deploymentJob,
											hostJob: {
												id: job.id,
												status: job.status,
												meta: job.meta
											}
											// errorMessage: `Host ${host.name} deployment failed. Check logs.`
										};
										deploymentJob.steps[0].status =
											DEPLOYMENT_STEP_STATUS.FAILED;
										deploymentJob.steps[0].errorMessage =
											"Host deployment failed. Please check logs";
										deploymentJob.isDone = true;
									}
									return deploymentJob;
								}
							)
						}));
						// this.unsubscribeFromLogs();
						this.props.hostListFetchRequested(host.clusterID);
						this.props.clusterListFetchRequested();
					}
				);
			},
			(err: any) => {
				console.log("host deploy error", err, err.message, err.response);
				this.setState((state: LocalState) => ({
					deploymentJobs: state.deploymentJobs.map(
						(deploymentJob: DeploymentJob) => {
							if (host.name === deploymentJob.host.name) {
								deploymentJob.steps[0].status = DEPLOYMENT_STEP_STATUS.FAILED;
								deploymentJob.steps[0].errorMessage = err.message;
								deploymentJob.isDone = true;
							}
							return deploymentJob;
						}
					)
				}));
			}
		);
	}

	installNode(node: Node): void {
		NodesAPI.create(node).then(
			(response: { job: Job; node: Node }) => {
				const { job, node } = response;
				console.log("node create job started", node, job);

				this.setState((state: LocalState) => ({
					deploymentJobs: state.deploymentJobs.map(
						(deploymentJob: DeploymentJob) => {
							if (node.name === deploymentJob.node.name) {
								deploymentJob = {
									...deploymentJob,
									nodeJob: {
										id: job.id,
										status: job.executionInfo.status,
										meta: job.meta
									}
								};
								deploymentJob.steps[1].status = DEPLOYMENT_STEP_STATUS.RUNNING;
							}
							return deploymentJob;
						}
					)
				}));

				JobsService.trackJob(job).then(
					() => {
						console.log("Node deployment job done!", node.name);
						this.setState((state: LocalState) => ({
							deploymentJobs: state.deploymentJobs.map(
								(deploymentJob: DeploymentJob) => {
									if (node.name === deploymentJob.node.name) {
										deploymentJob = {
											...deploymentJob,
											// nodeJob: job, TODO
											isDone: !this.state.startNodeAfterDeployment
										};
										deploymentJob.steps[1].status = DEPLOYMENT_STEP_STATUS.DONE;
									}
									return deploymentJob;
								}
							)
						}));

						// this.unsubscribeFromLogs();
						this.props.clusterListFetchRequested();
						this.props.nodeListFetchRequested(node.clusterID);
						this.props.hostListFetchRequested(node.clusterID);

						if (this.state.startNodeAfterDeployment) {
							this.startNode(node);
						} else {
							this.setState((state: LocalState) => ({
								isDeploymentDone: state.deploymentJobs.every(
									(deploymentJob: DeploymentJob) => deploymentJob.isDone
								)
							}));
						}
					},
					(job: JobTracking) => {
						// console.log("job failed", node.name, job);
						this.setState((state: LocalState) => ({
							deploymentJobs: state.deploymentJobs.map(
								(deploymentJob: DeploymentJob) => {
									if (node.name === deploymentJob.node.name) {
										deploymentJob = {
											...deploymentJob,
											nodeJob: job
										};
										deploymentJob.steps[1].status =
											DEPLOYMENT_STEP_STATUS.FAILED;
										deploymentJob.steps[1].errorMessage =
											"Node installation failed. Check logs for more info";
										deploymentJob.isDone = true;
									}
									return deploymentJob;
								}
							)
						}));
						// this.unsubscribeFromLogs();
						this.props.clusterListFetchRequested();
						this.props.nodeListFetchRequested(node.clusterID);
					}
				);
			},
			(err: any) => {
				// console.log("node install error", err, err.message, err.response);
				this.setState((state: LocalState) => ({
					deploymentJobs: state.deploymentJobs.map(
						(deploymentJob: DeploymentJob) => {
							if (node.name === deploymentJob.node.name) {
								deploymentJob.steps[1].status = DEPLOYMENT_STEP_STATUS.FAILED;
								deploymentJob.steps[1].errorMessage = err.message;
								deploymentJob.isDone = true;
							}
							return deploymentJob;
						}
					)
				}));
			}
		);
	}

	startNode(node: Node) {
		NodesAPI.start(node).then(
			(job: Job) => {
				this.setState((state: LocalState) => ({
					deploymentJobs: state.deploymentJobs.map(
						(deploymentJob: DeploymentJob) => {
							if (node.name === deploymentJob.node.name) {
								deploymentJob.steps[2].status = DEPLOYMENT_STEP_STATUS.RUNNING;
							}
							return deploymentJob;
						}
					)
				}));

				JobsService.trackJob(job).then(
					() => {
						// console.log("node start job done!", node.name);
						this.setState((state: LocalState) => ({
							deploymentJobs: state.deploymentJobs.map(
								(deploymentJob: DeploymentJob) => {
									if (node.name === deploymentJob.node.name) {
										deploymentJob.steps[2].status = DEPLOYMENT_STEP_STATUS.DONE;
										deploymentJob.isDone = true;
									}
									return deploymentJob;
								}
							)
						}));

						this.setState((state: LocalState) => ({
							isDeploymentDone: state.deploymentJobs.every(
								(deploymentJob: DeploymentJob) => deploymentJob.isDone
							)
						}));
					},
					() => {
						// console.log("node start job failed", node.name, job);
						this.setState((state: LocalState) => ({
							deploymentJobs: state.deploymentJobs.map(
								(deploymentJob: DeploymentJob) => {
									if (node.name === deploymentJob.node.name) {
										deploymentJob.steps[2].status =
											DEPLOYMENT_STEP_STATUS.FAILED;
										deploymentJob.steps[2].errorMessage =
											"Failed to start node. Check logs for more info";
										deploymentJob.isDone = true;
									}
									return deploymentJob;
								}
							)
						}));
					}
				);
			},
			(err: AxiosError<GMDErrorData>) => {
				// console.log("node start error", err, err.message, err.response);
				this.setState((state: LocalState) => ({
					deploymentJobs: state.deploymentJobs.map(
						(deploymentJob: DeploymentJob) => {
							if (node.name === deploymentJob.node.name) {
								deploymentJob.steps[2].status = DEPLOYMENT_STEP_STATUS.FAILED;
								deploymentJob.steps[2].errorMessage = err.message;
								deploymentJob.isDone = true;
							}
							return deploymentJob;
						}
					)
				}));
			}
		);
	}

	onSubmit(): void {
		if (this.state.nodesToDeploy === 1) {
			// if (this.props.nodeCreateWizard.isHostDeployed) {
			// 	this.installNode(this.state.node);
			// } else {
			this.deploy(this.state.host, this.state.node);
			// }
		} else {
			this.initiateBulkDeployment();
		}

		this.setState({ activeStep: 1 });
	}

	initiateBulkDeployment() {
		// console.log("preparing data for bulk deployment", this.state.nodesToDeploy);
		const { host, node, nodesToDeploy } = this.state;

		const suffixes = Utils.generateRandomStringArray(nodesToDeploy, 5);

		const hosts: Host[] = Array(nodesToDeploy)
			.fill(host)
			.map((host: Host, index: number) => ({
				...host,
				name: `${host.name}-${suffixes[index]}`
			}));
		const nodes: Node[] = Array(nodesToDeploy)
			.fill(node)
			.map((node: Node, index: number) => ({
				...node,
				name: `${node.name}-${suffixes[index]}`
			}));

		// console.log("generated hosts", hosts);
		// console.log("generated nodes", nodes);

		// start host deployment jobs
		hosts.forEach((host: Host, index: number) => {
			this.deploy(host, nodes[index]);
		});
	}

	onCloseAttempt(step: number): void {
		if (step === 0) {
			if (this.state.formIsChanged) {
				GMDialogService.showConfirm({
					title: "Unsaved changes",
					message:
						"Are you sure you want to exit the dialog? Changes will be lost.",
					confirmText: "Exit",
					declineText: "Keep editing"
				}).then(
					() => {
						this.props.nodeCreateWizardHide();
						// TODO: ask should it go gere as it goes in clusterCreateDialogComponent.tsx, probably not because it is automaticly reset
						// setTimeout(() => {
						// 	this.resetState();
						// }, 500);
					},
					() => {}
				);
			} else {
				this.props.nodeCreateWizardHide();
			}
		}
	}

	render() {
		const { classes, theme } = this.props;
		const { isOpen, cluster } = this.props.nodeCreateWizard;
		const {
			node,
			host,
			startNodeAfterDeployment,
			nodesToDeploy,
			activeStep,
			deploymentJobs,
			sshTestStatus,
			dbTestStatus
		} = this.state;

		const clusterType = ClusterUtils.getClusterType(cluster);
		let isDeployDisabled = false;

		if (
			clusterType === CLUSTER_TYPE.MONITORED &&
			(sshTestStatus !== CONNECTION_TEST_STATUS.SUCCESS ||
				dbTestStatus !== CONNECTION_TEST_STATUS.SUCCESS)
		)
			isDeployDisabled = true;

		if (
			clusterType === CLUSTER_TYPE.HYBRID &&
			sshTestStatus !== CONNECTION_TEST_STATUS.SUCCESS
		)
			isDeployDisabled = true;

		const step1Content = (
			<FormWrapper>
				<Grid container direction="column">
					<Grid container item direction="row" spacing={2}>
						{host.type !== HOST_TYPE.UNMANAGED && (
							<Grid item xs={6}>
								<FormControl fullWidth={true}>
									<TextField
										margin="dense"
										name="nodes-to-deploy"
										label="# of nodes to deploy"
										autoComplete="off"
										type="number"
										variant={INPUT_VARIANT}
										inputProps={{
											"data-testid": "node-deployment-no-of-nodes",
											max: 100,
											min: 1
										}}
										value={nodesToDeploy}
										onChange={(e: ChangeEvent) => {
											const field = e.target as HTMLFormElement;
											this.setState({
												nodesToDeploy: parseInt(field.value),
												formIsChanged: true
											});
										}}
									/>
								</FormControl>
							</Grid>
						)}
						<Grid item sm={6} xs={12} alignItems="flex-end">
							<Box className={classes.checkboxCenter}>
								<FormControlLabel
									control={
										<Checkbox
											data-testid="node-deployment-auto-start-nodes-checkbox"
											checked={startNodeAfterDeployment}
											onChange={() => {
												this.setState({
													startNodeAfterDeployment: !startNodeAfterDeployment,
													formIsChanged: true
												});
											}}
											value="startNode"
											color="primary"
										/>
									}
									label={
										nodesToDeploy > 1
											? "Start nodes after deployment"
											: "Start node after deployment"
									}
								/>
							</Box>
						</Grid>
					</Grid>
					<Grid item>
						<NodeFormComponent
							cluster={cluster}
							node={node}
							host={host}
							onNodeChange={(node: Node) => {
								this.setState({ node, formIsChanged: true });
							}}
							onHostChange={(host: Host) => {
								this.setState({ host, formIsChanged: true });
							}}
							multipleDeploymentMode={nodesToDeploy > 1}
							onSubmit={() => {
								// console.log("onSubmit", node, host);
								this.onSubmit();
							}}
							sshTestStatus={sshTestStatus}
						/>
					</Grid>
				</Grid>
			</FormWrapper>
		);

		const step1Actions = (
			<>
				<div style={{ flexGrow: 1 }} />
				<Button
					size="small"
					variant="outlined"
					onClick={(): void => {
						this.onCloseAttempt(activeStep);
					}}
				>
					Cancel
				</Button>

				{clusterType !== CLUSTER_TYPE.MANAGED && (
					<ConnectionTestComponent
						clusterID={cluster.id}
						hostSystem={host.system}
						dbEngine={node.dbEngine}
						sshAddress={host.ssh?.address}
						sshPort={host.ssh?.port}
						testDBConnection={clusterType === CLUSTER_TYPE.MONITORED}
						privateKey={cluster.sharedConfig.host.privateKey || ""}
						dbRootPassword={node.rootPassword}
						onSSHStatusChange={(status: CONNECTION_TEST_STATUS) => {
							this.setState({ sshTestStatus: status });
						}}
						onDBStatusChange={(status: CONNECTION_TEST_STATUS) => {
							this.setState({ dbTestStatus: status });
						}}
						onDBRootPasswordUpdate={(rootPassword: string) => {
							this.setState((state: LocalState) => ({
								node: {
									...state.node,
									rootPassword: rootPassword
								}
							}));
							this.props.clusterListFetchRequested();
						}}
					/>
				)}
				{isDeployDisabled ? (
					<Tooltip title={"Please test access first"}>
						<span>
							<Button
								style={{ marginLeft: theme.spacing(1) }}
								disabled={true}
								data-testid="deploy-button"
								color="primary"
								variant="contained"
								size="small"
								type="submit"
								form="nodeForm"
							>
								Deploy
							</Button>
						</span>
					</Tooltip>
				) : (
					<Button
						style={{ marginLeft: theme.spacing(1) }}
						data-testid="deploy-button"
						color="primary"
						variant="contained"
						size="small"
						type="submit"
						form="nodeForm"
					>
						Deploy
					</Button>
				)}
			</>
		);

		const step2Content = (
			<>
				<Grid item container direction="row" spacing={1}>
					{deploymentJobs.map((deploymentJob: DeploymentJob, index: number) => (
						<Grid container item key={index} xs={12}>
							<NodeDeploymentMonitorComponent deploymentJob={deploymentJob} />
						</Grid>
					))}
				</Grid>
			</>
		);

		const step2Actions = (
			<>
				<div style={{ flexGrow: 1 }} />
				<Button
					data-testid="finish-button"
					color="primary"
					variant="outlined"
					size="small"
					disabled={!deploymentJobs.every((job: DeploymentJob) => job.isDone)}
					onClick={() => {
						this.props.hostListFetchRequested(node.clusterID);
						this.props.nodeListFetchRequested(node.clusterID);
						this.props.clusterListFetchRequested();
						this.props.nodeCreateWizardHide();
					}}
				>
					Finish
				</Button>
			</>
		);

		const stepper = (
			<Stepper activeStep={activeStep} classes={{ root: classes.stepperRoot }}>
				<Step>
					<StepLabel>{"Configure"}</StepLabel>
				</Step>

				<Step>
					<StepLabel>{"Deploy"}</StepLabel>
				</Step>
			</Stepper>
		);

		const dialogContent = (
			<>
				<DialogContent>
					{stepper}
					{(activeStep === 0 && step1Content) ||
						(activeStep === 1 && step2Content)}
				</DialogContent>
				<DialogActions>
					{(activeStep === 0 && step1Actions) ||
						(activeStep === 1 && step2Actions)}
				</DialogActions>
			</>
		);

		const isDesktop = checkIfDesktop(600);

		return (
			<Dialog
				onClose={(event: Event, reason: string) => {
					if (reason === "escapeKeyDown" || reason === "backdropClick") {
						this.onCloseAttempt(activeStep);
					}
				}}
				scroll="body"
				fullScreen={!isDesktop}
				maxWidth={activeStep !== 1 ? "md" : "sm"}
				fullWidth={isDesktop}
				open={isOpen}
			>
				<DialogTitle>Node deployment</DialogTitle>
				{dialogContent}
			</Dialog>
		);
	}
}

// REDUX MAPPINGS
const mapGlobalStateToProps = (state: AppState) => ({
	nodeCreateWizard: state.nodeCreateWizard
});

const mapGlobalDispatchToProps = (dispatch: any) => ({
	nodeCreateWizardHide: () => {
		dispatch(nodeCreateWizardHide());
	},
	nodeListFetchRequested: (clusterID: number) => {
		dispatch(nodeListFetchRequested(clusterID));
	},
	hostListFetchRequested: (clusterID: number) => {
		dispatch(hostListFetchRequested(clusterID));
	},
	clusterListFetchRequested: () => {
		dispatch(clusterListFetchRequested());
	}
});

export default withStyles(styles, { withTheme: true })(
	withRouter(
		connect(
			mapGlobalStateToProps,
			mapGlobalDispatchToProps
		)(NodeDeploymentDialogComponent)
	)
);
