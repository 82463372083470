import { JobTracking } from "services/jobs/types";
import {
	JOB_MONITOR_ACTION,
	JobMonitorAction,
	JobMonitorState
} from "store/jobsMonitor/types";
import JobUtils from "./utils";

export const initialState: JobMonitorState = {
	trackedJobList: [], // last state of tracked jobs
	jobsToTrack: [], // contains IDs of jobs to monitor
	runningJobList: [],
	jobsDoneDuringBoot: [],
	isBootDone: false,
	isWsConnected: false
};

export function jobMonitorReducer(
	state: JobMonitorState = initialState,
	action: JobMonitorAction
): JobMonitorState {
	switch (action.type) {
		case JOB_MONITOR_ACTION.BOOT_DONE:
			return {
				...state,
				isBootDone: true
			};

		case JOB_MONITOR_ACTION.SET_RUNNING_JOBS:
			if (!state.isBootDone) {
				return {
					...state,
					runningJobList: action.payload.jobs.filter(
						(jobTracking) => !state.jobsDoneDuringBoot.includes(jobTracking.id)
					)
				};
			} else {
				return {
					...state,
					runningJobList: action.payload.jobs
				};
			}

		case JOB_MONITOR_ACTION.ADD_RUNNING_JOB:
			return {
				...state,
				runningJobList: [...state.runningJobList, action.payload.job]
			};

		case JOB_MONITOR_ACTION.REMOVE_RUNNING_JOB:
			return {
				...state,
				runningJobList: state.runningJobList.filter(
					(jobTracking: JobTracking) => jobTracking.id !== action.payload.jobId
				),
				// if boot not finished, add to done jobs
				jobsDoneDuringBoot: state.isBootDone
					? state.jobsDoneDuringBoot
					: [...state.jobsDoneDuringBoot, action.payload.jobId]
			};

		case JOB_MONITOR_ACTION.TRACK:
			return {
				...state,
				jobsToTrack: [...state.jobsToTrack, action.payload.jobId]
			};

		case JOB_MONITOR_ACTION.UNTRACK:
			return {
				...state,
				jobsToTrack: state.jobsToTrack.filter(
					(jobId) => jobId !== action.payload.jobId
				),
				trackedJobList: state.trackedJobList.filter(
					(jobTracking) => jobTracking.id !== action.payload.jobId
				)
			};

		case JOB_MONITOR_ACTION.CONSOLIDATE_TRACKED_JOBS:
			// update tracked jobs

			const trackedJobList: JobTracking[] = [];

			action.payload.jobs.forEach((job) => {
				// if job is still being tracked
				if (state.jobsToTrack.includes(job.id)) {
					const existingTrackedJob = state.trackedJobList.find(
						(jobTracking) => jobTracking.id === job.id
					);

					if (!existingTrackedJob) {
						// if job is not in existing list - add it
						trackedJobList.push(JobUtils.getJobTracking(job));
					} else {
						// if job is in existing list and new status is higher - add it
						if (
							JobUtils.compareStatuses(
								job.executionInfo.status,
								existingTrackedJob.status
							) === 1
						) {
							trackedJobList.push(JobUtils.getJobTracking(job));
							// if new job status is not higher - keep it
						} else {
							trackedJobList.push(existingTrackedJob);
						}
					}
				}
			});

			return {
				...state,
				trackedJobList
			};

		case JOB_MONITOR_ACTION.SET_TRACKED_JOB:
			// if it exists - update it
			if (
				state.trackedJobList.find(
					(jobTracking) => jobTracking.id === action.payload.job.id
				)
			) {
				return {
					...state,
					trackedJobList: state.trackedJobList.map((jobTracking) =>
						jobTracking.id === action.payload.job.id
							? action.payload.job
							: jobTracking
					)
				};
			} else {
				// if it does not exist - add it
				return {
					...state,
					trackedJobList: [...state.trackedJobList, action.payload.job]
				};
			}

		case JOB_MONITOR_ACTION.REMOVE_TRACKED_JOB:
			return {
				...state,
				trackedJobList: state.trackedJobList.filter(
					(jobTracking) => jobTracking.id !== action.payload.jobId
				)
			};

		default:
			return state;
	}
}
