import {
	AGGREGATION,
	Dashboard,
	DASHBOARD_TILE_SIZE
} from "components/monitoring/dashboard/types";

export const DEFAULT_DASHBOARD_CONFIG: Dashboard = {
	name: "",
	config: {
		charts: [
			{
				id: "301186ce-7b7f-41bb-a457-60696aeabba8",
				title: "wsrep_received",
				metric: {
					name: "wsrep_received",
					table: "mysql"
				},
				position: 3,
				aggregation: AGGREGATION.DIFFERENTIAL,
				unit: "tx",
				dataScaling: 1
			},
			{
				id: "8c24b48d-e6f5-4d29-9e66-58f4774e0480",
				title: "wsrep_replicated",
				metric: {
					name: "wsrep_replicated",
					table: "mysql"
				},
				position: 2,
				aggregation: AGGREGATION.DIFFERENTIAL,
				unit: "tx",
				dataScaling: 1
			},
			{
				id: "bbc94d8f-44cf-4764-8b8e-0eeca2f648a2",
				title: "cpu",
				metric: {
					name: "cpu_usage",
					table: "procstat"
				},
				position: 0,
				aggregation: AGGREGATION.RAW,
				unit: "%",
				dataScaling: 1,
				yAxisMax: 100
			},
			// {
			// 	id: "8325c2b0-c35a-4735-9def-bf2e4dcb3d30",
			// 	name: "host_memory_MemAvailable_bytes",
			// 	metric: "node_memory_MemAvailable_bytes",
			// 	position: 1,
			// 	resolution: "5s",
			// 	aggregation: AGGREGATION.RAW
			// },
			{
				id: "0517a725-4988-4011-91f0-c77202346bea",
				title: "wsrep_flow_control_sent",
				metric: {
					name: "wsrep_flow_control_sent",
					table: "mysql"
				},
				position: 4,
				aggregation: AGGREGATION.DIFFERENTIAL,
				unit: "",
				dataScaling: 1
			},
			{
				id: "a0e25085-19e3-4b4b-b6f4-8285f5bd2e65",
				title: "wsrep_flow_control_paused",
				metric: {
					name: "wsrep_flow_control_paused",
					table: "mysql"
				},
				position: 5,
				aggregation: AGGREGATION.DIFFERENTIAL,
				unit: "",
				dataScaling: 1
			}
		],
		tileSize: DASHBOARD_TILE_SIZE.MEDIUM,
		period: "15m"
	}
};

export const DASHBOARD_PERIOD_SELECT_OPTIONS = [
	"5m",
	"15m",
	"30m",
	"1h",
	"3h",
	"6h",
	"12h",
	"1d",
	"7d",
	"30d"
];
// export const DASHBOARD_RESOLUTION_SELECT_OPTIONS = [
// 	"1s",
// 	"5s",
// 	"15s",
// 	"30s",
// 	"1m",
// 	"5m",
// 	"30m",
// 	"1h"
// ];
// export const DASHBOARD_REFRESH_RATE_SELECT_OPTIONS = [
// 	{ label: "1s", value: 1000 },
// 	{ label: "5s", value: 5000 },
// 	{ label: "15s", value: 15000 },
// 	{ label: "30s", value: 30000 },
// 	{ label: "1m", value: 60000 }
// ];

// top offset to the charts area
export const DASHBOARD_CHARTS_TOP_OFFSET = 270;
export const DASHBOARD_NO_OF_COLUMNS = {
	SM: 3,
	MD: 2,
	LG: 1
};
export const DASHBOARD_NO_OF_ROWS = {
	SM: 4,
	MD: 3,
	LG: 2
};
