// MUI
import { Typography } from "@mui/material";
import useStyles from "components/form/styles";

interface Props {
	/** Subtitle that will be displayed */
	title: string | boolean;
	isFormTitle?: boolean;
}

const FormTitle = (props: Props) => {
	const { title, isFormTitle } = props;
	const classes = useStyles();

	return (
		<Typography
			// variant={isFormTitle ? "h5" : "h6"} // prethodna verzija, vratiti ako imam dvi vrste titlova
			variant={"h5"}
			className={isFormTitle ? classes.primaryTitle : classes.secondaryTitle}
		>
			{title}
		</Typography>
	);
};

export default FormTitle;
