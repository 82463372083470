import {
	HOST_ACTION,
	HostAction,
	Host
} from "pages/management/host/types";

const initialState: Map<number, Host[]> = new Map<number, Host[]>();

export function hostListReducer(
	state: Map<number, Host[]> = initialState,
	action: HostAction
): Map<number, Host[]> {
	switch (action.type) {
		case HOST_ACTION.LIST_FETCH_SUCCEEDED:
			console.log("host list fetch succeeded", action.payload);
			const { clusterID, hostList } = action.payload;
			return new Map<number, Host[]>(state).set(clusterID, hostList);
		default:
			return state;
	}
}
