// MUI
import {
	Checkbox,
	FormControl,
	FormControlLabel,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Tooltip
} from "@mui/material";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
// redux
import { AppState } from "AppState";
import { CONNECTION_TEST_STATUS } from "components/connectionTestDialog/types";
import DbEngineConfigButtonComponent from "components/form/dbEngineConfigEditor/DbEngineConfigButtonComponent";
import DigitalOceanConfigComponent from "components/form/digitalOceanConfig/DigitalOceanConfigComponent";
import { DigitalOceanConfig } from "components/form/digitalOceanConfig/types";
import EC2ConfigComponent from "components/form/ec2Config/EC2ConfigComponent";
import SSHKeysEditor from "components/form/SSHKeysEditor/SSHKeysEditorComponent";
import { CLUSTER_TYPE } from "pages/management/cluster/clusterCreateWizard/types";
import { Cluster } from "pages/management/cluster/types";
import ClusterUtils from "pages/management/cluster/utils";
import {
	EC2Config,
	Host,
	HOST_SYSTEM,
	HOST_TYPE
} from "pages/management/host/types";
import HostUtils from "pages/management/host/utils";
import { styles } from "pages/management/node/nodeForm/styles";
import { Node, NODE_DB_ENGINE } from "pages/management/node/types";
import { ChangeEvent, Component, FormEvent } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";

import FormTitle from "components/form/title/FormTitle";
import { HostSystemFieldComponent } from "../../../../components/form/formFields/HostSystemFieldComponent";

interface LocalState {
	formValidation: {
		nodeName?: {
			isInvalid: boolean;
			message: string;
		};
		hostName?: {
			isInvalid: boolean;
			message: string;
		};
		segment?: {
			isInvalid: boolean;
			message: string;
		};
		databaseSizeGiB?: {
			isInvalid: boolean;
			message: string;
		};
		sshAddress?: {
			isInvalid: boolean;
			message: string;
		};
		sshPort?: {
			isInvalid: boolean;
			message: string;
		};
		hostSystem?: {
			isInvalid: boolean;
			message: string;
		};
	};
	isNodeNameLinkEnabled: boolean;
	isSubmitClicked: boolean;
	isCloudAccessVerified: boolean;
}

interface LocalProps {
	cluster: Cluster;
	node?: Node;
	host: Host;
	readOnly?: boolean;
	onNodeChange?: (node: Node) => void;
	onHostChange?: (host: Host) => void;
	onSubmit?: () => void;
	multipleDeploymentMode?: boolean;
	onToggleGeneralLog?: () => void;
	sshTestStatus?: CONNECTION_TEST_STATUS;
}

// PROPS
interface ReduxStateProps {
	nodeNames: string[];
	hostNames: string[];
}

type Props = LocalProps & ReduxStateProps & WithStyles<typeof styles>;

class NodeFormComponent extends Component<Props, LocalState> {
	constructor(props: Props) {
		super(props);

		// console.log("support matrix", SupportMatrix.getSupportMatrix());

		this.state = {
			formValidation: {},
			isNodeNameLinkEnabled: true,
			isSubmitClicked: false,
			isCloudAccessVerified: false
		};
	}

	onSubmit = (e: FormEvent) => {
		e.preventDefault();
		// console.log("onSubmit", e);

		this.setState({
			isSubmitClicked: true
		});

		// if (
		// 	!SupportMatrix.isSystemSupported(
		// 		this.props.host.system,
		// 		this.props.host.type
		// 	)
		// ) {
		// 	this.setState({
		// 		formValidation: {
		// 			hostSystem: {
		// 				isInvalid: true,
		// 				message: `${this.props.host.system} is no longer supported by Galera Manager`
		// 			}
		// 		}
		// 	});
		//
		// 	return;
		// }

		// check if cloud access is verified
		if (
			[HOST_TYPE.EC2, HOST_TYPE.DIGITAL_OCEAN].includes(
				this.props.cluster.sharedConfig.host.type || HOST_TYPE.UNMANAGED
			) &&
			!this.state.isCloudAccessVerified
		) {
			console.log("cloud access is not verified");
			return;
		}

		if (this.props.readOnly !== false && this.props.onSubmit) {
			this.props.onSubmit();
		}
	};

	onHostNameChange = (e: ChangeEvent) => {
		const {
			onHostChange,
			onNodeChange,
			multipleDeploymentMode,
			hostNames,
			host,
			node
		} = this.props;

		const field = e.target as HTMLFormElement;

		onHostChange && onHostChange({ ...host, name: field.value as string });
		!this.state.isNodeNameLinkEnabled &&
			node &&
			onNodeChange &&
			onNodeChange({ ...node, hostID: field.value });

		!multipleDeploymentMode && hostNames.includes(field.value)
			? field.setCustomValidity("Host name already in use")
			: field.setCustomValidity("");

		const isValid = field.checkValidity();

		this.setState((state: LocalState) => ({
			formValidation: {
				...state.formValidation,
				hostName: {
					isInvalid: !isValid,
					message: isValid ? "" : field.validationMessage
				}
			}
		}));
	};

	setHostName(hostName: string) {
		const { onHostChange, host } = this.props;

		onHostChange && onHostChange({ ...host, name: hostName });
		// node && onNodeChange && onNodeChange({ ...node, hostID: hostName });
	}

	render() {
		const {
			cluster,
			node,
			host,
			multipleDeploymentMode,
			onHostChange,
			onNodeChange,
			readOnly,
			nodeNames,
			sshTestStatus
		} = this.props;

		const { isNodeNameLinkEnabled, formValidation, isSubmitClicked } =
			this.state;

		const clusterType = ClusterUtils.getClusterType(cluster);

		return (
			<>
				<form
					data-testid="node-form"
					id={`nodeForm${readOnly ? "-readonly" : ""}`}
					onSubmit={this.onSubmit}
				>
					{node && host && (
						<>
							<Grid
								container
								direction="column"
								sx={{
									maxWidth: 600,
									margin: "auto",
									marginBottom: "12px"
								}}
							>
								{/* <Grid container direction="row" spacing={2}> */}
								<Grid item xs={12}>
									<FormTitle title="General" />

									<FormControl
										onInvalid={(e: FormEvent): void => {
											e.preventDefault();
											const form = e.target as HTMLFormElement;

											this.setState((state: LocalState) => ({
												formValidation: {
													...state.formValidation,
													nodeName: {
														isInvalid: true,
														message: form.validationMessage
													}
												}
											}));
										}}
										error={formValidation.nodeName?.isInvalid}
										fullWidth={true}
										required
									>
										<TextField
											error={formValidation.nodeName?.isInvalid}
											margin="dense"
											variant="outlined"
											required
											label={
												multipleDeploymentMode
													? "Node name prefix"
													: "Node name"
											}
											autoFocus={!readOnly}
											inputProps={{
												readOnly: readOnly,
												minLength: 3,
												maxLength: 20,
												"data-testid": "node-name"
											}}
											autoComplete="off"
											value={node.name}
											helperText={formValidation.nodeName?.message}
											onChange={(e: ChangeEvent) => {
												const field = e.target as HTMLFormElement;

												isNodeNameLinkEnabled && this.onHostNameChange(e);

												onNodeChange &&
													onNodeChange({
														...node,
														name: field.value,
														hostID: isNodeNameLinkEnabled
															? field.value
															: node?.hostID
													});

												// console.log("nodeNames", nodeNames);
												!multipleDeploymentMode &&
												nodeNames.includes(field.value)
													? field.setCustomValidity("Node name already in use")
													: field.setCustomValidity("");

												const isValid = field.checkValidity();

												this.setState((state: LocalState) => ({
													formValidation: {
														...state.formValidation,
														nodeName: {
															isInvalid: !isValid,
															message: isValid ? "" : field.validationMessage
														}
													}
												}));
											}}
										/>
									</FormControl>
								</Grid>

								{clusterType !== CLUSTER_TYPE.MANAGED && (
									<Grid
										sx={{ display: { xs: "none", md: "block" } }}
										item
										sm={6}
										xs={12}
									/>
								)}

								<Grid item xs={12}>
									<HostSystemFieldComponent
										value={host.system}
										hostType={host.type}
										dbEngine={node.dbEngine}
										readOnly={!!readOnly}
										disabled={!!readOnly}
										onChange={(system: HOST_SYSTEM) => {
											onHostChange &&
												onHostChange({
													...host,
													system
												});
										}}
									/>
								</Grid>

								<Grid item xs={12}>
									<FormControl
										onInvalid={(e: FormEvent): void => {
											e.preventDefault();
											const form = e.target as HTMLFormElement;

											this.setState((state: LocalState) => ({
												...state,
												formValidation: {
													...state.formValidation,
													segment: {
														isInvalid: true,
														message: form.validationMessage
													}
												}
											}));
										}}
										error={formValidation.segment?.isInvalid}
										fullWidth={true}
										required
									>
										<TextField
											error={formValidation.segment?.isInvalid}
											helperText={formValidation.segment?.message}
											fullWidth={true}
											type="number"
											required
											variant="outlined"
											margin="dense"
											label="Segment"
											value={host.segment}
											onChange={(e) => {
												const field = e.target as HTMLInputElement;
												const value = parseInt(field.value);

												onHostChange &&
													onHostChange({
														...host,
														segment: value
													});

												const isValid = field.checkValidity();

												this.setState((state: LocalState) => ({
													formValidation: {
														...state.formValidation,
														segment: {
															isInvalid: !isValid,
															message: isValid ? "" : field.validationMessage
														}
													}
												}));
											}}
											inputProps={{
												id: "node-segment",
												"data-testid": "node-segment",
												readOnly: readOnly,
												min: 0,
												max: 255
											}}
										/>
									</FormControl>
								</Grid>
								{host.type !== HOST_TYPE.UNMANAGED && (
									<Grid item xs={12}>
										<DbEngineConfigButtonComponent
											config={node.userConfig}
											inheritedConfig={cluster?.sharedConfig.node.userConfig}
											readOnly={readOnly || false}
											onChange={(config: string) => {
												node &&
													onNodeChange &&
													onNodeChange({ ...node, userConfig: config });
											}}
											subtitleText={
												readOnly
													? "This is currently active custom DB engine configuration"
													: "Enter custom DB engine configuration that will be applied to this node"
											}
										/>
									</Grid>
								)}
								{readOnly && (
									<Grid item xs={12}>
										<Tooltip
											title={`Click to ${
												node.settings.logs.generalLog.enabled
													? "disable"
													: "enable"
											} general log tracking`}
										>
											<FormControlLabel
												control={
													<Checkbox
														data-testid="toggle-general-log"
														checked={node.settings.logs.generalLog.enabled}
														onChange={() => {
															this.props.onToggleGeneralLog &&
																this.props.onToggleGeneralLog();
														}}
														value="startNode"
														color="primary"
													/>
												}
												label={
													"General log (consumes large amounts of disk space on GMD host over time)"
												}
											/>
										</Tooltip>
									</Grid>
								)}
								{/* </Grid> */}

								<Grid container direction="column" spacing={2}>
									<FormTitle title="Access control" />

									<Grid item xs={12}>
										<SSHKeysEditor
											clusterType={clusterType}
											authorizedKeys={host.authorizedKeys}
											sshTestStatus={sshTestStatus}
											inheritedAuthorizedKeys={
												cluster ? cluster.sharedConfig.host.authorizedKeys : []
											}
											readOnly={readOnly || false}
											onAdd={(publicKey: string): void => {
												onHostChange &&
													onHostChange({
														...host,
														authorizedKeys: [...host.authorizedKeys, publicKey]
													});
											}}
											onRemove={(deletedKey: string): void => {
												const filteredKeys = host.authorizedKeys.filter(
													(publicKey: string) => publicKey !== deletedKey
												);

												onHostChange &&
													onHostChange({
														...host,
														authorizedKeys: filteredKeys
													});
											}}
										/>
									</Grid>

									<FormTitle title="Deployment" />

									{clusterType === CLUSTER_TYPE.MANAGED && (
										<Grid item xs={12}>
											<FormControl required fullWidth={true} margin="dense">
												<InputLabel htmlFor="host-type">
													Cloud provider
												</InputLabel>
												<Select
													displayEmpty={false}
													required
													variant="outlined"
													fullWidth={true}
													label="Cloud provider"
													data-testid="cloud-provider-select-container"
													readOnly={readOnly}
													value={host.type}
													onChange={(e) => {
														console.log("onChange", e);

														const selectedCloudProvider = e.target
															.value as HOST_TYPE;

														const defaultHostTypeSpecificConfig =
															HostUtils.getHostTypeSpecificDefaults(
																selectedCloudProvider
															);

														onHostChange &&
															onHostChange({
																...host,
																type: selectedCloudProvider,
																hostTypeSpecific:
																	cluster.sharedConfig.host.type ===
																	selectedCloudProvider
																		? cluster.sharedConfig.host.hostTypeSpecific
																		: defaultHostTypeSpecificConfig
															});
													}}
													inputProps={{
														id: "host-type",
														"data-testid": "host-type-select"
													}}
												>
													<MenuItem key={HOST_TYPE.EC2} value={HOST_TYPE.EC2}>
														AWS EC2
													</MenuItem>
													<MenuItem
														key={HOST_TYPE.DIGITAL_OCEAN}
														value={HOST_TYPE.DIGITAL_OCEAN}
													>
														DigitalOcean
													</MenuItem>
												</Select>
											</FormControl>
										</Grid>
									)}

									{host.type === HOST_TYPE.DIGITAL_OCEAN &&
										host.hostTypeSpecific && (
											<DigitalOceanConfigComponent
												readOnly={readOnly}
												config={host.hostTypeSpecific as DigitalOceanConfig}
												onChange={(config: DigitalOceanConfig) => {
													console.log("onDOChange", config);

													onHostChange &&
														onHostChange({
															...host,
															hostTypeSpecific: config
														});
												}}
												onValidationChange={(isValid: boolean) => {
													console.log("onTokenValidation", isValid);
													this.setState({
														isCloudAccessVerified: isValid
													});
												}}
												isSubmitClicked={isSubmitClicked}
											/>
										)}

									{host.type === HOST_TYPE.EC2 && host.hostTypeSpecific && (
										<>
											<EC2ConfigComponent
												config={host.hostTypeSpecific}
												readOnly={readOnly}
												requirements={{
													minRAM: node
														? node.dbEngine === NODE_DB_ENGINE.MYSQL_8_0
															? 2048
															: 1024
														: 1024
												}}
												onChange={(ec2Config: EC2Config) => {
													console.log("onEC2 config change", ec2Config);

													onHostChange &&
														onHostChange({
															...host,
															hostTypeSpecific: {
																...ec2Config
															}
														});
												}}
												onValidationChange={(isValid: boolean) => {
													console.log("onCredentialsValidation", isValid);
													this.setState({
														isCloudAccessVerified: isValid
													});
												}}
											/>
										</>
									)}
									{(readOnly || host.type === HOST_TYPE.UNMANAGED) && (
										<>
											<Grid item xs={12}>
												<FormControl
													onInvalid={(e: FormEvent): void => {
														e.preventDefault();
														const form = e.target as HTMLFormElement;

														this.setState((state: LocalState) => ({
															formValidation: {
																...state.formValidation,
																sshAddress: {
																	isInvalid: true,
																	message: form.validationMessage
																}
															}
														}));
													}}
													error={formValidation.sshAddress?.isInvalid}
													fullWidth={true}
													required
												>
													<TextField
														fullWidth={true}
														margin="dense"
														required
														variant="outlined"
														error={formValidation.sshAddress?.isInvalid}
														helperText={formValidation.sshAddress?.message}
														label="SSH Address"
														value={host.ssh && host.ssh.address}
														inputProps={{ readOnly }}
														onChange={(e: ChangeEvent) => {
															const field = e.target as HTMLFormElement;
															const address = field.value as string;

															onHostChange &&
																onHostChange({
																	...host,
																	ssh: {
																		address,
																		port: host.ssh?.port || "22"
																	},
																	db: {
																		address,
																		port: host.db?.port || "3306"
																	}
																});

															const isValid = field.checkValidity();

															this.setState((state: LocalState) => ({
																formValidation: {
																	...state.formValidation,
																	sshAddress: {
																		isInvalid: !isValid,
																		message: isValid
																			? ""
																			: field.validationMessage
																	}
																}
															}));
														}}
													/>
												</FormControl>
											</Grid>
											<Grid item xs={12}>
												<FormControl
													onInvalid={(e: FormEvent): void => {
														e.preventDefault();
														const form = e.target as HTMLFormElement;

														this.setState((state: LocalState) => ({
															formValidation: {
																...state.formValidation,
																sshPort: {
																	isInvalid: true,
																	message: form.validationMessage
																}
															}
														}));
													}}
													error={formValidation.sshPort?.isInvalid}
													fullWidth={true}
													required
												>
													<TextField
														fullWidth={true}
														type="number"
														required
														variant="outlined"
														error={formValidation.sshPort?.isInvalid}
														helperText={formValidation.sshPort?.message}
														margin="dense"
														label="SSH Port"
														value={host.ssh && host.ssh.port}
														inputProps={{ readOnly, min: 0, max: 65535 }}
														onChange={(e: ChangeEvent) => {
															const field = e.target as HTMLFormElement;
															const port = field.value as string;

															onHostChange &&
																onHostChange({
																	...host,
																	ssh: {
																		address: host.ssh?.address || "",
																		port
																	}
																});

															const isValid = field.checkValidity();

															this.setState((state: LocalState) => ({
																formValidation: {
																	...state.formValidation,
																	sshPort: {
																		isInvalid: !isValid,
																		message: isValid
																			? ""
																			: field.validationMessage
																	}
																}
															}));
														}}
													/>
												</FormControl>
											</Grid>
										</>
									)}
								</Grid>
							</Grid>
						</>
					)}
				</form>
			</>
		);
	}
}

const makeNodeNamesSelector = () =>
	createSelector(
		(state: AppState) => state.nodeList,
		(state: AppState, props: LocalProps) => props.cluster,
		(nodeList: Map<number, Node[]>, cluster?: Cluster): string[] => {
			if (cluster && cluster.id) {
				return nodeList.get(cluster.id)?.map((node: Node) => node.name) || [];
			} else {
				return [];
			}
		}
	);

const makeHostNamesSelector = () =>
	createSelector(
		(state: AppState) => state.hostList,
		(state: AppState, props: LocalProps) => props.cluster,
		(hostList: Map<number, Host[]>, cluster?: Cluster): string[] => {
			if (cluster && cluster.id) {
				return hostList.get(cluster.id)?.map((host: Host) => host.name) || [];
			} else {
				return [];
			}
		}
	);

const mapGlobalStateToProps = (state: AppState, props: LocalProps) => {
	const nodeNamesSelector = makeNodeNamesSelector();
	const hostNamesSelector = makeHostNamesSelector();

	return {
		nodeNames: nodeNamesSelector(state, props),
		hostNames: hostNamesSelector(state, props)
	};
};

export default withStyles(styles, { withTheme: true })(
	connect(mapGlobalStateToProps, {})(NodeFormComponent)
);
