import React from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import Login from "pages/login/LoginComponent";
import { createBrowserHistory, History } from "history";
import RootComponent from "pages/root/RootComponent";
import { AppState } from "AppState";
import { connect } from "react-redux";
import { PublicPreloaderState } from "components/preloader/types";
import Box from "@mui/material/Box";
import PublicPreloader from "components/preloader/PublicPreloaderComponent";
import AppInfo from "components/appInfo/AppInfoComponent";

interface ReduxStateProps {
	publicPreloader: PublicPreloaderState;
}

type Props = ReduxStateProps;

export const history: History = createBrowserHistory({ basename: "/app" });

class GMRouter extends React.Component<Props> {
	render() {
		const { publicPreloader } = this.props;
		return (
			<>
				{!publicPreloader.isPreloadDone ? (
					<Box height="100%">
						<PublicPreloader />
					</Box>
				) : (
					<>
						<Router history={history}>
							<Switch>
								<Route path="/login" component={Login} />
								<Route path="/" component={RootComponent} />
								<Redirect from="*" to="/" />
							</Switch>
						</Router>
						<AppInfo />
					</>
				)}
			</>
		);
	}
}

const mapGlobalStateToProps = (state: AppState) => {
	return {
		publicPreloader: state.preloader.publicPreloader
	};
};

export default connect(mapGlobalStateToProps, {})(GMRouter);
