import { INPUT_VARIANT } from "../const";
import React from "react";
import {
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select
} from "@mui/material";
import NodeUtils from "../../../pages/management/node/utils";
import SupportMatrix from "../../../services/supportMatrix/SupportMatrix";
import { NODE_DB_ENGINE } from "../../../pages/management/node/types";
import { HOST_SYSTEM, HOST_TYPE } from "../../../pages/management/host/types";

interface Props {
	value: NODE_DB_ENGINE;

	hostType: HOST_TYPE;
	hostSystem: HOST_SYSTEM;

	readOnly: boolean;
	disabled: boolean;

	onChange: (value: NODE_DB_ENGINE) => void;
}

export const DbEngineFieldComponent = (props: Props) => {
	const { value, disabled, readOnly, hostType, hostSystem, onChange } = props;

	// const [validationMessage, setValidationMessage] = useState<string>("");
	// const [invalid, setInvalid] = useState<boolean>(false);
	const getInfoMessage = (): string => {
		if (
			!readOnly &&
			SupportMatrix.isDbDeprecated(value, hostSystem, hostType)
		) {
			return `${value} is deprecated. It's not recommended to create new clusters using deprecated db engines.`;
		} else {
			return "";
		}
	};

	return (
		<FormControl
			required
			fullWidth={true}
			variant={INPUT_VARIANT}
			disabled={disabled}
			margin="dense"
		>
			<InputLabel htmlFor="node-db-engine">Node DB Engine</InputLabel>
			<Select
				required
				fullWidth={true}
				variant={INPUT_VARIANT}
				disabled={disabled}
				label="Node DB Engine"
				data-testid="node-db-engine-select-container"
				readOnly={readOnly}
				value={value}
				onChange={(e) => {
					const dbEngine = NodeUtils.getNodeDbEngineEnum(
						e.target.value as string
					);

					onChange(dbEngine);
				}}
				inputProps={{
					id: "node-db-engine",
					"data-testid": "node-db-engine-select"
				}}
			>
				{Object.values(NODE_DB_ENGINE).map((dbEngine: NODE_DB_ENGINE) => (
					<MenuItem
						key={dbEngine}
						disabled={
							!SupportMatrix.isDbSupportedOnSystem(
								dbEngine,
								hostSystem,
								hostType
							)
						}
						value={dbEngine}
					>
						{`${dbEngine}${
							!SupportMatrix.isDbSupportedOnSystem(
								dbEngine,
								hostSystem,
								hostType
							)
								? " (Not supported on " + hostSystem + ")"
								: ""
						}${
							SupportMatrix.isDbDeprecated(dbEngine, hostSystem, hostType)
								? " (Deprecated)"
								: ""
						}`}
					</MenuItem>
				))}
			</Select>
			<FormHelperText>{getInfoMessage()}</FormHelperText>
		</FormControl>
	);
};
