import { Theme } from "@mui/material";

import createStyles from "@mui/styles/createStyles";

export const styles = (theme: Theme) =>
	createStyles({
		nested: {
			paddingLeft: theme.spacing(10)
		},
		clusterElement: {
			paddingLeft: theme.spacing(2),
			borderTop: `1px solid ${theme.palette.grey[300]}`
		},
		strayHostsSubheader: {
			padding: "8px 12px 0 40px",
			lineHeight: 1.5
		},
		badgeHelpIcon: {
			width: 22,
			height: 22,
			borderRadius: "50%",
			color: theme.palette.grey[400],
			background: theme.palette.common.white
		},
		alertStyle: {
			marginRight: "10px",
			paddingTop: 0,
			paddingBottom: 0,
			backgroundColor: theme.palette.grey[200]
		},
		// these styles are also used in NoClusterItemComponent.tsx
		clusterItemAvatar: {
			backgroundColor: theme.palette.common.white,
			color: theme.palette.primary.main
		},
		clusterItemAvatarIcon: {
			width: 48,
			height: 48
		}
	});
