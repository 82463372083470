import { User, USER_ROLE } from "pages/users/types";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Step,
	StepLabel,
	Stepper,
	Typography
} from "@mui/material";
import { AppState } from "AppState";
import { Component } from "react";
import { connect } from "react-redux";
import { NewUser } from "pages/users/userCreateWizard/types";
import { userCreateWizardHide } from "pages/users/userCreateWizard/actions";
import UserFormComponent from "pages/users/UserFormComponent";
import UsersApi from "services/api/UsersApi";
import { AxiosError } from "axios";
import { userListFetchRequested } from "pages/users/actions";
import { styles } from "pages/users/userCreateWizard/styles";
import { withStyles, WithTheme } from "@mui/styles";

interface LocalState {
	newUser: NewUser;
	activeStep: number;
	error?: string;
	isCreating: boolean;
}

interface LocalProps {}

interface ReduxStateProps {
	isOpen: boolean;
}

interface ReduxDispatchProps {
	hide: () => void;
	reloadUserList: () => void;
}

type Props = LocalProps & ReduxStateProps & ReduxDispatchProps & WithTheme;

class UserCreateWizardComponent extends Component<Props, LocalState> {
	DEFAULT_STATE = {
		activeStep: 0,
		isCreating: false,
		newUser: {
			name: "",
			password: "",
			role: USER_ROLE.NONE
		}
	};

	constructor(props: Props) {
		super(props);

		this.state = { ...this.DEFAULT_STATE };
	}

	onSubmit = (user: User | NewUser) => {
		console.log("onSubmit", user);

		this.setState({ isCreating: true });

		const newUser = user as NewUser;

		UsersApi.create(newUser).then(
			() => {
				this.setState({ isCreating: false, activeStep: 1 });
				this.props.reloadUserList();
			},
			(error: AxiosError) => {
				this.setState({
					isCreating: false,
					error: `Failed to create user: ${
						error.response ? error.response.data.wrapped.msg : error.message
					}`
				});
			}
		);
	};

	render():
		| React.ReactElement<any, string | React.JSXElementConstructor<any>>
		| string
		| number
		| {}
		| React.ReactNodeArray
		| React.ReactPortal
		| boolean
		| null
		| undefined {
		const { activeStep, newUser, error } = this.state;

		const { isOpen, hide, theme } = this.props;

		const stepper = (
			<>
				<Stepper activeStep={activeStep}>
					<Step key={1}>
						<StepLabel>{"Enter user data"}</StepLabel>
					</Step>

					<Step key={2}>
						<StepLabel>{"Finish"}</StepLabel>
					</Step>
				</Stepper>
			</>
		);

		const wizardButtons = (
			<>
				{(activeStep === 0 && (
					<>
						<div style={{ flexGrow: 1 }} />
						<Button
							size="small"
							onClick={(): void => {
								hide();
								this.setState({ ...this.DEFAULT_STATE });
							}}
						>
							Cancel
						</Button>
						<Button color="primary" type="submit" form="userForm" size="small">
							Create
						</Button>
					</>
				)) ||
					(activeStep === 1 && (
						<>
							<div style={{ flexGrow: 1 }} />
							<Button
								size="small"
								onClick={() => {
									hide();
									this.setState({ ...this.DEFAULT_STATE });
								}}
							>
								Close
							</Button>
							<Button
								size="small"
								color="primary"
								onClick={() => {
									console.log("showUser", newUser);
									hide();
									this.setState({ ...this.DEFAULT_STATE });
								}}
							>
								Show user
							</Button>
							<Button
								size="small"
								color="primary"
								onClick={() => {
									this.setState({ ...this.DEFAULT_STATE });
								}}
							>
								Add another user
							</Button>
						</>
					))}
			</>
		);

		const userForm = (
			<>
				<UserFormComponent user={newUser} onSubmit={this.onSubmit} />
			</>
		);

		const userCreatedMessage = (
			<>
				<Typography>User successfully created.</Typography>
			</>
		);

		const dialogContent = (
			<>
				<DialogContent>
					{stepper}
					{error && (
						<Grid item>
							<Typography variant="subtitle2" color="error">
								{error}
							</Typography>
						</Grid>
					)}
					{(activeStep === 0 && userForm) ||
						(activeStep === 1 && userCreatedMessage)}
				</DialogContent>
				<DialogActions>{wizardButtons}</DialogActions>
			</>
		);

		return (
			<>
				{theme.breakpoints.up("sm") ? (
					<Dialog
						fullScreen={false}
						maxWidth="sm"
						fullWidth={true}
						onClose={(event: Event, reason: string) => {
							if (reason === "escapeKeyDown") {
								hide();
								this.setState({ ...this.DEFAULT_STATE });
							}
						}}
						open={isOpen}
					>
						<DialogTitle>Create new user</DialogTitle>
						{dialogContent}
					</Dialog>
				) : (
					<Dialog
						fullScreen={true}
						onClose={(event: Event, reason: string) => {
							if (reason === "escapeKeyDown") {
								hide();
								this.setState({ ...this.DEFAULT_STATE });
							}
						}}
						open={isOpen}
					>
						<DialogTitle>Create new user</DialogTitle>
						{dialogContent}
					</Dialog>
				)}
			</>
		);
	}
}

const mapGlobalStateToProps = (state: AppState) => ({
	isOpen: state.userCreateWizard.isUserCreateWizardOpen
});

const mapGlobalDispatchToProps = (dispatch: any) => {
	return {
		hide: () => {
			dispatch(userCreateWizardHide());
		},
		reloadUserList: () => {
			dispatch(userListFetchRequested());
		}
	};
};

export default withStyles(styles, { withTheme: true })(
	connect(
		mapGlobalStateToProps,
		mapGlobalDispatchToProps
	)(UserCreateWizardComponent)
);
