import { User } from "pages/users/types";
import {
	AUTH_ACTION,
	AuthBootCheck,
	AuthFetchJWT,
	AuthFetchJWTFailed,
	AuthFetchJWTSuccess,
	AuthLogin,
	AuthLoginFailed,
	AuthLoginSuccess,
	AuthLogout,
	AuthLogoutFailed,
	AuthLogoutSuccess
} from "store/auth/stateTypes";
import { Credentials, JwtData } from "store/auth/types";

export const bootAuthCheck = (): AuthBootCheck => ({
	type: AUTH_ACTION.BOOT_CHECK
});

export const login = (credentials: Credentials): AuthLogin => ({
	type: AUTH_ACTION.LOGIN,
	payload: {
		credentials
	}
});

export const loginSuccess = (userData: User): AuthLoginSuccess => ({
	type: AUTH_ACTION.LOGIN_SUCCESS,
	payload: {
		userData
	}
});

export const loginFailed = (errorMsg: string): AuthLoginFailed => ({
	type: AUTH_ACTION.LOGIN_FAILED,
	payload: {
		errorMsg
	}
});

export const logout = (): AuthLogout => ({
	type: AUTH_ACTION.LOGOUT
});

export const logoutSuccess = (): AuthLogoutSuccess => ({
	type: AUTH_ACTION.LOGOUT_SUCCESS
});

export const logoutFailed = (errorMsg: string): AuthLogoutFailed => ({
	type: AUTH_ACTION.LOGOUT_FAILED,
	payload: {
		errorMsg: errorMsg
	}
});

export const fetchJWT = (): AuthFetchJWT => ({
	type: AUTH_ACTION.FETCH_JWT
});

export const fetchJWTSuccess = (
	jwtTokenData: JwtData
): AuthFetchJWTSuccess => ({
	type: AUTH_ACTION.FETCH_JWT_SUCCESS,
	payload: {
		jwtTokenData
	}
});

export const fetchJWTFailed = (errorMsg: string): AuthFetchJWTFailed => ({
	type: AUTH_ACTION.FETCH_JWT_FAILED,
	payload: {
		errorMsg
	}
});
