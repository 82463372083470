import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
	navbarStatus: {
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "center",
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		marginLeft: "auto"
	},
	tooltip: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-start"
	},
	tooltipStatus: {
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "center",
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1)
	},
	indicatorGreen: {
		display: "inline-block",
		width: 16,
		height: 16,
		borderRadius: "50%",
		background: theme.palette.success.main
	},
	indicatorWeak: {
		display: "inline-block",
		width: 16,
		height: 16,
		borderRadius: "50%",
		background: theme.palette.warning.main
	},
	indicatorError: {
		display: "inline-block",
		width: 16,
		height: 16,
		borderRadius: "50%",
		background: theme.palette.error.main
	},
	text: {
		paddingLeft: theme.spacing(2)
	},
	space: {
		height: theme.spacing(1)
	}
}));

export default useStyles;
