import React from "react";
import { WithStyles, WithTheme } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { styles } from "components/form/dbEngineConfigEditor/styles";
import {
	Avatar,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemSecondaryAction,
	ListItemText,
	Tooltip
} from "@mui/material";
import { Delete, Settings } from "@mui/icons-material";
import DbEngineConfigEditorComponent from "components/form/dbEngineConfigEditor/DbEngineConfigEditorComponent";

interface LocalState {
	editorConfig: string;
	isEditorOpen: boolean;
}

interface LocalProps {
	readOnly?: boolean;
	config?: string;
	inheritedConfig?: string;
	onChange?: (config: string) => void;
	subtitleText?: string;
}

type Props = LocalProps & WithStyles<typeof styles> & WithTheme;

class DbEngineConfigButtonComponent extends React.PureComponent<
	Props,
	LocalState
> {
	constructor(props: Props) {
		super(props);

		this.state = { editorConfig: props.config || "", isEditorOpen: false };
	}

	onItemClick = (): void => {
		this.setState({
			editorConfig: this.props.config || "",
			isEditorOpen: true
		});
	};

	// onConfigChanged = (e: ChangeEvent<HTMLInputElement>): void => {
	// 	e.persist();
	// 	const config = e.target.value;
	// 	console.log("config changed", config);
	// 	this.setState({ config });
	// };

	onRemoveClick = (): void => {
		this.props.onChange && this.props.onChange("");
	};

	render() {
		const {
			classes,
			readOnly,
			theme,
			config,
			inheritedConfig,
			subtitleText
		} = this.props;
		const { editorConfig, isEditorOpen } = this.state;

		const isNotEmpty = config || inheritedConfig;
		const isConfigInherited = !config && !!inheritedConfig;

		const ListItemComponent = ListItem as any; // temporary workaround because of bug in TS/MUI: https://github.com/mui-org/material-ui/issues/14971

		return <>
            <List className={classes.root}>
                <ListItemComponent button onClick={this.onItemClick}>
                    <ListItemAvatar>
                        <Avatar
                            style={
                                isNotEmpty
                                    ? { backgroundColor: theme.palette.primary.main }
                                    : {}
                            }
                        >
                            <Settings />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        data-testid="db-engine-config"
                        primary="Custom DB engine configuration"
                        secondary={
                            !isNotEmpty
                                ? "Empty"
                                : `${isConfigInherited ? "Inherited from cluster" : ""}`
                        }
                    />
                    {config && !readOnly && (
                        <ListItemSecondaryAction>
                            <Tooltip title={"Clear custom DB engine configuration"}>
                                <IconButton onClick={this.onRemoveClick} edge="end" aria-label="Delete" size="large">
                                    <Delete />
                                </IconButton>
                            </Tooltip>
                        </ListItemSecondaryAction>
                    )}
                </ListItemComponent>
            </List>
            <DbEngineConfigEditorComponent
                isDialogOpen={isEditorOpen}
                config={editorConfig}
                inheritedConfig={inheritedConfig}
                onChange={(config: string) => {
                    // console.log("on change", config);
                    this.setState({ editorConfig: config });
                }}
                onClose={() => {
                    this.setState({ isEditorOpen: false });
                }}
                onConfirm={() => {
                    this.setState({ isEditorOpen: false });
                    this.props.onChange && this.props.onChange(editorConfig);
                }}
                readOnly={readOnly}
                subtitleText={subtitleText}
            />
        </>;
	}
}

export default withStyles(styles, { withTheme: true })(
	DbEngineConfigButtonComponent
);
