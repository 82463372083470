import {
	NODE_CREATE_WIZARD_ACTION,
	NodeCreateWizardAction,
	NodeCreateWizardState
} from "pages/management/node/nodeDeploymentDialog/types";
import { cloneDeep } from "lodash";
import { DEFAULT_CLUSTER } from "pages/management/cluster/const";

const getInitialState = (): NodeCreateWizardState => {
	const initialState = {
		isOpen: false,
		cluster: DEFAULT_CLUSTER
	};

	return cloneDeep(initialState);
};

const initialState: NodeCreateWizardState = getInitialState();

export function nodeCreateWizardReducer(
	state: NodeCreateWizardState = getInitialState(),
	action: NodeCreateWizardAction
): NodeCreateWizardState {
	switch (action.type) {
		case NODE_CREATE_WIZARD_ACTION.SHOW:
			console.log("show node deployment wizard");
			return {
				...state,
				isOpen: true,
				cluster: action.payload.cluster
			};

		case NODE_CREATE_WIZARD_ACTION.HIDE:
			return initialState;

		default:
			return state;
	}
}
