import Axios, { AxiosResponse } from "axios";
import { DEFAULT_COLORS } from "components/logViewer/const";
import {
	ChartMetric,
	DEFAULT_PERIOD,
	DEFAULT_REFRESH_INTERVAL,
	MAX_NUM_OF_POINTS_IN_CHART
} from "components/monitoring/charts/const";
import {
	AxisRange,
	DateRange
} from "components/monitoring/charts/timeSeries/types";
import { AGGREGATION } from "components/monitoring/dashboard/types";
import { IResponse } from "influx";
import { IResponseSeries, IResultEntry, Row } from "influx/lib/src/results";
import { DateTime } from "luxon";
import { Cluster } from "pages/management/cluster/types";
import { Node } from "pages/management/node/types";
// @ts-ignore
import { scatter } from "plotly-js-material-design-theme";
import { Config } from "services/config/Config";

class ChartUtils {
	/**
	 * @desc fetches data from influx db
	 * @param {Cluster} cluster
	 * @param {AGGREGATION} aggregation
	 * @param {ChartMetric} metric
	 * @param {DateRange} dateRange
	 * @param {Node} node
	 * @returns {Promise<AxiosResponse<IResponse>>}
	 */
	static fetchData(
		cluster: Cluster,
		aggregation: AGGREGATION,
		metric: ChartMetric,
		dateRange: DateRange,
		node?: Node
	): {
		promise: Promise<AxiosResponse<IResponse>>;
		abortController: AbortController;
	} {
		const {
			influx_host: host,
			influx_port: port,
			influx_password: password,
			influx_protocol: protocol,
			influx_username: username,
			influx_db: db
		} = Config;

		const url = `${protocol}://${host}:${port}/query`;

		const params = {
			q: ChartUtils.buildQuery(
				cluster.name,
				metric,
				aggregation,
				dateRange,
				node?.name
			),
			db
		};

		const abortController = new AbortController();

		const promise = Axios.get(url, {
			auth: {
				username,
				password
			},
			params,
			signal: abortController.signal
		});

		return { promise, abortController };
	}

	/**
	 * @desc Builds query for fetching metrics from InfluxDB
	 * @param {string} clusterName
	 * @param {ChartMetric} metric
	 * @param {AGGREGATION} aggregation
	 * @param {string} dateRange
	 * @param {string} nodeName
	 * @returns {string}
	 */
	static buildQuery(
		clusterName: string,
		metric: ChartMetric,
		aggregation: AGGREGATION,
		dateRange: DateRange,
		nodeName?: string
	): string {
		const buildWhereStatement = (): string => {
			return `cluster = '${clusterName}' ${
				nodeName ? "AND node = '" + nodeName + "'" : ""
			} AND time > ${dateRange.from.toMillis()}000000 AND time < ${dateRange.to.toMillis()}000000`;
		};

		switch (aggregation) {
			case AGGREGATION.RAW:
				return `SELECT MAX(${metric.name}) FROM ${
					metric.table
				} WHERE ${buildWhereStatement()} GROUP BY node, time(${this.getGroupSeconds(
					dateRange
				)}s) fill(null)`;
			case AGGREGATION.DIFFERENTIAL:
				return `SELECT MAX("derivative") AS "max_derivative" FROM (SELECT DERIVATIVE(${
					metric.name
				}, 1s) AS "derivative" FROM ${
					metric.table
				} WHERE ${buildWhereStatement()}) GROUP BY node, time(${this.getGroupSeconds(
					dateRange
				)}s) fill(null)`;
			default:
				console.warn("Unknown aggregation value: ", aggregation);
				return "";
		}
	}

	/**
	 * @description calculates a period in seconds for influx db data to be grouped by
	 * 							so there will be max 1000 points assuming that there is
	 * 							1 data point per second
	 * @param dateRange
	 * @returns {number}
	 */
	static getGroupSeconds(dateRange: DateRange): number {
		const diffInSeconds = dateRange.to.toSeconds() - dateRange.from.toSeconds();
		return Math.ceil(diffInSeconds / MAX_NUM_OF_POINTS_IN_CHART);
	}

	/**
	 * @description parses data from InfluxDB and returns Plotly chart traces
	 * @param {IResponse} data
	 * @param {number} dataScaling
	 * @returns {any[]}
	 */
	static parseData(
		data: IResponse,
		dataScaling: number,
		aggregation: AGGREGATION = AGGREGATION.RAW
	) {
		let traces: any[] = [];

		data.results.forEach((result: IResultEntry) => {
			// console.log("result entries", result);

			result.series?.forEach((series: IResponseSeries, index: number) => {
				// console.log("result values", series);

				const node = series.tags ? series.tags["node"] : "unknown";

				// console.log("node name", node);

				let trace: any = {
					line: {
						width: 1.5,
						color: DEFAULT_COLORS[index % 10]
					},
					mode: "lines",
					type: "scatter",
					hoverinfo: "name+y+x",
					x: [],
					y: [],
					name: node,
					connectgaps: false
				};

				series.values?.forEach((row: Row) => {
					// trace.x.push(moment.unix(row[0]).toDate());
					// trace.y.push(row[1]);
					// console.log("row", row[0], new Date(row[0]), moment.unix(row[0]));

					const timestamp = row[0];
					// if aggregation is differential, limit value min to 0
					const value =
						aggregation === AGGREGATION.DIFFERENTIAL
							? Math.max(0, row[1])
							: row[1];

					trace.x.push(new Date(timestamp));
					if (row[1] === null) {
						trace.y.push(undefined);
					} else if (dataScaling) {
						trace.y.push(value * dataScaling);
					} else {
						trace.y.push(value);
					}
				});

				traces.push(scatter(trace));
			});
		});

		return traces;
	}

	/**
	 * @description Given last refresh timestamp, it returns interval in ms until
	 * 							the next supposed request. Used to maintain a consistent
	 * 							refresh interval
	 * @param {number} lastRequestTimestamp
	 * @returns {number} ms until next request
	 */
	static getRemainingIntervalTime(lastRequestTimestamp?: number): number {
		// console.log("getRemainingIntervalTime", lastRequestTimestamp);
		if (!lastRequestTimestamp) {
			return DEFAULT_REFRESH_INTERVAL;
		} else {
			// get current timestamp
			const now = DateTime.now().toMillis();
			const serverResponseTime = now - lastRequestTimestamp;
			const msUntilNextRequest = DEFAULT_REFRESH_INTERVAL - serverResponseTime;
			// console.log("msUntilNextRequest", msUntilNextRequest);

			if (msUntilNextRequest < 0) {
				// console.log(
				// 	`Server response time (${serverResponseTime}ms) is longer than refresh interval (${DEFAULT_REFRESH_INTERVAL}ms)`
				// );
			}
			// console.log("msUntilNextRequest", msUntilNextRequest);
			return Math.max(msUntilNextRequest, 0);
		}
	}

	/**
	 * @description returns axis title, depending if its raw data or differential (unit per sec)
	 * @param {AGGREGATION} aggregation
	 * @param {string} unit
	 * @returns {string}
	 */
	static getMeasurementUnit(
		aggregation: AGGREGATION = AGGREGATION.RAW,
		unit?: string
	): string {
		return unit
			? aggregation === AGGREGATION.DIFFERENTIAL
				? `${unit}/s`
				: unit
			: "";
	}

	/**
	 * @description returns message to be displayed in chart overlay
	 * @param {boolean} isThereAnyData
	 * @param {boolean} dragToMove
	 * @returns {string}
	 */
	static getMessage(isThereAnyData: boolean, dragToMove: boolean) {
		if (dragToMove) return "Drag to move";
		else if (!isThereAnyData) return "No data";
	}

	/**
	 * @description calculated x-axis range given period or zoom range
	 * 							it will primarily return zoom range if it exists to prevent resetting axis zoom
	 * @param {string} period
	 * @param axisRange
	 * @returns {any[]}
	 */
	static getPlotlyXAxisRange(
		period: string = DEFAULT_PERIOD,
		axisRange?: AxisRange
	) {
		return axisRange
			? [axisRange.x?.from, axisRange.x?.to]
			: [this.getPeriodStartDate(period), DateTime.now().plus({ hours: 1 })];
	}

	/**
	 * @description	calculates y-axis range given max or zoom range
	 * 							it will primarily return zoom range if it exists to prevent resetting axis zoom
	 * @param {number | undefined} yAxisMax
	 * @param axisRange
	 * @returns {any[]}
	 */
	static getPlotlyYAxisRange(
		yAxisMax: number | undefined,
		axisRange?: AxisRange
	) {
		return axisRange
			? [axisRange.y?.from || 0, axisRange.x?.from || yAxisMax]
			: [0, yAxisMax];
	}

	/**
	 * @desc parses plotly's date time string format into luxon DateTime object
	 * @param {string} dateTime
	 * @returns {DateTime}
	 */
	static parsePlotlyDateTime(dateTime: string): DateTime {
		// console.log("dateTime", dateTime);
		return DateTime.fromFormat(dateTime.split(".")[0], "yyyy-MM-dd HH:mm:ss");
	}

	/**
	 * @desc returns start date and time of specific period
	 * @param {string} period
	 * @returns {DateTime}
	 */
	static getPeriodStartDate(period: string): DateTime {
		const amount = parseInt(period.substring(0, period.length - 1));
		const unit = period[period.length - 1];

		switch (unit) {
			case "m":
				return DateTime.now().minus({ minutes: amount });
			case "h":
				return DateTime.now().minus({ hours: amount });
			case "d":
				return DateTime.now().minus({ days: amount });
			default:
				console.warn("Unrecognized time period:", period);
				return DateTime.now().minus({ minutes: 5 });
		}
	}

	/**
	 * @desc returns date range for specific period
	 * @param {string} period
	 * @returns {DateRange}
	 */
	static getDateRange(period: string = "5m"): DateRange {
		return {
			from: this.getPeriodStartDate(period),
			to: DateTime.now()
		};
	}
}

export default ChartUtils;
