import React from "react";
// MUI
import {
	AppBar,
	Box,
	CssBaseline,
	Grid,
	IconButton,
	Toolbar,
	Typography
} from "@mui/material";
import { WithStyles, WithTheme } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { AppState } from "AppState";
// icons
import {
	ArrowBack as ArrowBackIcon,
	Menu as MenuIcon
} from "@mui/icons-material";
// assets
// import gc_logo from "assets/images/gc_logo.png";
import logo from "assets/images/gc_logo_orange.png";
import AboutPage from "pages/about/AboutPage";
// components
import ClusterCreateDialogComponent from "pages/management/cluster/clusterCreateWizard/ClusterCreateDialogComponent";
import ClusterManagerComponent from "pages/management/cluster/ClusterManagerComponent";
import HostManagerComponent from "pages/management/host/HostManagerComponent";
import NodeDeploymentWizardComponent from "pages/management/node/nodeDeploymentDialog/NodeDeploymentDialogComponent";
import NodeManagerComponent from "pages/management/node/NodeManagerComponent";
import TreeViewComponent from "pages/management/treeView/TreeViewComponent";
import { showSnackbar } from "components/snackbar/actionCreators";
import { SnackbarActionPayload } from "components/snackbar/types";
import SystemLogComponent from "pages/systemLog/SystemLogComponent";
import UserCreateWizard from "pages/users/userCreateWizard/UserCreateWizard";
import UserListComponent from "pages/users/UserListComponent";
import UserManagerComponent from "pages/users/UserManagerComponent";
import Sidebar from "components/sidebar/Sidebar";
import ConnectionStatusComp from "components/monitoring/connectionStatus/ConnectionStatusComp";
// ...
import { logout } from "store/auth/actions";
import SecurePreloader from "components/preloader/SecurePreloaderComponent";
import { SecurePreloaderState } from "components/preloader/types";
// redux
import { connect } from "react-redux";
// react router
import { StaticContext } from "react-router";
import {
	Redirect,
	Route,
	RouteComponentProps,
	Switch,
	withRouter
} from "react-router-dom";
// css
import { styles } from "pages/root/styles";
import {
	drawerWidth,
	drawerMiniWidth,
	drawerMobile
} from "components/sidebar/styles";
// utility
import { checkIfDesktop } from "utils/checkIfDesktop";

interface PropsWindow {
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	window?: () => Window;
}

interface LocalState {
	isDrawerOpen: boolean;
	showBackButton: boolean;
	mobileOpen: boolean;
	width: number;
}

interface LocalProps {}

interface ReduxStateProps {
	securePreloader: SecurePreloaderState;
	isNodeDeploymentWizardOpen: boolean;
}

interface ReduxDispatchProps {
	showSnackbar: (snackbar: SnackbarActionPayload) => void;
	logout: () => void;
}

type Props = PropsWindow &
	LocalProps &
	ReduxDispatchProps &
	ReduxStateProps &
	WithStyles<typeof styles> &
	WithTheme &
	RouteComponentProps<any, StaticContext, any>;

class RootComponent extends React.Component<Props, LocalState> {
	constructor(props: Props) {
		super(props);

		this.state = {
			isDrawerOpen: true,
			showBackButton: false,
			mobileOpen: false,
			width: window.innerWidth
		};
		this.updateDimensions = this.updateDimensions.bind(this);
		this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
	}

	componentDidMount() {
		const sidebarStatus = localStorage.getItem("sidebarStatus") === "true";

		this.setState({ isDrawerOpen: sidebarStatus });

		window.addEventListener("resize", this.updateDimensions);
	}

	componentDidUpdate(prevProps: Props) {
		if (this.props.location !== prevProps.location) {
			this.updateShowBackButton();
		}
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateDimensions);
	}

	updateDimensions() {
		this.setState({
			width: window.innerWidth
		});
	}

	updateShowBackButton() {
		const { pathname } = this.props.location;

		// console.log("updateShowBackButton", pathname);

		this.setState(
			(state: LocalState): LocalState => ({
				...state,
				showBackButton: pathname.split("/").length > 2
			})
		);
	}

	handleCloseMobileSidebar = () => {
		this.setState(
			(state: LocalState): LocalState => ({
				...state,
				mobileOpen: false
			})
		);
	};

	handleDrawerToggle = (isDesktop: boolean) => {
		if (isDesktop) {
			// Only in desktop we want to save if sidebar is small or full width
			// "" + ====> transforms boolean into string, so it can be saved in localStorage
			localStorage.setItem("sidebarStatus", "" + !this.state.isDrawerOpen);

			this.setState(
				(state: LocalState): LocalState => ({
					...state,
					isDrawerOpen: !this.state.isDrawerOpen
				})
			);
		} else {
			this.setState(
				(state: LocalState): LocalState => ({
					...state,
					isDrawerOpen: true,
					mobileOpen: !this.state.mobileOpen
				})
			);
		}
	};

	logOut = async () => {
		// todo: handle logging out loader and logout errors
		this.props.logout();

		// try {
		// 	await Auth.logout();
		// 	await Auth.clearJWTTokens();
		//
		// 	this.props.endUserSession();
		// 	this.history.push("/login");
		// } catch (e: any) {
		// 	console.error("Logout failed:", e);
		// 	GMDialogService.showInfo({ message: e.message, title: "Logout failed" });
		// }
	};

	render() {
		const { classes, securePreloader, isNodeDeploymentWizardOpen, theme } =
			this.props;
		const { showBackButton, isDrawerOpen, mobileOpen } = this.state;

		const isDesktop = checkIfDesktop(1200); // 1200 is size "lg" breakpoint

		const mainPaddingLeft = !isDesktop
			? drawerMobile
			: this.state.isDrawerOpen
			? `${drawerWidth}px`
			: `${drawerMiniWidth}px`;

		return (
			<>
				{!securePreloader.isPreloadDone ? (
					<Box height="100%">
						<SecurePreloader />
					</Box>
				) : (
					<Box className={classes.root} height="100%">
						<CssBaseline />
						<AppBar position="fixed" className={classes.appBar}>
							<Toolbar variant="dense">
								{theme.breakpoints.up("sm") ? (
									<IconButton
										id="MainMenuButton"
										color="inherit"
										aria-label="Open drawer"
										onClick={() => this.handleDrawerToggle(isDesktop)}
										className={classes.menuButton}
										size="large"
									>
										<MenuIcon />
									</IconButton>
								) : (
									<>
										{showBackButton ? (
											<IconButton
												id="GoBackButton"
												color="inherit"
												aria-label="Go back"
												onClick={() => {
													this.props.history.goBack();
												}}
												className={classes.menuButton}
												size="large"
											>
												<ArrowBackIcon />
											</IconButton>
										) : (
											<IconButton
												id="MainMenuButton"
												color="inherit"
												aria-label="Open drawer"
												onClick={() => this.handleDrawerToggle(isDesktop)}
												className={classes.menuButton}
												size="large"
											>
												<MenuIcon />
											</IconButton>
										)}

										<img
											className="logo"
											src={logo}
											height="30px"
											alt="Galera Cluster Logo"
										/>
									</>
								)}

								<Typography variant="h6" color="inherit" noWrap>
									Galera Manager
								</Typography>

								{/* display backend and influxDB connection status */}
								<ConnectionStatusComp />
							</Toolbar>
						</AppBar>

						<Sidebar
							isDrawerOpen={isDrawerOpen}
							mobileOpen={mobileOpen}
							handleDrawerToggle={() => this.handleDrawerToggle(isDesktop)}
							handleCloseMobileSidebar={this.handleCloseMobileSidebar}
						/>

						<Grid
							container
							direction="column"
							component="main"
							className={classes.content}
						>
							{
								<Grid container direction="column">
									<Grid item style={{ height: 58 }} />

									<Grid
										item
										id="container"
										container
										direction="row"
										xs
										spacing={2}
										sx={{
											paddingLeft: mainPaddingLeft
										}}
									>
										<Route exact path="/">
											<Redirect to="/clusters" />
										</Route>
										{/*Dialogs*/}
										<Route
											path={["/clusters", "/nodes", "/hosts"]}
											component={ClusterCreateDialogComponent}
										/>

										<Route path="/users" component={UserCreateWizard} />

										{/*Display tree view for cluster management*/}
										<Grid item>
											<Route path="/users" component={UserListComponent} />
											<Route
												path={["/clusters", "/nodes", "/hosts"]}
												component={TreeViewComponent}
											/>
										</Grid>

										{/*Details pages*/}
										<Grid item xs>
											<Switch>
												<Route
													path="/clusters/:clusterID"
													exact
													component={ClusterManagerComponent}
												/>
												<Route
													path="/clusters/:clusterID/nodes/:nodeID"
													exact
													component={NodeManagerComponent}
												/>
												<Route
													path="/clusters/:clusterID/hosts/:hostID"
													exact
													component={HostManagerComponent}
												/>
												<Route
													path="/users/:name" // todo: change to /users/:userID to be consistent with cluster management routing
													exact
													component={UserManagerComponent}
												/>
												<Route
													path="/system-log"
													exact
													component={SystemLogComponent}
												/>
												<Route path="/about" exact component={AboutPage} />
												{/* <Route path="/changelog" exact component={...} /> */}
											</Switch>
										</Grid>
									</Grid>
								</Grid>
							}
						</Grid>
						{isNodeDeploymentWizardOpen && <NodeDeploymentWizardComponent />}
					</Box>
				)}
			</>
		);
	}
}

// REDUX MAPPINGS
const mapGlobalStateToProps = (state: AppState) => ({
	isNodeDeploymentWizardOpen: state.nodeCreateWizard.isOpen,
	securePreloader: state.preloader.securePreloader
});

const mapGlobalDispatchToProps = (dispatch: any) => {
	return {
		showSnackbar: (snackbar: SnackbarActionPayload) => {
			dispatch(showSnackbar(snackbar));
		},
		logout: () => {
			dispatch(logout());
		}
	};
};

export default withStyles(styles, { withTheme: true })(
	withRouter(
		connect(mapGlobalStateToProps, mapGlobalDispatchToProps)(RootComponent)
	)
);
