import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';

export const styles = (theme: Theme) =>
	createStyles({
		jobDoneIcon: {
			color: "green"
		},
		jobRunningIcon: {
			color: theme.palette.primary.main,
			animation: "$blinker 1.2s infinite ease-in-out"
		},
		"@keyframes blinker": {
			"50%": {
				opacity: 0.5
			}
		},
		jobPendingIcon: {
			color: "grey"
		},
		jobCancelledIcon: {
			color: "grey"
		},
		jobErrorIcon: {
			color: "red"
		},
		actions: {
			marginLeft: "8px",
			marginBottom: "5px"
		},
		lastLogText: {
			fontSize: "0.7em",
			fontFamily: "monospace",
			backgroundColor: "black",
			color: "white",
			padding: "2px",
			cursor: "pointer"
		},
		dialogTitle: {
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2)
		},
		logContent: {
			backgroundColor: "black",
			color: "white"
		},
		logsTypography: {
			fontSize: "0.9em",
			fontFamily: "monospace"
		},
		closeButton: {
			position: "absolute",
			right: theme.spacing(2),
			top: theme.spacing(2),
			color: theme.palette.grey[500]
		},
		galeraManagerLogMessage: {
			color: theme.palette.primary.main
		},
		hostStderrMessage: {
			color: theme.palette.error.main
		}
	});
