import { MoreVert } from "@mui/icons-material";
import {
	Button,
	FormControl,
	Grid,
	IconButton,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Select
} from "@mui/material";
import Menu from "@mui/material/Menu";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { AppState } from "AppState";
import { Cluster } from "pages/management/cluster/types";
import { Node } from "pages/management/node/types";
import TimeSeriesChartComponent from "components/monitoring/charts/timeSeries/TimeSeriesChartComponent";
import AddElement from "components/monitoring/dashboard/AddElement/AddElement";
import ChartActionsComponent from "components/monitoring/dashboard/ChartActions/ChartActionsComponent";
import { DASHBOARD_PERIOD_SELECT_OPTIONS } from "components/monitoring/dashboard/const";
import {
	Chart,
	Dashboard,
	DASHBOARD_TILE_SIZE,
	DashboardConfig
} from "components/monitoring/dashboard/types";
import DashboardUtils from "components/monitoring/dashboard/utils";
import elementResizeDetectorMaker from "element-resize-detector";
import { isEqual } from "lodash";
import { ChartLine, Download, Pencil, Plus } from "mdi-material-ui";
import { Component } from "react";
import { dashboardSave } from "store/dashboard/actions";
import {
	dashboardSaveErrorMsgSelector,
	dashboardSaveInProgressSelector,
	dashboardSelector
} from "store/dashboard/selectors";
import GridLayout, { Layout } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { connect } from "react-redux";
import { v4 as uuid } from "uuid";
import { styles } from "./styles";

import "./styles.css";

interface LocalState {
	dragInProgress: boolean;
	isAddElementDialogOpen: boolean;
	width: number;
	menuTarget?: any;
	isEditEnabled: boolean;
	temporaryDashboard?: Dashboard;
}

export interface LocalProps {
	cluster: Cluster;
	node?: Node;
}

interface ReduxProps {
	dashboard: Dashboard;
}

interface ReduxDispatch {
	saveDashboard: (dashboard: Dashboard) => void;
}

type Props = LocalProps &
	WithStyles<typeof styles> &
	ReduxProps &
	ReduxDispatch;

class DashboardComponent extends Component<Props, LocalState> {
	_isMounted: boolean = false;

	constructor(props: Props) {
		super(props);

		this.state = {
			width: 1000,
			dragInProgress: false,
			isAddElementDialogOpen: false,
			isEditEnabled: false
		};
	}

	componentDidMount(): void {
		this._isMounted = true;

		const elementResizeDetector = elementResizeDetectorMaker({
			strategy: "scroll" //<- For ultra performance.
		});

		const monitoringPageContainer = document.getElementById(
			"MonitoringPageContainer"
		);

		if (monitoringPageContainer) {
			// listener for handling dashboard width change
			elementResizeDetector.listenTo(
				monitoringPageContainer,
				(element: HTMLElement) => {
					const width = element.offsetWidth;
					if (this.state.width !== width) {
						// console.log("Size:" + width);
						this.setState(
							(state: LocalState): LocalState => ({
								...state,
								width
							})
						);
					}
				}
			);
		}
	}

	componentWillUnmount(): void {
		this._isMounted = false;
	}

	componentDidUpdate(
		prevProps: Readonly<Props>,
		prevState: Readonly<LocalState>,
		snapshot?: any
	) {
		// if cluster or node changed, discard changes
		if (
			!isEqual(prevProps.cluster, this.props.cluster) ||
			!isEqual(prevProps.node, this.props.node)
		) {
			this.discardChanges();
		}
	}

	onConfigChanged(config: Partial<DashboardConfig>, isEditEnabled: boolean) {
		if (isEditEnabled) {
			this.setState((state: LocalState) => {
				if (state.temporaryDashboard) {
					return {
						temporaryDashboard: {
							...state.temporaryDashboard,
							config: {
								...state.temporaryDashboard.config,
								...config
							}
						}
					};
				} else {
					console.warn("No temporary dashboard. Something went wrong...");
					return {};
				}
			});
		} else {
			this.saveDashboard({
				...this.props.dashboard,
				config: {
					...this.props.dashboard.config,
					...config
				}
			});
		}
	}

	saveDashboard(dashboard: Dashboard) {
		console.log("saveDashboard", dashboard);

		this.props.saveDashboard(dashboard);
	}

	discardChanges() {
		this.setState({
			isEditEnabled: false,
			temporaryDashboard: undefined
		});
	}

	render() {
		const {
			isAddElementDialogOpen,
			width,
			menuTarget,
			isEditEnabled,
			temporaryDashboard
		} = this.state;

		const { classes, cluster, node, dashboard: storedDashboard } = this.props;

		const dashboard = temporaryDashboard || storedDashboard;
		// console.log(dashboard);

		const { columns, rowHeight } = DashboardUtils.getGridSize(
			dashboard.config.tileSize
		);

		const moreMenu = (
			<>
				<IconButton
					onClick={(event: any) => {
						this.setState({ menuTarget: event.target });
					}}
				>
					<MoreVert />
				</IconButton>
				<Menu
					id="long-menu"
					anchorEl={menuTarget}
					open={Boolean(menuTarget)}
					onClose={() => {
						this.setState({ menuTarget: null });
					}}
				>
					<MenuItem
						onClick={() => {
							this.setState({
								isAddElementDialogOpen: true,
								menuTarget: null
							});
						}}
					>
						<ListItemIcon>
							<Plus />
						</ListItemIcon>
						<ListItemText primary="Add chart" />
					</MenuItem>
					<MenuItem
						divider={true}
						onClick={() => {
							this.setState({
								isEditEnabled: true,
								temporaryDashboard: { ...storedDashboard },
								menuTarget: null
							});
						}}
					>
						<ListItemIcon>
							<Pencil />
						</ListItemIcon>
						<ListItemText primary="Edit dashboard" />
					</MenuItem>
					<MenuItem dense={true} disabled={true}>
						<ListItemText primary="Chart size" />
					</MenuItem>
					<MenuItem
						selected={dashboard.config.tileSize === DASHBOARD_TILE_SIZE.SMALL}
						onClick={() => {
							this.onConfigChanged(
								{ tileSize: DASHBOARD_TILE_SIZE.SMALL },
								isEditEnabled
							);
						}}
					>
						<ListItemIcon>
							<ChartLine fontSize={"small"} />
						</ListItemIcon>
						<ListItemText primary="Small" />
					</MenuItem>
					<MenuItem
						selected={dashboard.config.tileSize === DASHBOARD_TILE_SIZE.MEDIUM}
						onClick={() => {
							this.onConfigChanged(
								{ tileSize: DASHBOARD_TILE_SIZE.MEDIUM },
								isEditEnabled
							);
						}}
					>
						<ListItemIcon>
							<ChartLine fontSize={"medium"} />
						</ListItemIcon>
						<ListItemText primary="Medium" />
					</MenuItem>
					<MenuItem
						divider={true}
						selected={dashboard.config.tileSize === DASHBOARD_TILE_SIZE.LARGE}
						onClick={() => {
							this.onConfigChanged(
								{ tileSize: DASHBOARD_TILE_SIZE.LARGE },
								isEditEnabled
							);
						}}
					>
						<ListItemIcon>
							<ChartLine fontSize={"large"} />
						</ListItemIcon>
						<ListItemText primary="Large" />
					</MenuItem>

					<MenuItem
						onClick={() => {
							const file = new Blob([JSON.stringify(dashboard)], {
								type: "application/text"
							});
							let a = document.createElement("a");
							a.href = URL.createObjectURL(file);
							a.download = `${dashboard.name}.json`;
							a.click();

							a.remove();

							this.setState({
								menuTarget: null
							});
						}}
					>
						<ListItemIcon>
							<Download />
						</ListItemIcon>
						<ListItemText primary="Download dashboard configuration" />
					</MenuItem>
				</Menu>
			</>
		);

		return (
			<>
				<AddElement
					cluster={cluster}
					node={node}
					isOpen={isAddElementDialogOpen}
					onCancelClick={() => {
						this.setState({
							isAddElementDialogOpen: false
						});
					}}
					onAddClick={(chart: Chart) => {
						// console.log("onAddClick", chart);

						const newChart: Chart = {
							...chart,
							title: chart.title || chart.metric.name,
							id: uuid(),
							position: dashboard.config.charts.length
						};

						// console.log("newChart", newChart);

						const charts = [...dashboard.config.charts, newChart];

						this.onConfigChanged({ charts }, isEditEnabled);
						this.setState({ isAddElementDialogOpen: false });
					}}
				/>
				<Grid
					id="MonitoringPageContainer"
					container
					direction="column"
					component="div"
				>
					<Grid
						component="div"
						item
						container
						direction="row"
						spacing={2}
						className={classes.toolbar}
						justifyContent="flex-end"
					>
						{isEditEnabled ? (
							<>
								<Grid item>
									<Button
										variant="outlined"
										color="error"
										onClick={() => {
											this.discardChanges();
										}}
									>
										Discard changes
									</Button>
								</Grid>
								<Grid item>
									<Button
										variant="outlined"
										color="success"
										onClick={() => {
											this.setState({
												isEditEnabled: false,
												temporaryDashboard: undefined
											});
											this.saveDashboard(dashboard);
										}}
									>
										Save
									</Button>
								</Grid>
							</>
						) : (
							<>
								<Grid item>
									<form>
										<FormControl className={classes.formControl}>
											<Select
												classes={{
													select: classes.selectMenu
												}}
												// disableUnderline={true} // TODO: MUI documentation doesn't show this attribute
												value={dashboard.config.period}
												onChange={(event) => {
													const value = event.target.value as string;
													// this.onPeriodChange(value);
													console.log("onPeriodChange", value);
													this.onConfigChanged(
														{ period: value },
														isEditEnabled
													);
												}}
											>
												{DASHBOARD_PERIOD_SELECT_OPTIONS.map(
													(option: string) => (
														<MenuItem
															key={option}
															className={classes.selectOption}
															value={option}
														>
															{`Last ${option}`}
														</MenuItem>
													)
												)}
											</Select>
										</FormControl>
									</form>
								</Grid>
								<Grid item>{moreMenu}</Grid>
							</>
						)}
					</Grid>
					<Grid component="div" item>
						<GridLayout
							className={this._isMounted ? "animated" : ""}
							verticalCompact={true}
							compactType="vertical"
							isDraggable={isEditEnabled}
							isResizable={false}
							// className="layout"
							layout={dashboard.config.charts.map((chart: Chart): Layout => {
								const coordinates = DashboardUtils.getCoordinates(
									dashboard.config.tileSize,
									chart.position
								);

								// console.log("chart layout", chart.position, layout);
								return {
									...coordinates,
									i: chart.id
								};
							})}
							cols={columns}
							rowHeight={rowHeight}
							width={width}
							margin={[0, 0]}
							onLayoutChange={(layout: Layout[]) => {
								if (
									dashboard.config.charts.length !== layout.length ||
									!isEditEnabled
								) {
									return;
								}

								// console.log("dash", dashboard);
								// console.log("layout updated", layout);

								let changed = false;

								const updatedCharts = dashboard.config.charts.map(
									(chart: Chart) => {
										const chartLayout = layout.find(
											(layout: Layout) => layout.i === chart.id
										);

										if (chartLayout) {
											const newPosition = DashboardUtils.getChartPosition(
												chartLayout,
												dashboard.config.tileSize
											);

											changed = changed || chart.position !== newPosition;

											return {
												...chart,
												position: newPosition
											};
										}
										return chart;
									}
								);

								// update charts in state if any position changed
								if (changed) {
									console.log("updating");
									this.setState({
										temporaryDashboard: {
											...dashboard,
											config: {
												...dashboard.config,
												charts: updatedCharts
											}
										}
									});
								}
							}}
							onDragStart={() => {
								this.setState(
									(state: LocalState): LocalState => ({
										...state,
										dragInProgress: true
									})
								);
							}}
							onDragStop={() => {
								this.setState(
									(state: LocalState): LocalState => ({
										...state,
										dragInProgress: false
									})
								);
							}}
						>
							{dashboard.config.charts.map((chart: Chart) => {
								return (
									<div key={chart.id}>
										{isEditEnabled && (
											<ChartActionsComponent
												onRemoveClick={() => {
													console.log("on remove click");

													const charts = dashboard.config.charts.filter(
														(c: Chart) => c.id !== chart.id
													);

													this.onConfigChanged({ charts }, isEditEnabled);
												}}
											/>
										)}
										<TimeSeriesChartComponent
											key={chart.id}
											period={dashboard.config.period}
											cluster={cluster}
											node={node}
											metric={chart.metric}
											title={chart.title}
											disableRender={false}
											dragToMove={isEditEnabled}
											aggregation={chart.aggregation}
											unit={chart.unit}
											dataScaling={chart.dataScaling}
											yAxisMax={chart.yAxisMax}
											tileSize={dashboard.config.tileSize}
										/>
									</div>
								);
							})}
						</GridLayout>
					</Grid>
				</Grid>
			</>
		);
	}
}

// REDUX MAPPINGS
const mapGlobalStateToProps = (state: AppState, props: LocalProps) => {
	return {
		dashboard: dashboardSelector(
			state,
			DashboardUtils.getDashboardName(props.cluster, props.node)
		),
		isSaveInProgress: dashboardSaveInProgressSelector(state),
		saveErrorMsg: dashboardSaveErrorMsgSelector(state)
	};
};

const mapGlobalDispatchToProps = (dispatch: any) => {
	return {
		saveDashboard: (dashboard: Dashboard) => {
			dispatch(dashboardSave(dashboard));
		}
	};
};

export default withStyles(styles, { withTheme: true })(
	connect(mapGlobalStateToProps, mapGlobalDispatchToProps)(DashboardComponent)
);
