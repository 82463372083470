import Axios, { AxiosResponse } from "axios";
import AppEnvironment from "services/appEnvironment";

class EnvAPI {
	static async fetchKey(key: string): Promise<AxiosResponse> {
		return await Axios.get(
			`${AppEnvironment.getApiAddress()}/config/env/${key}`
		);
	}

	static async fetchPublicKey(key: string): Promise<AxiosResponse> {
		return await Axios.get(
			`${AppEnvironment.getApiAddress()}/config/env-public/${key}`
		);
	}
}

export default EnvAPI;
