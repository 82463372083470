import createStyles from "@mui/styles/createStyles";

export const styles = () =>
	createStyles({
		progress: {
			marginTop: "-50px",
			position: "absolute",
			top: 80,
			right: 5,
			zIndex: 1
		},
		progressFadeIn: {
			opacity: 1,
			transition: "opacity .2s 1s"
		},
		progressFadeOut: {
			opacity: 0,
			transition: "opacity 0.5s"
		}
	});
