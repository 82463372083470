import { VERSION_ACTION, VersionGetFailedAction, VersionGetSuccessAction } from "store/version/types";

export const versionGetSuccessAction = (
	version: string
): VersionGetSuccessAction => ({
	type: VERSION_ACTION.GET_SUCCESS,
	payload: {
		version
	}
});

export const versionGetFailedAction = (
	errorMsg: string
): VersionGetFailedAction => ({
	type: VERSION_ACTION.GET_FAILED,
	payload: {
		errorMsg
	}
});