import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';

export const styles = (theme: Theme) =>
	createStyles({
		root: {
			width: "100%"
		},
		activeRuleAvatar: {
			backgroundColor: theme.palette.primary.main
		}
	});
