import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		paddingLeft: 8,
		paddingRight: 8,
		background: "rgb(30, 30, 30) none repeat scroll 0% 0%",
		borderRadius: 4
	}
}));

export default useStyles;
