import {
	VERSION_ACTION,
	VersionAction,
} from "store/version/types";

export const defaultState: string = "Loading..."

export function versionReducer(
	state: string = defaultState,
	action: VersionAction
): string {
	switch (action.type) {
		case VERSION_ACTION.GET_SUCCESS:
			return action.payload.version

		case VERSION_ACTION.GET_FAILED:
			return action.payload.errorMsg;

		default:
			return state;
	}
}
