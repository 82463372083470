export enum JOB_STATUS {
	MISSING = "missing",
	NEW = "new",
	RUNNING = "running",
	SUCCESS = "success",
	FAILURE = "failure",
	ABORTED = "aborted"
}

export interface Wrapped {
	msg: string;
	stackTrace?: string[];
	wrapped?: Wrapped;
}

export interface Meta {
	cluster_id?: number;
	cluster_name?: string;
	host_id?: number;
	host_name?: string;
	node_id?: number;
	node_name?: string;
}

export interface JobTracking {
	id: number;
	status: JOB_STATUS;
	meta: Meta;
}

export interface Job {
	id: number;
	description: string;
	parentId: number;
	executionInfo: {
		status: JOB_STATUS;
		details: string;
		error?: Wrapped;
		payload: any;
	};
	createdAt: string;
	stoppedAt: string;
	meta: Meta;
}

export interface SerializedJob {
	attributes: Job;
	type: "jobs";
	id?: string;
}

export type JobType = "host" | "node" | "cluster" | undefined;
