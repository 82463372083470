import {
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField
} from "@mui/material";
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { AppState } from "AppState";
import React, { ChangeEvent, FormEvent } from "react";
import { connect } from "react-redux";
import { styles } from "pages/users/styles";
import { RouteComponentProps, StaticContext, withRouter } from "react-router";
import { User, USER_ROLE } from "pages/users/types";
import { NewUser } from "pages/users/userCreateWizard/types";

// component local state interface
interface LocalState {
	user: User | NewUser;
	formValidation: {
		usernameError: string;
		passwordError: string;
	};
}

interface LocalProps {
	user: User | NewUser;
	onSubmit?: (user: User | NewUser) => void;
	readOnly?: boolean;
}

// PROPS
interface ReduxStateProps {}

interface ReduxDispatchProps {}

type Props = LocalProps &
	ReduxStateProps &
	ReduxDispatchProps &
	WithStyles<typeof styles> &
	RouteComponentProps<any, StaticContext, any>;

interface Snapshot {
	hasUserChanged: boolean;
}

// COMPONENT
class UserFormComponent extends React.Component<Props, LocalState> {
	constructor(props: Props) {
		super(props);

		console.log("userForm", props);
		this.state = {
			user: props.user,
			formValidation: {
				usernameError: "",
				passwordError: ""
			}
		};
	}

	getSnapshotBeforeUpdate(
		prevProps: Readonly<Props>,
		prevState: Readonly<LocalState>
	): Snapshot {
		return {
			hasUserChanged:
				prevProps.match.params.name !== this.props.match.params.name
		};
	}

	componentDidUpdate(
		prevProps: Readonly<Props>,
		prevState: Readonly<LocalState>,
		snapshot?: Snapshot
	): void {
		if (snapshot && snapshot.hasUserChanged) {
			this.setState({
				user: this.props.user
			});
		}
	}

	onSubmit = (e: FormEvent) => {
		e.preventDefault();
		// console.log("onChange", e);
		if (this.props.readOnly !== false && this.props.onSubmit) {
			this.props.onSubmit(this.state.user);
		}
	};

	render(): React.ReactNode {
		const { readOnly } = this.props;
		const { user } = this.state;

		return (
			<form id="userForm" onSubmit={this.onSubmit}>
				<Grid container direction="column" spacing={2}>
					<Grid container item direction="column">
						<Grid container item direction="row" spacing={2}>
							<Grid item xs={12} sm={6}>
								<FormControl
									onInvalid={(e: FormEvent): void => {
										e.preventDefault();
										const form = e.target as HTMLFormElement;

										this.setState((state: LocalState) => ({
											...state,
											formValidation: {
												...state.formValidation,
												usernameError: form.validationMessage
											}
										}));
									}}
									error={!!this.state.formValidation.usernameError}
									fullWidth={true}
									required
								>
									<TextField
										label="Username"
										error={!!this.state.formValidation.usernameError}
										autoFocus={!readOnly}
										required
										autoComplete="off"
										inputProps={{
											readOnly: readOnly,
											maxLength: 30
										}}
										margin="dense"
										name="username"
										helperText={this.state.formValidation.usernameError}
										value={user.name}
										onChange={(e: ChangeEvent) => {
											const field = e.target as HTMLFormElement;

											this.setState((state: LocalState) => ({
												user: {
													...state.user,
													name: field.value
												}
											}));

											if (field.checkValidity()) {
												this.setState((state: LocalState) => ({
													formValidation: {
														...state.formValidation,
														usernameError: ""
													}
												}));
											} else {
												this.setState((state: LocalState) => ({
													...state,
													formValidation: {
														...state.formValidation,
														usernameError: field.validationMessage
													}
												}));
											}
										}}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6}>
								<FormControl
									onInvalid={(e: FormEvent): void => {
										e.preventDefault();
										const form = e.target as HTMLFormElement;

										this.setState((state: LocalState) => ({
											...state,
											formValidation: {
												...state.formValidation,
												passwordError: form.validationMessage
											}
										}));
									}}
									error={!!this.state.formValidation.passwordError}
									fullWidth={true}
									required
								>
									<TextField
										label="Password"
										error={!!this.state.formValidation.passwordError}
										required
										autoComplete="off"
										inputProps={{
											readOnly: readOnly,
											maxLength: 30,
											type: "password"
										}}
										margin="dense"
										name="password"
										helperText={this.state.formValidation.passwordError}
										value={user.password}
										onChange={(e: ChangeEvent) => {
											const field = e.target as HTMLFormElement;

											this.setState((state: LocalState) => ({
												user: {
													...state.user,
													password: field.value
												}
											}));

											if (field.checkValidity()) {
												this.setState((state: LocalState) => ({
													formValidation: {
														...state.formValidation,
														passwordError: ""
													}
												}));
											} else {
												this.setState((state: LocalState) => ({
													...state,
													formValidation: {
														...state.formValidation,
														passwordError: field.validationMessage
													}
												}));
											}
										}}
									/>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					<Grid container item direction="column">
						<Grid item sm={6} xs={12}>
							<FormControl fullWidth={true} margin="dense">
								<InputLabel htmlFor="node-db-engine">Role</InputLabel>
								<Select
									fullWidth={true}
									readOnly={readOnly}
									value={user.role}
									onChange={(e) => {
										const value = e.target.value as USER_ROLE;

										this.setState((state: LocalState) => ({
											user: {
												...state.user,
												role: value
											}
										}));
									}}
									inputProps={{
										id: "node-db-engine"
									}}
								>
									<MenuItem value={USER_ROLE.NONE}>None</MenuItem>
									<MenuItem value={USER_ROLE.OBSERVER}>Observer</MenuItem>
									<MenuItem value={USER_ROLE.MANAGER}>Manager</MenuItem>
									<MenuItem value={USER_ROLE.ADMINISTRATOR}>
										Administrator
									</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					</Grid>
				</Grid>
			</form>
		);
	}
}

// REDUX MAPPINGS
const mapGlobalStateToProps = (state: AppState) => ({});

const mapGlobalDispatchToProps = (dispatch: any) => ({});

export default withStyles(styles, { withTheme: true })(
	withRouter(
		connect(mapGlobalStateToProps, mapGlobalDispatchToProps)(UserFormComponent)
	)
);
