import {
	DescribeInstanceTypesCommand,
	DescribeRegionsCommand,
	DescribeRegionsCommandOutput,
	EC2Client,
	InstanceTypeInfo,
	Region
} from "@aws-sdk/client-ec2";

export default class AWSUtils {
	static async fetchAvailableRegions(
		accessKeyId: string,
		secretAccessKey: string
	): Promise<Region[]> {
		const client = new EC2Client({
			region: "eu-central-1",
			credentials: {
				accessKeyId,
				secretAccessKey
			}
		});

		const fetchRegions = new DescribeRegionsCommand({ AllRegions: true });
		let availableRegions: Region[] = [];

		const data: DescribeRegionsCommandOutput = await client.send(fetchRegions);

		// console.log("EC2 available regions", data);

		client.destroy();
		availableRegions = data.Regions || [];
		// console.log("availableRegions", availableRegions);
		return availableRegions.sort((a: Region, b: Region) => {
			if (!a.RegionName) {
				return -1;
			} else if (!b.RegionName) {
				return 1;
			} else {
				return a.RegionName.toLowerCase().localeCompare(
					b.RegionName.toLowerCase()
				);
			}
		});
	}

	static async fetchAvailableInstanceTypes(
		accessKeyId: string,
		secretAccessKey: string,
		defaultRegion: string = "eu-central-1"
	): Promise<InstanceTypeInfo[]> {
		// console.log("fetchAvailableInstanceTypes", defaultRegion);
		const client = new EC2Client({
			region: defaultRegion,
			credentials: {
				accessKeyId,
				secretAccessKey
			}
		});

		const fetchInstanceTypes = new DescribeInstanceTypesCommand({});
		let availableInstanceTypes: InstanceTypeInfo[] = [];

		const data = await client.send(fetchInstanceTypes);
		// console.log("instance types data", data);

		data.InstanceTypes && availableInstanceTypes.push(...data.InstanceTypes);

		let nextToken = data.NextToken;

		while (nextToken) {
			const fetchNextInstanceTypes = new DescribeInstanceTypesCommand({
				NextToken: nextToken
			});
			const nextData = await client.send(fetchNextInstanceTypes);
			// console.log("instance types next", nextData);

			nextData.InstanceTypes &&
				availableInstanceTypes.push(...nextData.InstanceTypes);

			nextToken = nextData.NextToken;
		}

		client.destroy();

		return availableInstanceTypes.sort(
			(a: InstanceTypeInfo, b: InstanceTypeInfo) => {
				if (!a.InstanceType) {
					return -1;
				} else if (!b.InstanceType) {
					return 1;
				} else {
					// sort by prefix, then by # of VCPUs and then RAM
					const class1 = a.InstanceType.split(".")[0];
					const class2 = b.InstanceType.split(".")[0];
					if (class1 > class2) return 1;
					else if (class1 < class2) return -1;
					else {
						const vcpu1 = a.VCpuInfo?.DefaultVCpus || 0;
						const vcpu2 = b.VCpuInfo?.DefaultVCpus || 0;
						if (vcpu1 > vcpu2) return 1;
						else if (vcpu1 < vcpu2) return -1;
						else {
							const mem1 = a.MemoryInfo?.SizeInMiB || 0;
							const mem2 = b.MemoryInfo?.SizeInMiB || 0;

							return mem1 - mem2;
						}
					}
				}
			}
		);
	}
}
