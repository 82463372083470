import {
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField
} from "@mui/material";
import { Cluster } from "pages/management/cluster/types";
import ClusterUtils from "pages/management/cluster/utils";
import { Host, HOST_TYPE } from "pages/management/host/types";
import EC2ConfigComponent from "components/form/ec2Config/EC2ConfigComponent";
import SSHKeysEditor from "components/form/SSHKeysEditor/SSHKeysEditorComponent";
import { Component } from "react";
import { RouteComponentProps, StaticContext, withRouter } from "react-router";
import { INPUT_VARIANT } from "../../../../components/form/const";

interface LocalState {}

interface LocalProps {
	cluster: Cluster;
	host: Host;
}

// PROPS
interface ReduxStateProps {}

type Props = LocalProps &
	ReduxStateProps &
	RouteComponentProps<any, StaticContext, any>;

class HostFormComponent extends Component<Props, LocalState> {
	render() {
		const { cluster, host } = this.props;

		const clusterType = ClusterUtils.getClusterType(cluster);

		const readOnly = true;

		return (
			<>
				<form>
					{host && (
						<>
							<Grid
								container
								direction="column"
								sx={{
									maxWidth: 600,
									margin: "auto",
									marginBottom: "12px"
								}}
							>
								<Grid item xs={12}>
									<FormControl fullWidth={true} required>
										<TextField
											required
											label={"Host name"}
											variant="outlined"
											disabled={readOnly}
											inputProps={{
												minLength: 3,
												maxLength: 20,
												"data-testid": "stray-host-name"
											}}
											autoComplete="off"
											value={host.name}
										/>
									</FormControl>
								</Grid>

								<Grid item xs={12}>
									<FormControl
										required={true}
										fullWidth={true}
										variant={INPUT_VARIANT}
										margin="dense"
									>
										<InputLabel htmlFor="host-system">Host system</InputLabel>
										<Select
											required
											disabled={readOnly}
											label="Host system"
											data-testid="host-system-select-container"
											fullWidth={true}
											value={host.system}
											inputProps={{
												id: "host-system",
												"data-testid": "host-system-select"
											}}
										>
											<MenuItem key={host.system} value={host.system}>
												{host.system}
											</MenuItem>
										</Select>
									</FormControl>
								</Grid>

								<Grid item xs={12}>
									<FormControl fullWidth={true} required>
										<TextField
											fullWidth={true}
											type="number"
											required
											disabled={readOnly}
											variant="outlined"
											label="Segment"
											value={host.segment}
											inputProps={{
												id: "host-segment",
												"data-testid": "host-segment",
												min: 0
											}}
										/>
									</FormControl>
								</Grid>

								{host.type === HOST_TYPE.EC2 && host.hostTypeSpecific && (
									<Grid item xs={12}>
										<EC2ConfigComponent
											config={host.hostTypeSpecific}
											readOnly={readOnly}
											requirements={{
												minRAM: 1024
											}}
										/>
									</Grid>
								)}

								{host.type === HOST_TYPE.UNMANAGED && (
									<>
										<Grid item xs={12}>
											<FormControl fullWidth={true} required>
												<TextField
													fullWidth={true}
													required
													variant="outlined"
													label="SSH Address"
													value={host.ssh && host.ssh.address}
													inputProps={{ readOnly }}
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12}>
											<FormControl fullWidth={true} required>
												<TextField
													fullWidth={true}
													type="number"
													required
													variant="outlined"
													label="SSH Port"
													value={host.ssh && host.ssh.port}
													inputProps={{ readOnly, min: 0, max: 65535 }}
												/>
											</FormControl>
										</Grid>
									</>
								)}

								<Grid item xs={12}>
									<SSHKeysEditor
										clusterType={clusterType}
										authorizedKeys={host.authorizedKeys}
										inheritedAuthorizedKeys={
											cluster ? cluster.sharedConfig.host.authorizedKeys : []
										}
										readOnly={readOnly || false}
									/>
								</Grid>
							</Grid>
						</>
					)}
				</form>
			</>
		);
	}
}

export default withRouter(HostFormComponent);
