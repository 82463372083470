import { Cluster } from "pages/management/cluster/types";
import { DEFAULT_HOST_SHARED_CONFIG } from "pages/management/host/const";
import { DEFAULT_NODE_SHARED_CONFIG } from "pages/management/node/const";

export const DEFAULT_CLUSTER: Readonly<Cluster> = {
	name: "",
	sharedConfig: {
		host: DEFAULT_HOST_SHARED_CONFIG,
		node: DEFAULT_NODE_SHARED_CONFIG
	},
	unmanaged: false,
	firewallRules: []
};
